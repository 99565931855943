import {
  actionChannel,
  all,
  call,
  cancelled,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { LOGGED_IN, LOGGED_OUT } from '../../auth/actions';
import { SAGA_REBUILD } from '../../../util/sagas';
import { DriveItemId, GroupId, ProjectId } from '../../../models/Types';
import { PRIO } from '../../../constants';
import { AnyAction } from 'redux';
import { apiCreateDriveFolder, apiCreateUploadSession } from '../api';
import { DriveItem, UploadSession } from '../../../models/Drive';
import {
  RootReducerState,
  getCurrentFolderItem,
  getProjectByIdState,
  isLoggedIn,
} from '../../../apps/main/rootReducer';
import {
  driveItemNewFolderCreated,
  fetchDriveItemsSagaAction,
} from '../actions';
import { defaultRetryOptions } from '../../../util/fetchWithRetry';
import { ApiResult } from '../../../api';
import { PrioFile } from '../../../components/Upload/UploadField';
import createGraphErrorNotification from '../../../util/createGraphErrorNotification';
import { notification } from 'antd';
import i18n from '../../../i18n';
import { updatePrioFileUploadProgress } from '../actions/uploadLists';
import { v4 as uuid } from 'uuid';

interface UploadFilesSagaAction {
  type: string;
  groupId: GroupId;
  uploadBundleId: string;
  parentDriveItemId: DriveItemId;
  projectId: ProjectId;
  fileList: PrioFile[];
}

export const SAGA_UPLOAD_FILES = PRIO + 'SAGA_UPLOAD_FILES';

export const sagaUploadFiles: (
  groupId: GroupId,
  uploadBundleId: string,
  parentDriveItemId: DriveItemId,
  projectId: ProjectId,
  fileList: PrioFile[]
) => UploadFilesSagaAction = (
  groupId,
  uploadBundleId,
  parentDriveItemId,
  projectId,
  fileList
) => ({
  type: SAGA_UPLOAD_FILES,
  groupId,
  uploadBundleId,
  parentDriveItemId,
  projectId,
  fileList,
});

interface StartUploadFilesSagaAction {
  type: string;
  groupId: GroupId;
  uploadBundleId: string;
  parentDriveItemId: DriveItemId;
  projectId: ProjectId;
}

export const SAGA_START_UPLOAD_FILES = PRIO + 'SAGA_START_UPLOAD_FILES';

export const sagaStartUploadFiles: (
  groupId: GroupId,
  uploadBundleId: string,
  parentDriveItemId: DriveItemId,
  projectId: ProjectId
) => StartUploadFilesSagaAction = (
  groupId,
  uploadBundleId,
  parentDriveItemId,
  projectId
) => ({
  type: SAGA_START_UPLOAD_FILES,
  groupId,
  uploadBundleId,
  parentDriveItemId,
  projectId,
});

interface CreatedSubfolderDictionary {
  [subFolderName: string]: DriveItemId;
}

interface FolderNameToFileListDictionary {
  [folderName: string]: PrioFile[];
}

const wait = (value: number) => {
  return new Promise((resolve) => setTimeout(resolve, value));
};

const calculateChunkSize = (
  previousDurationInMs: number,
  currentChunkSize?: number
) => {
  if (currentChunkSize === undefined) {
    if (previousDurationInMs < 750) {
      return 16 * 320 * 1024;
    }
    if (previousDurationInMs < 1750) {
      return 8 * 320 * 1024;
    }
    return 4 * 320 * 1024;
  } else {
    if (previousDurationInMs < 750) {
      if (currentChunkSize < 32 * 320 * 1024) {
        return currentChunkSize * 2;
      }
      return currentChunkSize;
    }
    if (previousDurationInMs < 1750) {
      return currentChunkSize;
    }
    if (currentChunkSize > 320 * 1024) {
      return currentChunkSize / 2;
    }
    return 320 * 1024;
  }
};

const fetchWithRetry: (
  fileName: string,
  input: RequestInfo,
  init?: RequestInit,
  retryWithErrorResponse?: boolean,
  retryOptions?: number[]
) => Promise<Response> = async (
  fileName,
  input,
  init,
  retryWithErrorResponse = false,
  retryOptions = defaultRetryOptions
) => {
  const [delay, ...rest] = retryOptions;
  try {
    await wait(delay);
    const result = await fetch(input, init);
    if (result.status >= 200 && result.status < 300) {
      return result;
    }
    throw result;
  } catch (error) {
    if (
      'status' in error &&
      error.status >= 500 &&
      error.status < 505 &&
      rest.length > 0
    ) {
      return fetchWithRetry(
        fileName,
        input,
        init,
        retryWithErrorResponse,
        rest
      );
    } else if (!('status' in error)) {
      return Response.error();
    } else {
      return error;
    }
  }
};

function* getAndCreateSubFolder(
  groupId: GroupId,
  sessionId: string,
  parentDriveItemId: DriveItemId,
  folderName: string,
  createdSubFolders: CreatedSubfolderDictionary,
  setCreatedSubFolders: (
    subFolderName: string,
    driveItemId: DriveItemId
  ) => void
) {
  let subFolderNames = folderName.split('/');

  let subFolderDriveItemIds: DriveItemId[] = [];
  let name: string = '';
  try {
    subFolderDriveItemIds = [parentDriveItemId];
    for (let i = 0; i < subFolderNames.length; i++) {
      const _path = subFolderNames.slice(0, i + 1).join('/');
      if (!createdSubFolders[_path]) {
        name = subFolderNames[i];
        const fileId = uuid();
        yield put(
          updatePrioFileUploadProgress(
            groupId,
            sessionId,
            subFolderDriveItemIds[subFolderDriveItemIds.length - 1],
            null,
            fileId,
            name,
            null,
            _path,
            0,
            0,
            'pending',
            true
          )
        );
        const { data }: ApiResult<DriveItem> = yield call(
          apiCreateDriveFolder,
          groupId,
          subFolderDriveItemIds[subFolderDriveItemIds.length - 1],
          name
        );
        if (data) {
          yield put(
            updatePrioFileUploadProgress(
              groupId,
              sessionId,
              subFolderDriveItemIds[subFolderDriveItemIds.length - 1],
              data.id,
              fileId,
              data.name,
              null,
              _path,
              0,
              0,
              'done',
              true
            )
          );
          setCreatedSubFolders(_path, data.id);
          subFolderDriveItemIds.push(data.id);
          yield put(
            driveItemNewFolderCreated(
              data,
              data.parentReference?.id ??
                subFolderDriveItemIds[subFolderDriveItemIds.length - 1],
              groupId
            )
          );
          const projectId: ProjectId = yield select(
            (state: RootReducerState) =>
              Object.values(getProjectByIdState(state)).find(
                ({ groupId: _groupId }) => _groupId === groupId
              )?.projectId
          );
          const isRoot = data.parentReference?.path === '/drive/root:';
          yield put(
            fetchDriveItemsSagaAction(
              projectId,
              groupId,
              isRoot ? null : data.parentReference?.id,
              250,
              isRoot,
              false
            )
          );
        } else {
          yield put(
            updatePrioFileUploadProgress(
              groupId,
              sessionId,
              subFolderDriveItemIds[subFolderDriveItemIds.length - 1],
              null,
              fileId,
              name,
              null,
              _path,
              0,
              0,
              'error',
              true,
              i18n.t('documents:errorMessages.createFolderError', {
                folderName: name,
              })
            )
          );
          notification.error({
            message: i18n.t('common:error'),
            description: i18n.t('documents:errorMessages.createFolderError', {
              folderName: name,
            }),
          });
          throw new Error(`Could not create folder '${name}'`);
        }
      } else {
        subFolderDriveItemIds.push(createdSubFolders[_path]);
      }
    }
  } catch (error) {
    console.error('Error in getAndCreateSubFolder -', error);
  } finally {
    if (subFolderDriveItemIds.length !== subFolderNames.length + 1) {
      return undefined;
    }
    return subFolderDriveItemIds[subFolderDriveItemIds.length - 1];
  }
}

/**
 * Task to upload one file. Uses MS-Api upload session. If file is in folder and this folder does
 * not exist in dropped directory, a new folder will be created. Created Folders are added to parent task.
 * @param action Action with file and upload metadata.
 * @param parentDriveItemId Parent folder id.
 */
function* uploadFile(
  file: PrioFile,
  action: UploadFilesSagaAction,
  parentDriveItemId: DriveItemId
) {
  const { projectId, groupId } = action;
  const sessionId = file.sessionId;
  const fileName = file.webkitRelativePath || file.name;
  const inSubfolder = fileName.split('/').length > 1;
  const _fileName = encodeURIComponent(
    inSubfolder ? fileName.split('/')[fileName.split('/').length - 1] : fileName
  );
  try {
    let beforeCallTimestampInMs = new Date().getTime();
    const { data }: ApiResult<UploadSession> = yield call(
      apiCreateUploadSession,
      projectId,
      groupId,
      parentDriveItemId,
      _fileName
    );
    let afterCallTimestampInMs = new Date().getTime();

    let driveItemId: DriveItemId = null;

    if (data) {
      const { fileId, name, webkitRelativePath, size, type } = file;
      const fileSize = size;
      let errorDetected = false;
      let maxChunkSize = calculateChunkSize(
        afterCallTimestampInMs - beforeCallTimestampInMs
      );

      for (
        let start = 0;
        !errorDetected && start < fileSize;
        start = start + maxChunkSize
      ) {
        if (start !== 0) {
          maxChunkSize = calculateChunkSize(
            afterCallTimestampInMs - beforeCallTimestampInMs,
            maxChunkSize
          );
        }

        let end = Math.min(start + maxChunkSize, fileSize);
        let chunk = file.slice(start, end);

        // Create the headers for this chunk
        const headers = {
          'Content-Range': `bytes ${start}-${end - 1}/${fileSize}`,
          'Content-Length': chunk.size.toString(),
        };

        // Make the PUT request to upload the chunk
        beforeCallTimestampInMs = new Date().getTime();
        const response: Response = yield call(
          fetchWithRetry,
          _fileName,
          data.uploadUrl,
          {
            method: 'PUT',
            body: chunk,
            headers,
          }
        );
        afterCallTimestampInMs = new Date().getTime();

        if (response.status >= 200 && response.status < 300) {
          const data = yield response.json();
          if (data?.id) {
            driveItemId = data.id;
          }

          yield put(
            updatePrioFileUploadProgress(
              groupId,
              sessionId,
              parentDriveItemId,
              data.id,
              fileId,
              name,
              type,
              webkitRelativePath,
              size,
              end,
              'pending',
              false
            )
          );
        } else {
          const errorString = yield call(
            createGraphErrorNotification,
            response
          );
          const { fileId, name, webkitRelativePath, size, type } = file;
          yield put(
            updatePrioFileUploadProgress(
              groupId,
              sessionId,
              parentDriveItemId,
              null,
              fileId,
              name,
              type,
              webkitRelativePath,
              size,
              size,
              'error',
              false,
              errorString
            )
          );
          errorDetected = true;
        }
      }

      if (!errorDetected) {
        const { fileId, name, webkitRelativePath, size, type } = file;
        yield put(
          updatePrioFileUploadProgress(
            groupId,
            sessionId,
            parentDriveItemId,
            driveItemId,
            fileId,
            name,
            type,
            webkitRelativePath,
            size,
            size,
            'done',
            false
          )
        );
        const isRoot = parentDriveItemId.includes('root-group');
        yield put(
          fetchDriveItemsSagaAction(
            projectId,
            groupId,
            isRoot ? null : parentDriveItemId,
            250,
            isRoot,
            false,
            undefined,
            true
          )
        );
      }
    } else {
      notification.error({
        message: i18n.t('common:error'),
        description: i18n.t('documents:errorMessages.uploadFileError', {
          fileName: _fileName,
        }),
      });
      const { fileId, name, webkitRelativePath, size, type } = file;
      yield put(
        updatePrioFileUploadProgress(
          groupId,
          sessionId,
          parentDriveItemId,
          null,
          fileId,
          name,
          type,
          webkitRelativePath,
          size,
          size,
          'error',
          false,
          i18n.t('documents:errorMessages.uploadFileError', {
            fileName: _fileName,
          })
        )
      );
    }
  } catch (error) {
    console.error('Error in watchUploadFiles - uploadFile:', error);
    const { fileId, name, webkitRelativePath, size, type } = file;
    if (!error?.message?.includes('Could not create folder')) {
      notification.error({
        message: i18n.t('common:error'),
        description: i18n.t('documents:errorMessages.createUploadSessionError'),
      });
      yield put(
        updatePrioFileUploadProgress(
          groupId,
          sessionId,
          parentDriveItemId,
          null,
          fileId,
          name,
          type,
          webkitRelativePath,
          size,
          size,
          'error',
          false,
          i18n.t('documents:errorMessages.createUploadSessionError')
        )
      );
    }
  }
}

/**
 * Task to collect upload request action into one "session". Not equivalent as a upload session in MS-Api!
 * Each session stores created folders.
 * @param action Action with file and upload metadata.
 */
function* uploadCollectionSession(action: UploadFilesSagaAction) {
  const { fileList, groupId, parentDriveItemId } = action;

  let uploadedFiles: PrioFile[] = [];

  const addUploadedFile = (file: PrioFile) => {
    uploadedFiles = [...uploadedFiles, file];
  };

  let createdSubFolders: CreatedSubfolderDictionary = {};

  const setCreatedSubFolders = (
    subFolderName: string,
    driveItemId: DriveItemId
  ) => {
    createdSubFolders = {
      ...createdSubFolders,
      [subFolderName]: driveItemId,
    };
  };

  let folderNameToFileList: FolderNameToFileListDictionary = {};

  try {
    if (!parentDriveItemId.includes('root-group')) {
      let _parentDriveItemId: DriveItemId = yield select(
        (state) => getCurrentFolderItem(state, parentDriveItemId)?.id
      );

      if (_parentDriveItemId) {
        folderNameToFileList = fileList.reduce((map, file) => {
          let folderName = 'root';
          if (file.webkitRelativePath.split('/').length > 1) {
            folderName = file.webkitRelativePath
              .split('/')
              .slice(0, -1)
              .join('/');
          }
          map[folderName] = [...(map[folderName] || []), file];
          return map;
        }, {});

        // if (!_parentDriveItemId) {
        //   notification.error({
        //     message: i18n.t('common:error'),
        //     description: i18n.t(
        //       'documents:errorMessages.uploadBeforeFetchingDriveItemsFinished'
        //     ),
        //     duration: 8,
        //   });
        // }

        for (const folderName of Object.keys(folderNameToFileList)) {
          const files = folderNameToFileList[folderName];
          if (folderName !== 'root') {
            const firstFile = files[0];
            _parentDriveItemId = yield call(
              getAndCreateSubFolder,
              groupId,
              firstFile?.sessionId,
              parentDriveItemId,
              folderName,
              createdSubFolders,
              setCreatedSubFolders
            );
          }
          if (_parentDriveItemId) {
            const __parentDriveItemId = _parentDriveItemId;
            const callUpload = function* (file: PrioFile) {
              yield call(uploadFile, file, action, __parentDriveItemId);
              addUploadedFile(file);
            };

            for (let i = 0; i <= Math.floor(files.length / 3); i++) {
              const slice = files.slice(i * 3, (i + 1) * 3);
              yield all(slice.map((file) => call(callUpload, file)));
            }
          } else {
            for (const file of files) {
              const {
                sessionId,
                fileId,
                name,
                webkitRelativePath,
                size,
                type,
              } = file;
              yield put(
                updatePrioFileUploadProgress(
                  groupId,
                  sessionId,
                  _parentDriveItemId,
                  null,
                  fileId,
                  name,
                  type,
                  webkitRelativePath,
                  size,
                  size,
                  'error',
                  false,
                  i18n.t('documents:errorMessages.createFolderError', {
                    folderName,
                  })
                )
              );
            }
          }
        }
      } else {
        if (!_parentDriveItemId) {
          notification.error({
            message: i18n.t('common:error'),
            description: i18n.t(
              'documents:errorMessages.uploadBeforeFetchingDriveItemsFinished'
            ),
            duration: 8,
          });
        }
        for (const file of fileList) {
          addUploadedFile(file);
          yield put(
            updatePrioFileUploadProgress(
              groupId,
              file.sessionId,
              parentDriveItemId,
              null,
              file.fileId,
              file.name,
              file.type,
              file.webkitRelativePath,
              file.size,
              file.size,
              'error',
              false,
              i18n.t(
                'documents:errorMessages.uploadBeforeFetchingDriveItemsFinished'
              )
            )
          );
        }
      }
    } else {
      notification.error({
        message: i18n.t('common:error'),
        description: i18n.t('documents:errorMessages.uploadToRootFolderError'),
      });
      for (const file of fileList) {
        addUploadedFile(file);
        yield put(
          updatePrioFileUploadProgress(
            groupId,
            file.sessionId,
            parentDriveItemId,
            null,
            file.fileId,
            file.name,
            file.type,
            file.webkitRelativePath,
            file.size,
            file.size,
            'error',
            false,
            i18n.t('documents:errorMessages.uploadToRootFolderError')
          )
        );
      }
    }
  } catch (error) {
    console.error(
      'Error in watchUploadFiles - uploadCollectionSession:',
      error
    );
  } finally {
    const missingFiles = fileList.filter(
      (file) =>
        !uploadedFiles.some(
          (uploadedFile) =>
            uploadedFile.webkitRelativePath === file.webkitRelativePath
        )
    );
    if (missingFiles.length > 0) {
      for (const file of missingFiles) {
        const { fileId, name, webkitRelativePath, size, type, sessionId } =
          file;
        yield put(
          updatePrioFileUploadProgress(
            groupId,
            sessionId,
            parentDriveItemId,
            null,
            fileId,
            name,
            type,
            webkitRelativePath,
            size,
            size,
            'error',
            false,
            i18n.t('documents:errorMessages.uploadFileError', {
              fileName: name,
            })
          )
        );
      }
    }
  }
}

/**
 * Task to organize upload sessions.
 * @param action Initial action triggered to start this task
 * @param removeBundleId Method to clean open task in upload manager. After 5 seconds, this task will stop and be removed from parent.
 */
function* groupIdTask(
  action: StartUploadFilesSagaAction,
  removeGroupId: (groupId: GroupId) => void
) {
  const { groupId } = action;
  let _latestAction: UploadFilesSagaAction = null;
  try {
    const groupIdChannel = yield actionChannel(
      (action: AnyAction) =>
        action.type &&
        action.groupId &&
        `${action.type}${action.groupId}` === `${SAGA_UPLOAD_FILES}${groupId}`
    );

    let isRunning = true;

    while (isRunning) {
      const { latestAction, stop } = yield race({
        latestAction: take(groupIdChannel),
        stop: delay(5000),
      });
      if (stop && isRunning) {
        isRunning = false;
      }
      if (latestAction) {
        _latestAction = latestAction as UploadFilesSagaAction;
        yield call(uploadCollectionSession, latestAction);
        _latestAction = null;
      }
    }
  } catch (error) {
    console.error('Error in watchUploadFiles - groupIdTask:', error);
  } finally {
    if (yield cancelled() && _latestAction) {
      yield call(groupIdTask, _latestAction, removeGroupId);
      _latestAction = null;
    }
    removeGroupId(groupId);
  }
}

/**
 * Manages the tasks for each upload bundle.
 * Upload sessions can be called async if the uploadBundleId is distinct.
 */
function* uploadManager() {
  let groupIds: GroupId[] = [];
  const channel = yield actionChannel(SAGA_START_UPLOAD_FILES);

  function removeGroupId(groupId: GroupId) {
    groupIds = groupIds.filter((id) => id !== groupId);
  }

  while (true) {
    let action: StartUploadFilesSagaAction = yield take(channel);
    if (!!action.groupId && !groupIds.includes(action.groupId)) {
      groupIds = [...groupIds, action.groupId];
      yield fork(groupIdTask, action, removeGroupId);
    }
  }
}

/**
 * main saga to start and stop the upload manager.
 * The upload manager runs only if an account is logged in.
 */
function* mainTask() {
  try {
    const loggedIn = yield select(isLoggedIn);
    if (loggedIn) {
      yield race([call(uploadManager), take(LOGGED_OUT)]);
    }
  } catch (error) {
    console.error('Error in watchUploadFile - mainTask', error);
    yield mainTask();
  }
}

/**
 * Rollout saga to upload files into sharepoint.
 * It will listen to "LOGGED_IN" and "SAGA_REBUILD" action and cancell currently running tasks.
 */
export default function* watchUploadFiles() {
  yield takeLatest([LOGGED_IN, SAGA_REBUILD], mainTask);
}
