import React, { useRef } from 'react';
import classNames from 'classnames';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { useParams } from 'react-router-dom';
import Flex from '../../../../components/Flex';
import HRTimekeepingMenu from './HRTimekeepingMenu';
import NavigationBar from '../../../../components/NavigationBar';
import HRMonthlyClosePage, {
  HRMonthlyClosePageRefProps,
} from './HRMonthlyClosePage';
import HRCompensationPaymentsPage, {
  HRCompensationPaymentsPageRefProps,
} from './HRCompensationPaymentsPage';
import { useTranslation } from 'react-i18next';
import { PrioTheme } from '../../../../theme/types';
import HRTimekeepingPage from './HRTimekeepingPage';
import HRTimeKeepingOverviewPage from './HRTimeKeepingOverviewPage';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  menu: {
    flex: 1,
    maxWidth: theme.old.components.menuMaxWidth,
  },
  content: {
    flex: 1,
    overflow: 'hidden',
  },
  overflow: {
    overflow: 'hidden',
  },
  popover: {
    width: '350px',
  },
  filterAndDataWrapper: {
    padding: 24,
    overflowY: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > div:first-child': {
      marginBottom: 16,
    },
  },
}));

interface HRTimekeepingProps {
  className?: string;
  pathPrefix?: string;
}

export const HRTimekeeping: React.FC<HRTimekeepingProps> = (props) => {
  //#region ------------------------------ Defaults

  const { className, pathPrefix } = props;
  const classes = useStyles();

  const { t } = useTranslation();

  const { selectedList, officeId } = useParams();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const monthlyClosesPageRef = useRef<HRMonthlyClosePageRefProps>(null);

  const compensationPaymentsPageRef =
    useRef<HRCompensationPaymentsPageRefProps>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Components
  let navigationBar: React.ReactNode;
  let component: React.ReactNode;
  switch (selectedList) {
    case 'overview':
      component = (
        <HRTimeKeepingOverviewPage
          className={classNames(classes.content)}
          officeId={officeId}
        />
      );
      break;

    case 'timekeepingDays':
      component = (
        <HRTimekeepingPage
          className={classNames(classes.content)}
          officeId={officeId}
        />
      );
      break;
    case 'monthlyClose':
      component = (
        <HRMonthlyClosePage
          ref={monthlyClosesPageRef}
          officeId={officeId}
          className={classes.filterAndDataWrapper}
        />
      );
      navigationBar = (
        <>
          <Button
            type="primary"
            iconProp={['fal', 'plus']}
            onClick={() => {
              if (monthlyClosesPageRef?.current) {
                monthlyClosesPageRef.current.openCreateCompensationPaymentDrawer();
              }
            }}
          >
            {t(
              'hr:timeAndLeaveManagement.navigationBar.monthlyCloses.createCompensationPayment'
            )}
          </Button>
        </>
      );
      break;

    case 'compensationPayments':
      component = (
        <HRCompensationPaymentsPage
          className={classNames(classes.content, classes.filterAndDataWrapper)}
          officeId={officeId}
          ref={compensationPaymentsPageRef}
        />
      );
      navigationBar = (
        <>
          <Button
            type="primary"
            iconProp={['fal', 'plus']}
            onClick={() => {
              if (compensationPaymentsPageRef?.current) {
                compensationPaymentsPageRef.current.openCreateCompensationPaymentDrawer();
              }
            }}
          >
            {t(
              `hr:timeAndLeaveManagement.navigationBar.compensationPayments.createCompensationPayment`
            )}
          </Button>
        </>
      );
      break;
    default:
      break;
  }
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <NavigationBar>{navigationBar}</NavigationBar>
      <Flex.Row flex={1} className={classes.overflow}>
        <HRTimekeepingMenu
          selectedList={selectedList}
          urlPrefix={pathPrefix}
          className={classes.menu}
        />
        {component}
      </Flex.Row>
    </Flex.Column>
  );
};

export default HRTimekeeping;
