import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Layout, Switch } from 'antd';
import { ProjectExtensionId, ProjectId } from '../../../../../../models/Types';
import {
  CreateProjectExtension,
  GetProjectExtensionSuggestion,
  ProjectExtension,
  ProjectExtensionSuggestion,
  UpdateProjectExtension,
} from '../../../../../../models/ProjectExtension';
import { Button, Input, Select } from '@prio365/prio365-react-library';
import { colon } from '../../../../../../util/forms';
import { OptionType } from '@prio365/prio365-react-library/lib/Select/components/Option';
import { Content } from 'antd/lib/layout/layout';
import { apiGetProjectExtensionSuggestion } from '../../../../api';
import InformationBox from '../../../../../../components/InformationBox';
import Flex from '../../../../../../components/Flex';
import { makePrioStyles } from '../../../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  submitButton: {
    position: 'absolute',
    bottom: '32px',
  },
  rootLayout: {
    margin: 0,
    height: '100%',
    backgroundColor: theme.colors.application.background.default,
  },
  editContent: {
    backgroundColor: theme.colors.application.background.default,
  },
}));

export interface JiraProjectExtensionFormModel {
  displayName: string;
  projectId: string;
  hasDefaultAccess: boolean;
}
interface JiraProjectExtensionFormProps {
  className?: string;
  projectExtension: ProjectExtension;
  addProjectExtension: CreateProjectExtension;
  projectId: ProjectId;
  updateProjectExtension: (
    projectId: ProjectId,
    projectExtensionId: ProjectExtensionId,
    projectExtension: UpdateProjectExtension
  ) => Promise<ProjectExtension>;
  createProjectExtension: (
    projectId: ProjectId,
    createProjectExtension: CreateProjectExtension
  ) => Promise<ProjectExtension>;
}

export const JiraProjectExtensionForm: React.FC<
  JiraProjectExtensionFormProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    addProjectExtension,
    projectExtension,
    projectId,
    updateProjectExtension,
    createProjectExtension,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [disabled, setDisabled] = useState<boolean>(false);
  const [Projects, setProjects] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedProjectId, setSelectedProjectId] = useState<string>(null);
  const [projectExtensionSuggestion, setProjectExtensionSuggestion] =
    useState<ProjectExtensionSuggestion>();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const saveOrUpdateExtension = async (
    jiraProjectExtensionFormModel: JiraProjectExtensionFormModel
  ) => {
    setLoading(true);
    setDisabled(true);
    if (projectExtension && !addProjectExtension) {
      let update: UpdateProjectExtension = {
        displayName: jiraProjectExtensionFormModel.displayName,
        value: null,
        key: jiraProjectExtensionFormModel.projectId,
      };
      await updateProjectExtension(
        projectId,
        projectExtension.projectExtensionId,
        update
      );
    } else if (addProjectExtension && !projectExtension) {
      let newProjectExtension: CreateProjectExtension = {
        displayName: jiraProjectExtensionFormModel.displayName,
        value: null,
        hasDefaultAccess: jiraProjectExtensionFormModel.hasDefaultAccess,
        projectExtensionType: 'jira',
        key: jiraProjectExtensionFormModel.projectId,
      };
      await createProjectExtension(projectId, newProjectExtension);
    }
    setDisabled(false);
    setLoading(false);
  };
  //#endregion

  //#region ------------------------------ Effects

  useEffect(() => {
    const getJiraSuggestions = async () => {
      let getProjectExtensionSuggestion: GetProjectExtensionSuggestion = {
        key: null,
        value: null,
        projectExtensionType: 'jira',
      };
      const { data } = await apiGetProjectExtensionSuggestion(
        projectId,
        getProjectExtensionSuggestion
      );
      if (data) {
        setProjectExtensionSuggestion(data);
        setProjects(
          data.availableProjectExtensions.map((jiraProject) => {
            return {
              value: jiraProject.key,
              label: jiraProject.displayName,
            };
          })
        );
      }
    };

    getJiraSuggestions();
  }, [projectId]);

  return (
    <Layout className={classes.rootLayout}>
      <Form
        form={form}
        layout="vertical"
        className={classes.root}
        initialValues={{
          displayName: projectExtension?.displayName ?? 'Jira',
          hasDefaultAccess: projectExtension?.hasDefaultAccess ?? true,
          projectId: projectExtension?.key ?? '',
          createType: 'link',
        }}
        onFinish={(values) => {
          if (saveOrUpdateExtension) {
            saveOrUpdateExtension(values);
          }
        }}
        onValuesChange={(_, values) => {
          //nothingToDo
        }}
      >
        <Content className={classes.editContent}>
          <Form.Item
            name="displayName"
            colon={colon}
            label={t('projects:addIn.extensions.jira.form.displayName')}
            required={true}
            rules={[
              {
                required: true,
                message: t('projects:form.validation.displayNameRequired'),
              },
            ]}
          >
            <Input full disabled={disabled} />
          </Form.Item>
          <InformationBox
            description={t(
              'projects:addIn.extensions.jira.displayNameDescription'
            )}
          />
          {!projectExtension ? (
            <>
              <Form.Item
                name="projectId"
                colon={colon}
                label={t('projects:addIn.extensions.jira.form.project')}
                required={true}
                valuePropName="checked"
              >
                <Select
                  options={Projects}
                  disabled={projectExtension != null}
                  value={selectedProjectId}
                  loading={loading}
                  onChange={(value) => {
                    setSelectedProjectId(value);
                  }}
                />
              </Form.Item>
              <InformationBox
                description={t(
                  'projects:addIn.extensions.jira.jiraProjectExtensionDescription'
                )}
              />
              <Form.Item
                name="isUnique"
                colon={colon}
                required={true}
                initialValue={true}
                label={t('projects:addIn.extensions.jira.form.isUnique')}
                rules={[
                  {
                    required: true,
                    message: t('projects:form.validation.isUnique'),
                  },
                ]}
                valuePropName="checked"
              >
                <Switch disabled={loading || disabled} />
              </Form.Item>
              <InformationBox
                description={t(
                  'projects:addIn.extensions.jira.isUniqueDescription'
                )}
              />
              <Form.Item
                name="hasDefaultAccess"
                colon={colon}
                required={true}
                label={t('projects:addIn.extensions.form.hasDefaultAccess')}
                rules={[
                  {
                    required: true,
                    message: t(
                      'projects:form.validation.hasDefaultAccessRequired'
                    ),
                  },
                ]}
                valuePropName="checked"
              >
                <Switch disabled={loading || disabled} />
              </Form.Item>
            </>
          ) : (
            <>
              {// search for board with projectId and get projectName
              projectExtensionSuggestion?.availableProjectExtensions.map(
                (jiraProject) => {
                  // compare number with string
                  if (
                    // board.location.projectId
                    //   .toString()
                    //   .includes(projectExtension?.key)
                    jiraProject.key.toString() === projectExtension?.key
                  ) {
                    return (
                      <p>
                        {t('projects:addIn.extensions.jira.form.project')}:
                        {jiraProject.displayName}
                      </p>
                    );
                  } else {
                    return '';
                  }
                }
              )}
            </>
          )}
        </Content>
        <Flex.Row flex={1} justifyContent="flex-end">
          <Button
            htmlType="submit"
            loading={disabled}
            disabled={disabled}
            className={classes.submitButton}
          >
            {t('projects:addIn.extensions.form.save')}
          </Button>
        </Flex.Row>
      </Form>
    </Layout>
  );
};

export default JiraProjectExtensionForm;
