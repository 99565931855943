import React from 'react';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Typography } from 'antd';
import { Trans } from 'react-i18next';
import {} from '../../../util/officeJs';
import { TemplateSettings } from './TemplateSettings';
import { GenericSettings } from './GenericSettings';
import { UserRolePage } from '../../users/components/UserRolePage';
import { InternalCompanyManagement } from '../../companies/components/InternalCompanyManagement';
import useAccessRights from '../../users/hooks/useAccessRights';
import ThemeSettingsModule from '../../../theme/components/ThemeSettingsModule';
import * as Sentry from '@sentry/react';

import { makePrioStyles } from '../../../theme/utils';
import PersonnelFiles from '../../hr/components/personnelFiles/PersonnelFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';
import AddInsConfig from './AddInsConfig';
import Flex from '../../../components/Flex';
import ProjectAdminSettings from './ProjectSettings/ProjectAdminSettings';
import ProjectAdminExtensionSettings from './ProjectSettings/ProjectAdminExtensionSettings';
import UserLicencePage from '../../users/components/UserLicencePage';
import { ContactImportEdit } from './ContactImport/ContactImportEdit';
import { ContactImporter } from './ContactImport/ContactImporter';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    gap: '1rem',
    fontSize: theme.old.typography.fontSize.base,
    fontWeight: theme.old.typography.fontWeight.bold,
  },
  newPage: {
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: `${theme.old.spacing.defaultPadding}px ${
      theme.old.spacing.defaultPadding * 2
    }px`,

    backgroundColor: theme.colors.application.background.light,
  },
  body: {
    overflow: 'hidden',
  },
  manu: {
    maxWidth: theme.old.components.menuMaxWidth,
    overflow: 'auto',
  },
}));

interface SettingsModuleProps {}

export const SettingsModule: React.FC<SettingsModuleProps> = (props) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const {
    showAllPrioSettings,
    showUserRoleSettings,
    showTemplateSettings,
    showUserCompanyData,
    showAddInsSettings,
    showProjectAdminSettings,
    showContactImportSettings,
  } = useAccessRights([
    'showAllPrioSettings',
    'showUserRoleSettings',
    'showTemplateSettings',
    'showUserCompanyData',
    'showAddInsSettings',
    'showProjectAdminSettings',
    'showContactImportSettings',
  ]);

  if (
    ![
      showAllPrioSettings,
      showUserCompanyData,
      showTemplateSettings,
      showUserCompanyData,
      showAddInsSettings,
      showContactImportSettings,
    ].includes(true)
  )
    return (
      <div className={classes.root}>
        <div className={classes.center}>
          <FontAwesomeIcon size="5x" icon={['fal', 'face-worried']} />
          {t('common:no_access_rights.settings.general')}
        </div>
      </div>
    );

  return (
    <div className={classes.root}>
      {/* <ContactsSelectionList type={selectedList as ContactSearchType} /> */}
      <Routes>
        {showUserRoleSettings && (
          <Route path="user-roles">
            <Route path="" element={<UserRolePage pathPrefix={'../'} />} />
            <Route
              path="office/:officeId/*"
              element={<UserRolePage pathPrefix={'../'} />}
            />
          </Route>
        )}
        {showUserRoleSettings && (
          <Route path="user-licences">
            <Route path="" element={<UserLicencePage pathPrefix={'../'} />} />
            <Route
              path="office/:officeId/*"
              element={<UserLicencePage pathPrefix={'../'} />}
            />
          </Route>
        )}
        {showUserCompanyData && (
          <Route
            path="users/*"
            element={
              <PersonnelFiles
                className={classes.fullHeight}
                onlyShowTabs={['companyData']}
                hideNavBar
              />
            }
          />
        )}
        {showTemplateSettings && (
          <Route path="templates">
            <Route path="" element={<Navigate to="documents" replace />} />
            <Route
              path=":selectedList/*"
              element={
                <TemplateSettings
                  pathPrefix="../"
                  className={classes.fullHeight}
                />
              }
            />
            <Route
              path="office/:officeId/:selectedList/*"
              element={
                <TemplateSettings
                  pathPrefix="../"
                  className={classes.fullHeight}
                />
              }
            />
          </Route>
        )}
        {showAddInsSettings && (
          <Route
            path="AddIns/*"
            element={
              <Flex.Column className={classes.root}>
                <Flex.Row flex={1} className={classes.body}>
                  <Flex.Item flex={1} className={classes.newPage}>
                    <AddInsConfig className={classes.fullHeight} />
                  </Flex.Item>
                </Flex.Row>
              </Flex.Column>
            }
          />
        )}
        {showProjectAdminSettings && (
          <Route
            path="projectSettings/*"
            element={
              <Flex.Column className={classes.root}>
                <Flex.Row flex={1} className={classes.body}>
                  <Flex.Item flex={1} className={classes.newPage}>
                    <ProjectAdminSettings className={classes.fullHeight} />
                  </Flex.Item>
                </Flex.Row>
              </Flex.Column>
            }
          />
        )}
        {showContactImportSettings && (
          <Route
            path="contactImporter"
            element={
              <Flex.Column className={classes.root}>
                <Flex.Row flex={1} className={classes.body}>
                  <Flex.Item flex={1} className={classes.newPage}>
                    <ContactImporter />
                  </Flex.Item>
                </Flex.Row>
              </Flex.Column>
            }
          />
        )}
        {showContactImportSettings && (
          <Route
            path="contactImporter/edit/:contactImportProcessId"
            element={
              <Flex.Column className={classes.root}>
                <Flex.Row flex={1} className={classes.body}>
                  <Flex.Item flex={1} className={classes.newPage}>
                    <ContactImportEdit />
                  </Flex.Item>
                </Flex.Row>
              </Flex.Column>
            }
          />
        )}
        {showProjectAdminSettings && (
          <Route
            path="projectExtensionSettings/*"
            element={
              <Flex.Column className={classes.root}>
                <Flex.Row flex={1} className={classes.body}>
                  <Flex.Item flex={1} className={classes.newPage}>
                    <ProjectAdminExtensionSettings
                      className={classes.fullHeight}
                    />
                  </Flex.Item>
                </Flex.Row>
              </Flex.Column>
            }
          />
        )}
        {showAllPrioSettings && (
          <>
            <Route path="generic">
              <Route path="" element={<Navigate to="keyValue" replace />} />
              <Route
                path=":selectedList/*"
                element={
                  <GenericSettings
                    pathPrefix="../"
                    className={classes.fullHeight}
                  />
                }
              />
            </Route>
            <Route
              path="subsidiaries"
              element={
                <InternalCompanyManagement className={classes.fullHeight} />
              }
            />
            <Route
              path="/"
              element={
                <div className="prio-flex-center-center prio-flex-column">
                  <Typography.Text>
                    <Trans i18nKey={'settings:noSettingsSelected'} />
                  </Typography.Text>
                </div>
              }
            />
            <Route path="theme" element={<ThemeSettingsModule />} />
          </>
        )}
        {showUserRoleSettings && (
          <Route
            path="contactImporter/*"
            element={
              <Flex.Column className={classes.root}>
                <Flex.Row flex={1} className={classes.body}>
                  <Flex.Item flex={1} className={classes.newPage}>
                    <div className={classes.fullHeight}>
                      <ContactImporter />
                    </div>
                  </Flex.Item>
                </Flex.Row>
              </Flex.Column>
            }
          />
        )}
      </Routes>
    </div>
  );
};

export default SettingsModule;
