import { DriveItem } from '../../../models/Drive';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { Project } from '../../../models/Project';
import { DriveUserRemoteItem } from '../../../models/Document';
import { MessageAttachment } from '../../../models/Message';
import { calcTextWidth } from '../../../util/calcTextWidth';
import { BreadcrumbItem } from '../../../models/Breadcrumb';
import { PRIO_DEFAULT_COLOR_BASE_MIME_TYPE } from '@prio365/prio365-react-library/lib/theme/defaults';
import { PrioTheme } from '../../../theme/types';
import {
  FolderPermissionAccessRight,
  FolderPermissionV2,
} from '../../../models/Settings';
import { FolderPermissionValue } from '../../../models/Types';

const folderTranslations = ['folder', 'ordner'];
const mimeTypes = PRIO_DEFAULT_COLOR_BASE_MIME_TYPE;

export const isDriveItemFolder: (driveItem: DriveItem) => boolean = (
  driveItem
) => {
  const isOneNote = checkIfOneNote(driveItem);
  if (!!driveItem?.folder && !isOneNote) {
    return true;
  }
  return folderTranslations?.includes(driveItem?.file?.mimeType?.toLowerCase());
};

export const iconForFile: (driveItem: DriveItem) => IconName = (driveItem) => {
  if (checkIfOneNote(driveItem)) return 'notebook';
  if (checkIfLink(driveItem?.name)) {
    return 'external-link';
  }
  return iconForMimeType(driveItem?.file?.mimeType);
};

export const iconForAttachment: (
  messageAttachment: MessageAttachment
) => IconName = (messageAttachment) => {
  if (checkIfLink(messageAttachment?.name)) {
    return 'external-link';
  }
  return iconForMimeType(messageAttachment.contentType);
};

declare type DriveItemType =
  | 'ms-word'
  | 'ms-excel'
  | 'ms-powerpoint'
  | 'ms-project'
  | 'ms-visio'
  | 'zip'
  | 'pdf'
  | 'image'
  | 'email'
  | 'video'
  | 'onenote';

const MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',

  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.ms-excel.template.macroEnabled.12',

  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',

  'application/vnd.ms-project',

  'application/visio',
  'application/x-visio',
  'application/vnd.visio',
  'application/vnd.ms-visio.drawing',
  'application/vnd.visio2013',
  'application/vnd.ms-visio.drawing.main+xml',
  'application/visio.drawing',
  'application/vsd ',
  'application/x-vsd',
  'image/x-vsd',

  'application/pdf',

  'image/png',
  'image/jpeg',
  'image/tiff',
  'image/svg+xml',

  'message/rfc822',

  'video/mp4',

  'application/x-zip-compressed',
  'application/zip',

  'application/oneNote',
];

declare type MimeType = (typeof MIME_TYPES)[number];

interface MimeTypeDriveItemTypeMap {
  [key: MimeType]: DriveItemType;
}

const MIMETYPE_DRIVEITEM_MAP: MimeTypeDriveItemTypeMap = {
  'application/msword': 'ms-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'ms-word',
  'application/vnd.ms-word.document.macroEnabled.12': 'ms-word',
  'application/vnd.ms-word.template.macroEnabled.12': 'ms-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    'ms-word',

  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'ms-excel',
  'application/vnd.ms-excel': 'ms-excel',
  'application/vnd.ms-excel.sheet.macroEnabled.12': 'ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    'ms-excel',
  'application/vnd.ms-excel.template.macroEnabled.12': 'ms-excel',

  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'ms-powerpoint',
  'application/vnd.ms-powerpoint': 'ms-powerpoint',
  'application/vnd.ms-powerpoint.template.macroEnabled.12': 'ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.template':
    'ms-powerpoint',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    'ms-powerpoint',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'ms-powerpoint',

  'application/vnd.ms-project': 'ms-project',

  'application/visio': 'ms-visio',
  'application/x-visio': 'ms-visio',
  'application/vnd.visio': 'ms-visio',
  'application/vnd.ms-visio.drawing': 'ms-visio',
  'application/vnd.visio2013': 'ms-visio',
  'application/vnd.ms-visio.drawing.main+xml': 'ms-visio',
  'application/visio.drawing': 'ms-visio',
  'application/vsd ': 'ms-visio',
  'application/x-vsd': 'ms-visio',
  'image/x-vsd': 'ms-visio',

  'application/pdf': 'pdf',

  'image/png': 'image',
  'image/jpeg': 'image',
  'image/tiff': 'image',
  'image/svg+xml': 'image',

  'message/rfc822': 'email',

  'video/mp4': 'video',

  'application/x-zip-compressed': 'zip',
  'application/zip': 'zip',

  'application/oneNote': 'onenote',
};

export const getDriveItemType: (driveItem: DriveItem) => string = (
  driveItem
) => {
  if (checkIfOneNote(driveItem)) return 'onenote';
  const mimeType = MIME_TYPES.find(
    (_type) => (driveItem?.file?.mimeType ?? '')?.includes(_type)
  );
  return MIMETYPE_DRIVEITEM_MAP[mimeType] ?? null;
};

export const getPDFType: (driveItem: DriveItem) => string = (driveItem) => {
  const mimeType = MIME_TYPES.find(
    (_type) => (driveItem?.file?.mimeType ?? '')?.includes(_type)
  );
  switch (MIMETYPE_DRIVEITEM_MAP[mimeType]) {
    case 'pdf':
      return 'pdf';
    default:
      return null;
  }
};

export const checkIfLink: (fileName: string) => boolean = (fileName) => {
  return fileName?.toLowerCase().endsWith('.url');
};

export const checkIfOneNote: (driveItem: DriveItem) => boolean = (
  driveItem
) => {
  return driveItem?.package?.type === 'oneNote';
};

export const iconForMimeType: (mimeType: String) => IconName = (
  mimeType = ''
) => {
  const _mimeType = MIME_TYPES.find((_type) => mimeType?.includes(_type));
  switch (MIMETYPE_DRIVEITEM_MAP[_mimeType]) {
    case 'ms-word':
      return 'file-word';
    case 'ms-excel':
      return 'file-excel';
    case 'ms-powerpoint':
      return 'file-powerpoint';
    case 'pdf':
      return 'file-pdf';
    case 'image':
      return 'file-image';
    case 'email':
      return 'envelope';
    case 'video':
      return 'file-video';
    case 'zip':
      return 'file-archive';
    case 'onenote':
      return 'notebook';
    default:
      return 'file';
  }
};

export const colorForIcon: (mimeType: string) => string = (mimeType) => {
  return colorForMimeType(mimeType);
};

export const colorForAttachmentIcon: (type: string) => string = (type) => {
  return colorForMimeType(type);
};

export const replaceMyDriveItemPath = (
  item: DriveUserRemoteItem,
  project: Project
) => {
  let returnPath = item.path ?? '';
  let path = returnPath.split('root:').slice(0);

  if (path.length >= 2) {
    returnPath = path[1] + '/';
  }
  if (returnPath === '') {
    returnPath = '/';
  }
  return project.number + returnPath;
};

export const colorForMimeType: (mimeType: String) => string = (
  mimeType = ''
) => {
  const _mimeType = MIME_TYPES.find((_type) => mimeType?.includes(_type));
  switch (MIMETYPE_DRIVEITEM_MAP[_mimeType]) {
    case 'ms-word':
      return mimeTypes.word;
    case 'ms-excel':
      return mimeTypes.excel;
    case 'ms-powerpoint':
      return mimeTypes.powerpoint;
    case 'pdf':
      return mimeTypes.pdf;
    case 'email':
      return mimeTypes.email;
    case 'image':
      return mimeTypes.image;
    case 'video':
      return mimeTypes.video;
    case 'zip':
      return mimeTypes.zip;
    case 'onenote':
      return mimeTypes.onenote;
    default:
      return '#393939';
  }
};

/* eslint-disable no-control-regex */
export const fileNameRegex =
  /^[^\u0022\u003C\u003E\u007C\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\u0008\u0009\u000A\u000B\u000C\u000D\u000E\u000F\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001A\u001B\u001C\u001D\u001E\u001F\u003A\u002A\u003F\u005C\u002F]*[a-zA-Z0-9\u0028\u0029\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]$/;

export const folderNameRegex =
  /^[^\u0022\u003C\u003E\u007C\u0000\u0001\u0002\u0003\u0004\u0005\u0006\u0007\u0008\u0009\u000A\u000B\u000C\u000D\u000E\u000F\u0010\u0011\u0012\u0013\u0014\u0015\u0016\u0017\u0018\u0019\u001A\u001B\u001C\u001D\u001E\u001F\u003A\u002A\u003F\u005C\u002F]*[a-zA-Z0-9\u0028\u0029\u00c4\u00e4\u00d6\u00f6\u00dc\u00fc\u00df]$/;

export const acceptedFileEndings =
  '.xls, .xlt, .xlm, .xlsx, .xlsm, .xltx, .xltm, .doc, .dot, .docx, .docm, .dotx, .dotm, .docb, .ppt, .pot, .pps, .pptx, .pptm, .potx, .potm, .ppam, .ppsx, .ppsm, .sldx, .sldm';
/* eslint-enable no-control-regex */

export const spreadBreadcrumbItems = (
  resizeObserver: ResizeObserver,
  currentBreadcrumbItems: BreadcrumbItem[],
  breadcrumbItems: BreadcrumbItem[],
  theme: PrioTheme,
  isInDrawer: boolean,
  equals: (a: any, b: any) => boolean,
  setCurrentBreadcrumbItems: (items: BreadcrumbItem[]) => void,
  setResizeObserver: (observer: ResizeObserver) => void
) => {
  if (
    !equals(
      currentBreadcrumbItems.map(({ id }) => ({
        id,
      })),
      breadcrumbItems.map(({ id }) => ({
        id,
      }))
    )
  ) {
    setCurrentBreadcrumbItems(breadcrumbItems);
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
    const items = [...breadcrumbItems];
    items.splice(breadcrumbItems.length - 1);
    const seperatorWidth =
      calcTextWidth('/', theme.old.typography.fontSize.base) + 8;
    const dropDownWidth = 14;
    const widths = items.map(
      (item) =>
        calcTextWidth(item.label, theme.old.typography.fontSize.base) +
        seperatorWidth +
        dropDownWidth
    );
    const correlatedWidths = widths.map((_, index, _widths) => {
      const _widthsCopy = [..._widths];
      return _widthsCopy
        .splice(index)
        .reduce<number>((sum, value) => sum + value, 0);
    });
    setResizeObserver(
      new ResizeObserver((entries) => {
        const _correlatedWidths = correlatedWidths;
        const _breadcrumbItems = [...breadcrumbItems];
        if (entries.length === 1) {
          const entry = entries[0];
          const _children = entry.target.children;
          const _entryWidth = entry.contentRect.width;

          if (_children.length > 3) {
            const _firstChildWidth = (entry.target.firstChild as HTMLDivElement)
              .offsetWidth;
            const _secondChildWidth =
              calcTextWidth('...', theme.old.typography.fontSize.base) +
              dropDownWidth +
              seperatorWidth;
            const _spacingForIcons = isInDrawer ? 0 : 34;
            const _lastChildWidth =
              (
                (entry.target.lastChild.firstChild
                  .firstChild as HTMLDivElement) ??
                (entry.target.lastChild.firstChild as HTMLDivElement)
              ).offsetWidth + _spacingForIcons;
            const _thirdChild = _children[2];
            const _thirdChildChildren = _thirdChild.children;
            _correlatedWidths.forEach((width, index) => {
              if (
                _entryWidth <
                width + _firstChildWidth + _lastChildWidth + _secondChildWidth
              ) {
                if (_thirdChildChildren[index]) {
                  _thirdChildChildren[index].setAttribute(
                    'style',
                    'display: none'
                  );
                  _breadcrumbItems[index] = {
                    ..._breadcrumbItems[index],
                    isHidden: true,
                  };
                }
              } else if (_thirdChildChildren[index]) {
                _thirdChildChildren[index].setAttribute(
                  'style',
                  'display: unset'
                );
                _breadcrumbItems[index] = {
                  ..._breadcrumbItems[index],
                  isHidden: false,
                };
              }
            });
          }
        }
        setCurrentBreadcrumbItems(_breadcrumbItems);
      })
    );
  }
};

export const selectValuesToApiData: (
  role: FolderPermissionAccessRight,
  value: FolderPermissionValue
) => FolderPermissionV2 = (role, value) => {
  const _canRead = value === 'read';
  const _canWrite = value === 'write';
  return {
    accessRights: role,
    canRead: _canWrite || _canRead || false,
    canWrite: _canWrite || false,
  };
};
