import React, { useCallback, useMemo } from 'react';
import AccessRightsContext, {
  CheckProjectRolesMethod,
  CheckOfficeRolesMethod,
  CheckGlobalRoles,
} from '../context/AccessRightsContext';
import {
  CompanyId,
  OfficeId,
  OfficeRole,
  ProjectId,
  ProjectRole,
} from '../../../models/Types';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import useOfficesContext from '../../companies/hooks/useOfficesContext';
import useProjectsContext from '../../projects/hooks/useProjectsContext';
import useCompaniesContext from '../../companies/hooks/useCompaniesContext';
import { distinct } from '../../../util';

interface CompanyOfficeRolesMap {
  [companyId: CompanyId]: OfficeRole[];
}

interface AccessRightsContextProviderProps {
  children?: React.ReactNode;
}

const AccessRightsContextProvider: React.FC<
  AccessRightsContextProviderProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);

  const { getProjectById, getProjectByProperty } = useProjectsContext();

  const { getOfficeById } = useOfficesContext();

  const { getCompanyById } = useCompaniesContext();

  const projectRoles = useMemo(() => {
    return userMe?.prioData?.projectRoles ?? {};
  }, [userMe?.prioData?.projectRoles]);

  const flattenedProjectRoles = useMemo(() => {
    return distinct(
      Object.keys(projectRoles).reduce((roles, projectId) => {
        return [...roles, ...projectRoles[projectId]];
      }, [] as ProjectRole[])
    );
  }, [projectRoles]);

  const officeRoles = useMemo(() => {
    return userMe?.prioData?.officeRoles ?? {};
  }, [userMe?.prioData?.officeRoles]);

  const flattenedOfficeRoles = useMemo(() => {
    return distinct(
      Object.keys(officeRoles).reduce((roles, officeId) => {
        return [...roles, ...officeRoles[officeId]];
      }, [] as OfficeRole[])
    );
  }, [officeRoles]);

  const companyOfficeRoles = useMemo(() => {
    return Object.keys(userMe?.prioData?.officeRoles).reduce(
      (map, officeId) => {
        const office = getOfficeById(officeId as OfficeId);
        if (!office) {
          return map;
        }
        const companyId = office.companyId;
        return {
          ...map,
          [companyId]: distinct([
            ...(map[companyId] ?? []),
            ...userMe?.prioData?.officeRoles[officeId as OfficeId],
          ]),
        };
      },
      {} as CompanyOfficeRolesMap
    );
  }, [userMe?.prioData?.officeRoles, getOfficeById]);

  const globalRoles = useMemo(() => {
    return userMe?.prioData?.globalRoles ?? [];
  }, [userMe?.prioData?.globalRoles]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const checkProjectRolesOfProjectId: CheckProjectRolesMethod<ProjectId> =
    useCallback(
      (roles, projectId, xor = true) => {
        if (!projectRoles) {
          return false;
        }
        if (!projectId) {
          if (xor) {
            return roles.some((role) => flattenedProjectRoles.includes(role));
          }
          return roles.every((role) => flattenedProjectRoles.includes(role));
        }
        const _projectRoles = projectRoles[projectId];
        if (!_projectRoles) {
          return false;
        }
        if (xor) {
          return roles.some((role) => _projectRoles.includes(role));
        }
        return roles.every((role) => _projectRoles.includes(role));
      },
      [projectRoles, flattenedProjectRoles]
    );

  const checkProjectRolesOfCompanyId: CheckProjectRolesMethod<CompanyId> =
    useCallback(
      (roles, companyId, xor = true) => {
        if (!companyId) {
          return false;
        }
        const company = getCompanyById(companyId);
        if (!company) {
          return false;
        }
        const project = getProjectByProperty('subsidiaryId', company.companyId);
        if (!project) {
          return false;
        }
        return checkProjectRolesOfProjectId(roles, project.projectId, xor);
      },
      [getCompanyById, getProjectByProperty, checkProjectRolesOfProjectId]
    );

  const checkProjectRolesOfOfficeId: CheckProjectRolesMethod<OfficeId> =
    useCallback(
      (roles, officeId, xor = true) => {
        if (!officeId) {
          return false;
        }
        const office = getOfficeById(officeId);
        if (!office) {
          return false;
        }
        return checkProjectRolesOfCompanyId(roles, office.companyId, xor);
      },
      [getOfficeById, checkProjectRolesOfCompanyId]
    );

  const checkProjectRoles = useCallback(
    (roles: ProjectRole[], xor: boolean = true) => {
      if (!flattenedProjectRoles) {
        return false;
      }
      if (xor) {
        return roles.some((role) => flattenedProjectRoles.includes(role));
      }
      return roles.every((role) => flattenedProjectRoles.includes(role));
    },
    [flattenedProjectRoles]
  );

  const checkOfficeRolesOfOfficeId: CheckOfficeRolesMethod<OfficeId> =
    useCallback(
      (roles, officeId, xor = true) => {
        if (!officeRoles) {
          return false;
        }
        if (!officeId) {
          if (xor) {
            return roles.some((role) => flattenedOfficeRoles.includes(role));
          }
          return roles.every((role) => flattenedOfficeRoles.includes(role));
        }
        const _officeRoles = officeRoles[officeId];
        if (!_officeRoles) {
          return false;
        }
        if (xor) {
          return roles.some((role) => _officeRoles.includes(role));
        }
        return roles.every((role) => _officeRoles.includes(role));
      },
      [officeRoles, flattenedOfficeRoles]
    );

  const checkOfficeRolesOfCompanyId: CheckOfficeRolesMethod<CompanyId> =
    useCallback(
      (roles, companyId, xor = true) => {
        if (!companyOfficeRoles || !companyId) {
          return false;
        }
        const _officeRoles = companyOfficeRoles[companyId];
        if (!_officeRoles) {
          return false;
        }
        if (xor) {
          return roles.some((role) => _officeRoles.includes(role));
        }
        return roles.every((role) => _officeRoles.includes(role));
      },
      [companyOfficeRoles]
    );

  const checkOfficeRolesOfProjectId: CheckOfficeRolesMethod<CompanyId> =
    useCallback(
      (roles, projectId, xor = true) => {
        if (!projectId) {
          return false;
        }
        const project = getProjectById(projectId);
        if (!project) {
          return false;
        }

        const internalOfficeId = project.internalOfficeId?.toLowerCase();

        if (internalOfficeId) {
          return checkOfficeRolesOfOfficeId(roles, internalOfficeId, xor);
        }
        return checkOfficeRolesOfCompanyId(roles, project.subsidiaryId, xor);
      },
      [getProjectById, checkOfficeRolesOfCompanyId, checkOfficeRolesOfOfficeId]
    );

  const checkOfficeRoles = useCallback(
    (roles: OfficeRole[], xor: boolean = true) => {
      if (!flattenedOfficeRoles) {
        return false;
      }
      if (xor) {
        return roles.some((role) => flattenedOfficeRoles.includes(role));
      }
      return roles.every((role) => flattenedOfficeRoles.includes(role));
    },
    [flattenedOfficeRoles]
  );

  const checkGlobalRoles: CheckGlobalRoles = useCallback(
    (roles, xor = true) => {
      if (!globalRoles) {
        return false;
      }
      if (xor) {
        return roles.some((role) => globalRoles.includes(role));
      }
      return roles.every((role) => globalRoles.includes(role));
    },
    [globalRoles]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <AccessRightsContext.Provider
      value={{
        checkProjectRolesOfProjectId,
        checkProjectRolesOfCompanyId,
        checkProjectRolesOfOfficeId,
        checkProjectRoles,
        checkOfficeRolesOfOfficeId,
        checkOfficeRolesOfCompanyId,
        checkOfficeRolesOfProjectId,
        checkOfficeRoles,
        checkGlobalRoles,
      }}
    >
      {children}
    </AccessRightsContext.Provider>
  );
};

export default AccessRightsContextProvider;
