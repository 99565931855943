import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, Empty, Menu, Popover, Typography } from 'antd';
import { makePrioStyles } from '../../../theme/utils';
import {
  MonthlyClose,
  MonthlyCloseAbsence,
  TimeKeepingDay,
} from '../../../models/TimeKeeping';
import VirtualTable2, {
  VColumn,
} from '../../../components/VirtualTable/VirtualTable2';
import moment from 'moment';
import classNames from 'classnames';
import { DateTimeString, TimeKeepingDayState } from '../../../models/Types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getOfficeHolidaysOfMonth,
} from '../../../apps/main/rootReducer';
import { OfficeHoliday } from '../../../models/AbsenceProposal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  cell: {
    padding: 10,
  },
  noItemsScreen: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  noItemsIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '200px',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)',
  },
  noItemsText: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  row: {
    position: 'relative',
  },
  rowWithLeftMarker: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      width: 5,
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  weekend: {
    backgroundColor: `${theme.old.palette.chromaticPalette.grey}20`,
  },
  stateLightBlue: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.lightBlue,
    },
  },
  stateBlue: {
    '&::before': {
      backgroundColor: theme.old.palette.primaryColor,
    },
    '& > div > div:last-child': {
      color: theme.old.palette.primaryColor,
    },
  },
  stateYellow: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.yellow,
    },
    '& > div > div:last-child': {
      color: theme.old.palette.chromaticPalette.yellow,
    },
  },
  stateRed: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
    '& > div > div:last-child': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  holidayOrAbsence: {
    backgroundColor: `${theme.old.palette.chromaticPalette.green}20`,
    '&:not($stateBlue):not($stateYellow):not($stateRed) > div > div:nth-child(2)':
      {
        color: theme.old.palette.chromaticPalette.green,
      },
  },
  popover: {
    width: '24px',
    height: '15px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  popoverContent: {
    width: '400px',
  },
  popoverIcon: {
    color: theme.old.palette.chromaticPalette.black,
  },
}));

type TableEntryState = TimeKeepingDayState | 'none';

interface TableEntry {
  timeKeepingDay: TimeKeepingDay;
  date: DateTimeString;
  actualWorkingHours: number;
  sumOfBreaks: number;
  fromTo: string;
  isWeekend: boolean;
  absenceProposal?: MonthlyCloseAbsence;
  officeHoliday?: string;
  type: string;
  state: TableEntryState;
}

interface MonthlyCloseTimekeepingDaysTableProps {
  monthlyClose?: MonthlyClose;
  onRowClick?: (date: DateTimeString) => void;
}

export const MonthlyCloseTimekeepingDaysTable: React.FC<
  MonthlyCloseTimekeepingDaysTableProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { monthlyClose, onRowClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const officeHolidays = useSelector<RootReducerState, OfficeHoliday[]>(
    (state) => getOfficeHolidaysOfMonth(state, monthlyClose?.month)
  );

  const data: TableEntry[] = useMemo(() => {
    if (!monthlyClose) {
      return [];
    }
    const days = moment(monthlyClose.month).daysInMonth();
    return new Array(days).fill(0).map((_, index) => {
      const day = index + 1;
      const date = moment(monthlyClose.month).date(day);
      const timeKeepingDay = monthlyClose.timeKeepingDays.find((t) =>
        moment(t.timeKeepingEntries[0].startTime).isSame(date, 'day')
      );
      const absenceProposal = monthlyClose.monthlyCloseAbsences.find(
        ({ from, to, type }) =>
          date.isSameOrBefore(moment(to)) &&
          date.isSameOrAfter(moment(from)) &&
          type !== 'publicHoliday'
      );
      let actualWorkingHours: number = null;
      let sumOfBreaks: number = null;
      let fromTo: string = null;
      let state: TableEntryState = 'none';
      if (timeKeepingDay) {
        const sortedEntries = timeKeepingDay.timeKeepingEntries.sort((a, b) =>
          moment.utc(a.startTime).diff(moment.utc(b.startTime))
        );
        sortedEntries.forEach(({ startTime, endTime }) => {
          actualWorkingHours =
            (actualWorkingHours ?? 0) +
            moment.utc(endTime).diff(moment.utc(startTime), 'milliseconds');
        });
        const firstToLastEntryDuration = moment
          .utc(sortedEntries[sortedEntries.length - 1].endTime)
          .diff(moment.utc(sortedEntries[0].startTime), 'milliseconds');
        sumOfBreaks = firstToLastEntryDuration - actualWorkingHours;
        fromTo = `${moment
          .utc(sortedEntries[0].startTime)
          .format('H:mm')} - ${moment
          .utc(sortedEntries[sortedEntries.length - 1].endTime)
          .format('H:mm')}`;
        state = timeKeepingDay.state;
      }
      const entry: TableEntry = {
        timeKeepingDay,
        date: date.toISOString(true).split('T')[0],
        sumOfBreaks,
        fromTo,
        actualWorkingHours,
        isWeekend: date.day() === 0 || date.day() === 6,
        absenceProposal: absenceProposal,
        officeHoliday: officeHolidays.find(({ date: _date }) =>
          moment(_date).isSame(date, 'day')
        )?.name,
        type: timeKeepingDay?.type ?? null,
        state,
      };
      return entry;
    });
  }, [monthlyClose, officeHolidays]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const defaultSortData = (data: TimeKeepingDay[]) => {
    data.sort((a, b) => {
      return moment
        .utc(a.timeKeepingEntries[0].startTime)
        .diff(moment.utc(b.timeKeepingEntries[0].startTime));
    });
    return data;
  };
  //#endregion

  //#region ------------------------------ Components
  const noItemsScreen = useCallback(
    () => (
      <div className={classes.noItemsScreen}>
        <div className={classes.noItemsIconContainer}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <span className={classes.noItemsText}>
                {t('documents:table.noItems')}
              </span>
            }
          />
        </div>
      </div>
    ),
    [classes, t]
  );
  //#endregion

  //#region ------------------------------ Columns
  const columns: VColumn<TableEntry>[] = [
    {
      Cell: ({
        row: {
          original: { date },
        },
      }) => moment(date).format('dd., DD. MMMM'),
      cellTitle: ({ date }) => moment(date).format('dd., DD. MMMM'),
      title: t('timeKeeping:monthlyCloseTimeKeepingDaysTable.header.date'),
      width: 20,
      id: 'date',
      accessor: 'date',
      className: classes.cell,
    },
    {
      Cell: ({
        row: {
          original: { fromTo, officeHoliday, absenceProposal },
        },
      }) => {
        if (fromTo) {
          if (officeHoliday || absenceProposal) {
            return (
              <span>
                <span>{fromTo}</span>
                <Dropdown
                  overlay={
                    <Menu
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        domEvent.preventDefault();
                      }}
                    >
                      {officeHoliday && (
                        <Menu.Item key="1">{officeHoliday}</Menu.Item>
                      )}
                      {absenceProposal && (
                        <Menu.Item key="2">
                          {t(
                            `absences:form.absenceTypes.${absenceProposal.type}`
                          )}
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                  placement="bottomRight"
                >
                  <FontAwesomeIcon
                    icon={['fal', 'chevron-down']}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    style={{ marginLeft: 5 }}
                  />
                </Dropdown>
              </span>
            );
          }
          return fromTo;
        }
        if (officeHoliday) {
          if (absenceProposal) {
            return (
              <span>
                <span>{officeHoliday}</span>
                <Dropdown
                  overlay={
                    <Menu
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        domEvent.preventDefault();
                      }}
                    >
                      <Menu.Item key="1">
                        {t(
                          `absences:form.absenceTypes.${absenceProposal.type}`
                        )}
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                >
                  <FontAwesomeIcon
                    icon={['fal', 'chevron-down']}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    style={{ marginLeft: 5 }}
                  />
                </Dropdown>
              </span>
            );
          }
          return officeHoliday;
        }
        if (absenceProposal) {
          return t(`absences:form.absenceTypes.${absenceProposal.type}`);
        }
        return '-';
      },
      cellTitle: ({ fromTo, officeHoliday, absenceProposal }) => {
        if (fromTo) {
          return fromTo;
        }
        if (officeHoliday) {
          return officeHoliday;
        }
        if (absenceProposal) {
          return t(`absences:form.absenceTypes.${absenceProposal.type}`);
        }
        return null;
      },
      width: 20,
      id: 'fromTo',
      className: classes.cell,
      accessor: 'fromTo',
      title: t('timeKeeping:monthlyCloseTimeKeepingDaysTable.header.fromTo'),
    },
    {
      Cell: ({
        row: {
          original: { actualWorkingHours },
        },
      }) =>
        actualWorkingHours ? `${actualWorkingHours / (1000 * 60 * 60)} h` : '-',
      cellTitle: ({ actualWorkingHours }) =>
        actualWorkingHours
          ? `${actualWorkingHours / (1000 * 60 * 60)} h`
          : null,
      title: t(
        'timeKeeping:monthlyCloseTimeKeepingDaysTable.header.actualWorkingHours'
      ),
      width: 10,
      id: 'actualWorkingHours',
      className: classes.cell,
      accessor: 'actualWorkingHours',
    },
    {
      Cell: ({
        row: {
          original: { sumOfBreaks },
        },
      }) => (sumOfBreaks ? `${sumOfBreaks / (1000 * 60 * 60)} h` : '-'),
      cellTitle: ({ sumOfBreaks }) =>
        sumOfBreaks ? `${sumOfBreaks / (1000 * 60 * 60)} h` : null,
      title: t(
        'timeKeeping:monthlyCloseTimeKeepingDaysTable.header.sumOfBreaks'
      ),
      width: 10,
      id: 'sumOfBreaks',
      className: classes.cell,
      accessor: 'sumOfBreaks',
    },
    {
      Cell: ({
        row: {
          original: { type },
        },
      }) => {
        if (type) {
          return t(
            `hr:timeAndLeaveManagement.monthlyCloseDrawer.timekeepingDaysTable.type.${type}`
          );
        }
        return '-';
      },
      cellTitle: ({ type }) => {
        if (type) {
          return t(
            `hr:timeAndLeaveManagement.monthlyCloseDrawer.timekeepingDaysTable.type.${type}`
          );
        }
        return null;
      },
      title: t('timeKeeping:monthlyCloseTimeKeepingDaysTable.header.type'),
      width: 20,
      id: 'type',
      accessor: 'type',
      className: classes.cell,
    },
    {
      Cell: ({
        row: {
          original: { state, timeKeepingDay },
        },
      }) => {
        const title =
          state === 'none'
            ? null
            : t(`timeKeeping:monthlyCloseTimeKeepingDaysTable.state.${state}`);
        const content = () => {
          return (
            <div className={classes.popoverContent}>
              <Typography.Text type={'secondary'} style={{ fontSize: 14 }}>
                {t(`timeKeeping:monthlyCloseTimeKeepingDaysTable.notesHeader`)}
              </Typography.Text>
              <br />
              <Typography.Text>{timeKeepingDay?.notes}</Typography.Text>
            </div>
          );
        };
        if (state === 'none') {
          return '-';
        }
        return (
          <Flex.Row alignItems="center">
            <div style={{ flex: 1 }} title={title}>
              {t(`timeKeeping:monthlyCloseTimeKeepingDaysTable.state.${state}`)}
            </div>
            {timeKeepingDay?.notes && (
              <Flex.Item>
                <Popover placement="bottomRight" content={content}>
                  <div className={classes.popover}>
                    <FontAwesomeIcon
                      icon={['fal', 'notes']}
                      onClick={(e) => e.stopPropagation()}
                      className={classes.popoverIcon}
                    />
                  </div>
                </Popover>
              </Flex.Item>
            )}
          </Flex.Row>
        );
      },
      title: t('timeKeeping:monthlyCloseTimeKeepingDaysTable.header.state'),
      width: 20,
      id: 'state',
      accessor: 'state',
      className: classes.cell,
    },
  ];
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      <VirtualTable2
        id={'prio-monthlyClose-timekeepingDaysTable'}
        className={classNames(classes.root)}
        columnsResizable={true}
        columns={columns}
        data={data}
        noItemsScreen={noItemsScreen()}
        classNameTableRow={(item: TableEntry) => {
          const { isWeekend, absenceProposal, officeHoliday, state } = item;
          return classNames(classes.row, {
            [classes.weekend]: isWeekend,
            [classes.rowWithLeftMarker]: state !== 'none',
            [classes.holidayOrAbsence]: !!absenceProposal || !!officeHoliday,
            [classes.stateBlue]: state === 'ok' || state === 'approved',
            [classes.stateLightBlue]: state === 'locked',
            [classes.stateYellow]:
              state === 'approvalRequested' || state === 'recording',
            [classes.stateRed]: state === 'notOk' || state === 'notApproved',
          });
        }}
        dataToForceRender={defaultSortData(monthlyClose?.timeKeepingDays ?? [])}
        onRow={{
          triggerFunctions: ({ date }: TableEntry) => ({
            onClick: onRowClick
              ? () => {
                  onRowClick(date);
                }
              : undefined,
          }),
        }}
      />
    </>
  );
};

export default MonthlyCloseTimekeepingDaysTable;
