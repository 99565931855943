import { useMemo } from 'react';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import useContactsContext from '../../../modules/contacts/hooks/useContactsProvider';
import useFilterPickerContext from '../hooks/useFilterPickerContext';
import { Contact } from '../../../models/Contact';

interface ContactFilterPickerConsumerProps extends FilterPickerConsumerProps {
  type?: 'all' | 'internal' | 'external';
}

const ContactFilterPickerConsumer: React.FC<
  ContactFilterPickerConsumerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { children, type = 'all' } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { contacts: _contacts } = useContactsContext();

  const typeToPickerType = useMemo(() => {
    switch (type) {
      case 'internal':
        return 'internalContact';
      case 'external':
        return 'externalContact';
      default:
        return 'contact';
    }
  }, [type]);

  const pickerFilterFunction =
    useFilterPickerContext<Contact>(typeToPickerType);

  const contacts = useMemo(() => {
    if (type === 'internal') {
      return _contacts.filter(
        (contact) => contact.contactType === 'InternalContact'
      );
    }
    if (type === 'external') {
      return _contacts.filter(
        (contact) => contact.contactType === 'ExternalContact'
      );
    }
    return _contacts;
  }, [type, _contacts]);

  const filteredContacts = useMemo(() => {
    if (pickerFilterFunction) {
      return contacts.filter(pickerFilterFunction);
    }
    return contacts;
  }, [contacts, pickerFilterFunction]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      {children({
        options: filteredContacts.map(({ contactId, firstName, lastName }) => {
          return {
            value: contactId,
            label: `${firstName} ${lastName}`,
            searchValue: `${firstName} ${lastName} ${contactId}`,
          };
        }),
      })}
    </>
  );
};

export default ContactFilterPickerConsumer;
