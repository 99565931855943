import { Contact } from '../../../models/Contact';

export const sortContactsHelper = (
  a: Contact | undefined,
  b: Contact | undefined
) => {
  if (a === undefined && b === undefined) {
    return 0;
  }
  if (a === undefined) {
    return 1;
  }
  if (b === undefined) {
    return -1;
  }
  const lastNameCompare = (a?.lastName ?? '').localeCompare(b?.lastName ?? '');
  if (lastNameCompare !== 0) {
    return lastNameCompare;
  }
  return (a?.firstName ?? '').localeCompare(b?.firstName ?? '');
};
