import { useTranslation } from 'react-i18next';
import InformationBox from '../../../../components/InformationBox';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '../../../../theme/utils';
import AddInternalContactDrawerProjectExtensionItem from './AddInternalContactDrawerProjectExtensionItem';
import { ProjectExtensionData } from './AddInternalContactDrawerUserConfigForm';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  header: {
    fontSize: theme.font.fontSize.large,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.application.typography.default,
    paddingTop: theme.spacing.regular,
  },
  addInsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
  },
}));

interface AddInConfigurationProps {
  projectExtensions: ProjectExtensionData[];
  projectExtensionSetters: {
    [key: string]: (value: boolean) => void;
  };
  numberOfDefaultAccesses: number;
}

const AddInConfiguration: React.FC<AddInConfigurationProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    projectExtensions,
    projectExtensionSetters,
    numberOfDefaultAccesses,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  if (!projectExtensions.length) {
    return null;
  }
  return (
    <>
      <div className={classes.header}>
        {t(
          `contacts:addInternalContactDrawer.userConfigStep.form.title.addIns`
        )}
      </div>
      <div className={classes.addInsContainer}>
        {projectExtensions
          ?.sort((a, b) => b.type.localeCompare(a.type))
          ?.map((item, index) => (
            <AddInternalContactDrawerProjectExtensionItem
              key={index}
              index={index}
              type={item.type}
              checked={item.checked}
              hasDefaultAccess={item.default}
              setExtension={projectExtensionSetters[item.type]}
            />
          ))}
      </div>
      <InformationBox
        description={t(
          `contacts:addInternalContactDrawer.userConfigStep.form.addInEditWarning${
            numberOfDefaultAccesses > 1 ? 'Multiple' : 'Single'
          }`
        )}
        marginBottom="regular"
      />
    </>
  );
};

export default AddInConfiguration;
