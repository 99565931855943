import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import {
  Button,
  Dropdown,
  Modal,
  ResponsiveContainer,
} from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import {
  getActiveProjectProject,
  getCurrentDriveItem,
  getCurrentFolderItem,
  getDocumentsMetaAreMultipleSelected,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { setDocumentsMetaState } from '../actions/meta';
import { DriveItem } from '../../../models/Drive';
import { Project } from '../../../models/Project';
import { User } from '../../../models/User';
import {
  addDriveItemPermissions,
  apiCreateDriveFolder,
  apiDownloadDriveItems,
} from '../api';
import { fetchDriveItemsSagaAction } from '../actions';
import { GroupId, ProjectId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import NewFolderModal from '../../settings/components/NewFolderModal';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import UploadField, {
  PrioFile,
  UploadFieldProps,
} from '../../../components/Upload/UploadField';
import {
  sagaStartUploadFiles,
  sagaUploadFiles,
} from '../sagas/watchUploadFiles';
import { addPrioFilesToUploadList } from '../actions/uploadLists';
import classnames from 'classnames';
import { setCurrentPreviewModalVisibility } from '../actions/previewModal';
import { NewDocumentFromTemplateDrawer } from './Drawers/NewDocumentFromTemplateDrawer';
import { isDriveItemFolder } from '../util';
import CreateNewDocumentDropdownOptions from './CreateNewDocumentDropdownOptions';
import { NewDocumentDrawer } from './Drawers/NewDocumentDrawer';
import { NewDocumentType } from '../../../models/Document';
import { isProjectAdmin } from '../../projects/utils';
import { CreateFolderRequest } from '../../settings/components/NewFolderForm';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: 64,
    minHeight: 64,
    borderBottom: theme.old.borders.sub,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  contextPartContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.regular,
    padding: `0px ${theme.spacing.regular}px`,
    height: 64,
    minHeight: 64,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contextPart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selectedItemCount: {
    fontStyle: 'italic',
    color: 'rgba(0, 0, 0, 0.45)',
    userSelect: 'none',
    margin: 0,
    whiteSpace: 'nowrap',
    width: '105px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dropDownButton: {
    height: '32px',
    justifyContent: 'space-evenly',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    border: '1px solid transparent',
    '&:hover': {
      backgroundColor: theme.colors.base.blue[50],
    },
  },
  dropdown: {
    padding: 0,
    zIndex: 999,
  },
  dropDownOption: {
    paddingLeft: theme.old.spacing.unit(1),
    height: '32px',
    display: 'flex',
    alignItems: 'center',
  },
}));

interface DocumentsNavigationBarProps {
  projectId: ProjectId;
  groupId: GroupId;
  selectedDriveItems?: DriveItem[];
  setSelectedDriveItems?: (selectedDriveItems: DriveItem[]) => void;
  refetchDocuments?: () => void;
}

export const DocumentsNavigationBar: React.FC<DocumentsNavigationBarProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    projectId,
    groupId,
    selectedDriveItems,
    setSelectedDriveItems,
    refetchDocuments,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const pathPrefix = './';
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const driveItem = useSelector<RootReducerState, DriveItem>(
    getCurrentDriveItem
  );

  const [downloadModalVisible, setDownloadModalVisible] =
    useState<boolean>(false);
  const isRoot = driveItem?.name === 'root';

  const [openNewDocFromTemplateDrawer, setOpenNewDocFromTemplateDrawer] =
    useState<boolean>(false);
  const [openNewDocDrawer, setOpenNewDocDrawer] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<NewDocumentType>(null);
  const [prefix, setprefix] = useState<string>(null);

  const selectedDriveItemsWithoutFolder = useMemo(
    () => selectedDriveItems?.filter((item) => !isDriveItemFolder(item)) ?? [],
    [selectedDriveItems]
  );
  const rootFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, `root-group-${groupId}`)
  );
  const userMe = useSelector<RootReducerState, User>(getUserMe);
  const activeProject = useSelector<RootReducerState, Project>(
    getActiveProjectProject
  );
  const activeOneDriveSyncEnabled =
    !!activeProject && !!userMe?.mail && !!rootFolder?.sharepointIds?.siteId;

  const areMultipleSelected = useSelector(getDocumentsMetaAreMultipleSelected);

  const currentFolder = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, driveItem?.id ?? `root-group-${groupId}`)
  );

  const [newFolderModalOpen, setNewFolderModalOpen] = useState<boolean>(false);
  const [newFolderCreating, setNewFolderCreating] = useState<boolean>(false);
  const [isDropdownNewDocumentOpen, setIsDropdownNewDocumentOpen] =
    useState(false);

  const isRootAndProjectAdmin = isRoot && isProjectAdmin(activeProject, userMe);

  const uploadProps: UploadFieldProps = useMemo(
    () => ({
      isButton: true,
      multiple: true,
      onBeforeUpload: (originFiles: PrioFile[], sessionId: string) => {
        dispatch(
          sagaStartUploadFiles(
            groupId,
            sessionId,
            driveItem?.id ?? `root-group-${groupId}`,
            projectId
          )
        );
      },
      onAfterUpload: async (fileList: PrioFile[], sessionId: string) => {
        dispatch(
          addPrioFilesToUploadList(
            groupId,
            fileList.map(
              ({
                fileId,
                name,
                webkitRelativePath,
                size,
                type,
                sessionId,
              }) => ({
                fileId,
                sessionId,
                parentDriveItemId: driveItem?.id ?? `root-group-${groupId}`,
                name,
                path: webkitRelativePath,
                size,
                isFolder: false,
                mimeType: type,
                driveItemId: null,
              })
            )
          )
        );
        dispatch(
          sagaUploadFiles(
            groupId,
            sessionId,
            driveItem?.id ?? `root-group-${groupId}`,
            projectId,
            fileList
          )
        );
      },
    }),
    [driveItem, projectId, groupId, dispatch]
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onCreateDocumentSuccess = (
    folder?: DriveItem,
    selectedProjectId?: ProjectId
  ) => {
    setOpenNewDocFromTemplateDrawer(false);
    setOpenNewDocDrawer(false);
    if (folder?.id) {
      if (selectedProjectId === projectId) {
        const isFolderRoot = folder?.name === 'root';
        navigate(
          !isFolderRoot
            ? `${pathPrefix}folder/${folder.id}`
            : `${pathPrefix}all`
        );
      }
    } else {
      navigate(
        driveItem && !isRoot
          ? `${pathPrefix}folder/${driveItem?.id}`
          : `${pathPrefix}all`
      );
    }
  };

  const createFolder = useCallback(async () => {
    if (!!groupId || isRootAndProjectAdmin) {
      setNewFolderModalOpen(true);
    }
  }, [groupId, isRootAndProjectAdmin]);

  const showMoveDrawer = useCallback(() => {
    dispatch(
      setDocumentsMetaState({
        copyDocumentsDrawerState: 'move',
      })
    );
  }, [dispatch]);

  const showCopyDrawer = useCallback(() => {
    dispatch(
      setDocumentsMetaState({
        copyDocumentsDrawerState: 'copy',
      })
    );
  }, [dispatch]);

  const openPreviewToEditMetaData = useCallback(() => {
    dispatch(
      setCurrentPreviewModalVisibility(
        true,
        selectedDriveItems[0]?.id,
        groupId,
        selectedDriveItems[0],
        selectedDriveItemsWithoutFolder,
        null,
        projectId,
        '',
        false,
        selectedDriveItemsWithoutFolder,
        'documents'
      )
    );
    setSelectedDriveItems([]);
  }, [
    dispatch,
    selectedDriveItems,
    selectedDriveItemsWithoutFolder,
    groupId,
    projectId,
    setSelectedDriveItems,
  ]);

  const showDeleteModal = useCallback(() => {
    dispatch(
      setDocumentsMetaState({
        showDeleteDocumentsModal: true,
      })
    );
  }, [dispatch]);

  const downloadFiles = useCallback(() => {
    apiDownloadDriveItems(groupId, selectedDriveItemsWithoutFolder);
    setSelectedDriveItems([]);
    setDownloadModalVisible(false);
  }, [
    groupId,
    selectedDriveItemsWithoutFolder,
    setSelectedDriveItems,
    setDownloadModalVisible,
  ]);

  const onNewFolderOk = async (value: CreateFolderRequest) => {
    if (value.folderName === '') {
      closeNewFolderModal();
      return;
    }
    setNewFolderCreating(true);
    const { data } = await apiCreateDriveFolder(
      groupId,
      driveItem?.id,
      value.folderName
    );
    if (isRoot)
      await addDriveItemPermissions(data.id, value, projectId, groupId);
    setNewFolderCreating(false);
    if (data) {
      const driveItemId = driveItem?.id ?? `root-group-${groupId}`;
      dispatch(
        fetchDriveItemsSagaAction(
          projectId,
          groupId,
          driveItem?.id,
          250,
          driveItemId.includes('root-group-'),
          false,
          true
        )
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('documents:errorMessages.newFolderCreateError'),
      });
    }
    refetchDocuments();
    closeNewFolderModal();
  };

  const onNewFolderCancel = () => {
    closeNewFolderModal();
  };

  const closeNewFolderModal = () => {
    setNewFolderModalOpen(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion
  return (
    <>
      <div id="prio-module-navigation-bar" className={classes.root}>
        <div className={classes.row}>
          <div
            className={classes.contextPartContainer}
            style={
              selectedDriveItems.length > 0
                ? { width: 'calc(100% - 135px)' }
                : { width: '100%' }
            }
          >
            <ResponsiveContainer
              children={[
                <Dropdown
                  className={classes.dropdown}
                  overlay={
                    <CreateNewDocumentDropdownOptions
                      projectId={projectId}
                      setOpenNewDocFromTamplateDrawer={
                        setOpenNewDocFromTemplateDrawer
                      }
                      setOpenNewDocDrawer={setOpenNewDocDrawer}
                      setIsDropdownNewDocumentOpen={
                        setIsDropdownNewDocumentOpen
                      }
                      onDocumentTypeSelect={(id, prefix) => {
                        setDocumentType(id);
                        setprefix(prefix);
                      }}
                    />
                  }
                  onVisibleChange={(visible) =>
                    setIsDropdownNewDocumentOpen(visible)
                  }
                  visible={isDropdownNewDocumentOpen}
                  trigger={['click']}
                >
                  <Button
                    type="primary"
                    iconProp={['fal', 'file']}
                    suffixIconProp={['fal', 'chevron-down']}
                    disabled={isRoot}
                  >
                    <span>{t('documents:navigationBar.createDocument')}</span>
                  </Button>
                </Dropdown>,
                <Button
                  onClick={createFolder}
                  disabled={
                    (currentFolder?.name === 'root' ||
                      rootFolder?.id === driveItem?.id) &&
                    !isRootAndProjectAdmin
                  }
                  iconProp={['fal', 'folder-plus']}
                  type="default"
                >
                  <span>{t('documents:navigationBar.createFolder')}</span>
                </Button>,

                <Button
                  href={driveItem?.webUrl}
                  disabled={!activeOneDriveSyncEnabled}
                  iconProp={['fal', 'external-link-alt']}
                  type="default"
                >
                  <span>{t('documents:navigationBar.openInSharePoint')}</span>
                </Button>,
                <Dropdown
                  overlay={
                    <div
                      style={{
                        fontSize: theme.font.fontSize.small,
                      }}
                    >
                      <div
                        className={classnames(
                          'prio-dropdown-option-root',
                          classes.dropDownOption
                        )}
                      >
                        <UploadField {...uploadProps}>
                          {t('documents:navigationBar.uploadFile')}
                        </UploadField>
                      </div>
                      <div
                        className={classnames(
                          'prio-dropdown-option-root',
                          classes.dropDownOption
                        )}
                      >
                        <UploadField {...uploadProps} directory>
                          {t('documents:navigationBar.uploadFolder')}
                        </UploadField>
                      </div>
                    </div>
                  }
                >
                  <Button
                    suffixIconProp={['fal', 'chevron-down']}
                    type="default"
                    iconProp={['fal', 'upload']}
                  >
                    {t('documents:navigationBar.upload')}
                  </Button>
                </Dropdown>,
                <Button
                  disabled={!areMultipleSelected}
                  iconProp={['fal', 'file-export']}
                  onClick={showMoveDrawer}
                  type="default"
                >
                  {t('documents:navigationBar.move')}
                </Button>,
                <Button
                  disabled={!areMultipleSelected}
                  iconProp={['fal', 'copy']}
                  type="default"
                  onClick={showCopyDrawer}
                >
                  {t('documents:navigationBar.copy')}
                </Button>,
                <Button
                  disabled={
                    !(
                      areMultipleSelected &&
                      selectedDriveItemsWithoutFolder.length > 0
                    )
                  }
                  iconProp={['fal', 'pen']}
                  type="default"
                  onClick={openPreviewToEditMetaData}
                >
                  {t('documents:navigationBar.editMetaData')}
                </Button>,
                <Button
                  type="default"
                  iconProp={['fal', 'arrow-to-bottom']}
                  onClick={() => {
                    if (selectedDriveItemsWithoutFolder.length < 10) {
                      downloadFiles();
                    } else {
                      setDownloadModalVisible(true);
                    }
                  }}
                  disabled={
                    selectedDriveItems.every((item) =>
                      isDriveItemFolder(item)
                    ) || selectedDriveItems.length === 0
                  }
                >
                  {t('common:downloadFiles')}
                </Button>,
                <Button
                  type="default"
                  disabled={
                    !areMultipleSelected || !!location.pathname.match(/(all)$/)
                  }
                  iconProp={['fal', 'trash']}
                  onClick={showDeleteModal}
                >
                  {t('documents:navigationBar.delete')}
                </Button>,
              ]}
            />
          </div>
          {selectedDriveItems.length > 0 && (
            <div className={classes.selectedItemCount}>
              {`${selectedDriveItems.length} ${t(
                'documents:navigationBar.selectedItemsCount'
              )}`}
            </div>
          )}
        </div>
      </div>
      <NewFolderModal
        currentPath={[
          driveItem?.name === 'root'
            ? t('documents:rootFolder')
            : driveItem?.name ?? '',
        ]}
        visible={
          newFolderModalOpen &&
          (driveItem?.name !== 'root' || isRootAndProjectAdmin)
        }
        onOk={onNewFolderOk}
        onCancel={onNewFolderCancel}
        confirmLoading={newFolderCreating}
        usePermissions={isRootAndProjectAdmin}
      />
      <NewDocumentFromTemplateDrawer
        driveItem={driveItem}
        projectId={projectId}
        groupId={groupId}
        onSuccess={onCreateDocumentSuccess}
        onCancel={() => {
          setOpenNewDocFromTemplateDrawer(false);
        }}
        visible={openNewDocFromTemplateDrawer}
      />
      <NewDocumentDrawer
        projectId={projectId}
        groupId={groupId}
        onSuccess={onCreateDocumentSuccess}
        onCancel={() => {
          setOpenNewDocDrawer(false);
        }}
        visible={openNewDocDrawer}
        parentFolderDriveItem={currentFolder}
        driveItem={driveItem}
        documentType={documentType}
        prefix={prefix}
        handleReloadDocuments={refetchDocuments}
      />
      <Modal
        visible={downloadModalVisible}
        title={t('common:downloadFiles')}
        onOk={downloadFiles}
        onClose={() => setDownloadModalVisible(false)}
        okText={t('common:downloadFiles')}
        cancelText={t('common:cancel')}
        closable
      >
        <p>
          {t('documents:navigationBar.downloadMultipleFilesWarning', {
            count: selectedDriveItemsWithoutFolder.length,
          })}
        </p>
      </Modal>
    </>
  );
};

export default DocumentsNavigationBar;
