import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import Menu from '@prio365/prio365-react-library/lib/Menu';
import { Button } from '@prio365/prio365-react-library';
import {
  allowedFileTypesForPdfConversion,
  DriveItem,
} from '../../../models/Drive';
import {
  checkIfOneNote,
  getDriveItemType,
  getPDFType,
  isDriveItemFolder,
} from '../util';
import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import { isProjectAdmin } from '../../projects/utils';
import { Project } from '../../../models/Project';

declare const Placements: [
  'topLeft',
  'topCenter',
  'topRight',
  'bottomLeft',
  'bottomCenter',
  'bottomRight',
];
declare type Placement = (typeof Placements)[number];

interface DocumentsTableContextMenuProps {
  className?: string;
  project: Project;
  iconClassname?: string;
  driveItem: DriveItem;
  isRoot?: boolean;
  parentIsRoot: boolean;
  onMenuRename?: (driveItem: DriveItem) => void;
  onMenuDelete?: (driveItem: DriveItem) => void;
  onMenuDownload?: (driveItem: DriveItem) => void;
  onMenuCopy?: (driveItem: DriveItem) => void;
  onMenuMove?: (driveItem: DriveItem) => void;
  openLocally?: (driveItem: DriveItem, openPdf: boolean) => Promise<void>;
  openInBrowser?: (driveItem: DriveItem) => Promise<void>;
  openParentFolder?: (driveItem: DriveItem) => void;
  onCopyAsPdf?: (driveItem: DriveItem) => void;
  onUnzipArchive?: (driveItem: DriveItem) => void;
  isSearchingDocumentsInWidgetBar?: boolean;
  isKeywordSearch?: boolean;
  buttonSize?: 'small' | 'default' | 'large';
  onDropdownClick?: (driveItem: DriveItem) => void;
  onDropdownClose?: (value: boolean) => void;
  onMenuEditFolderAccessRights: (driveItem: DriveItem) => void;
}
const useStyles = makePrioStyles((theme: PrioTheme) => ({
  button: {
    height: '100%',
    backgroundColor: 'transparent',
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
  },
  menu: {
    padding: 0,
    boxShadow: theme.boxShadow.regular,
    '& > .prio-menu-item': {
      marginLeft: 0,
    },
    '& > .prio-menu-item-disabled': {
      backgroundColor: `${theme.colors.application.background.default}!important`,
    },
  },
}));

export const DocumentsTableContextMenu: React.FC<
  DocumentsTableContextMenuProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    project,
    driveItem,
    isRoot,
    parentIsRoot,
    onMenuRename,
    onMenuCopy,
    onMenuDelete,
    onMenuDownload,
    onMenuMove,
    openLocally,
    openInBrowser,
    openParentFolder,
    onCopyAsPdf,
    onUnzipArchive,
    isSearchingDocumentsInWidgetBar,
    isKeywordSearch,
    buttonSize,
    onDropdownClick,
    onDropdownClose,
    onMenuEditFolderAccessRights,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles(props);
  const buttonRef = useRef<HTMLButtonElement>(null);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const type = getDriveItemType(driveItem);
  const pdfType = getPDFType(driveItem);
  const userMe = useSelector(getUserMe);

  const [placement, setPlacement] = useState<Placement>('bottomRight');
  const [contextMenuVisible, setContextMenuVisible] = useState<boolean>(false);

  const isRootAndProjectAdmin = parentIsRoot && isProjectAdmin(project, userMe);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const convertToPdfIsDisabled = (driveItem: DriveItem) => {
    const splittedFilename = driveItem.name.split('.');
    const fileExtension = splittedFilename[splittedFilename.length - 1];
    if (
      allowedFileTypesForPdfConversion.some(
        (allowedFileExtension) => allowedFileExtension === fileExtension
      )
    )
      return false;
    return true;
  };

  // makes sure that only one context menu is opened at a time
  const handleMenuItemClick = () => {
    setContextMenuVisible(!contextMenuVisible);
    onDropdownClick && onDropdownClick(driveItem);
  };

  const handleClickOutside = () => {
    setContextMenuVisible(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (buttonRef?.current) {
      if (
        buttonRef?.current.getBoundingClientRect().y >
        document.body.offsetHeight - 300
      ) {
        setPlacement('topRight');
      }
    }
  }, [buttonRef]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!contextMenuVisible && onDropdownClose) {
      onDropdownClose(false);
    }
  }, [contextMenuVisible, onDropdownClose]);

  //#endregion

  return (
    <Dropdown
      onVisibleChange={handleMenuItemClick}
      className={className}
      trigger={['click']}
      placement={placement}
      visible={contextMenuVisible}
      overlay={
        <Menu className={classes.menu}>
          {!(isDriveItemFolder(driveItem) || checkIfOneNote(driveItem)) && (
            <Menu.MenuItem
              id="download"
              onClick={(e) => {
                e.stopPropagation();
                setContextMenuVisible(false);
                onMenuDownload(driveItem);
              }}
              label={t('documents:table.actions.download')}
            ></Menu.MenuItem>
          )}
          {(isSearchingDocumentsInWidgetBar || isKeywordSearch) &&
            !(
              !openParentFolder ||
              (!isSearchingDocumentsInWidgetBar && parentIsRoot)
            ) && (
              <Menu.MenuItem
                id="openParentFolder"
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuVisible(false);
                  openParentFolder(driveItem);
                }}
                label={t('documents:table.actions.openParentFolder')}
              ></Menu.MenuItem>
            )}
          {(!parentIsRoot || isRootAndProjectAdmin) && onMenuDelete && (
            <Menu.MenuItem
              id="delete"
              onClick={(e) => {
                e.stopPropagation();
                setContextMenuVisible(false);
                onMenuDelete(driveItem);
              }}
              label={t('documents:table.actions.delete')}
            ></Menu.MenuItem>
          )}
          {onMenuRename && (
            <Menu.MenuItem
              id="rename"
              onClick={(e) => {
                e.stopPropagation();
                setContextMenuVisible(false);
                onMenuRename(driveItem);
              }}
              label={t('documents:table.actions.rename')}
            ></Menu.MenuItem>
          )}

          {onMenuMove && (
            <Menu.MenuItem
              id="move"
              onClick={(e) => {
                e.stopPropagation();
                setContextMenuVisible(false);
                onMenuMove(driveItem);
              }}
              label={t('documents:table.actions.move')}
            ></Menu.MenuItem>
          )}

          {onMenuCopy && (
            <Menu.MenuItem
              id="copy"
              onClick={(e) => {
                e.stopPropagation();
                setContextMenuVisible(false);
                onMenuCopy(driveItem);
              }}
              label={t('documents:table.actions.copy')}
            ></Menu.MenuItem>
          )}

          {type !== 'zip' && openInBrowser && (
            <Menu.MenuItem
              id="openInBrowser"
              onClick={(e) => {
                e.stopPropagation();
                setContextMenuVisible(false);
                openInBrowser(driveItem);
              }}
              label={t('documents:table.actions.openInBrowser')}
            ></Menu.MenuItem>
          )}

          {type !== 'zip' &&
            !isDriveItemFolder(driveItem) &&
            !(convertToPdfIsDisabled(driveItem) && isRoot) &&
            onCopyAsPdf && (
              <Menu.MenuItem
                id="saveAsPdf"
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuVisible(false);
                  onCopyAsPdf(driveItem);
                }}
                label={t('documents:table.actions.saveAsPdf')}
              ></Menu.MenuItem>
            )}
          {type === 'zip' && onUnzipArchive && (
            <Menu.MenuItem
              id="unzipArchive"
              onClick={(e) => {
                e.stopPropagation();
                setContextMenuVisible(false);
                onUnzipArchive(driveItem);
              }}
              label={t('documents:table.actions.unzipArchive')}
            ></Menu.MenuItem>
          )}
          {(type || pdfType) &&
            openLocally &&
            !isDriveItemFolder(driveItem) &&
            type !== 'zip' && (
              <Menu.MenuItem
                id="openLocally"
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuVisible(false);
                  openLocally(driveItem, true);
                }}
                label={t('documents:table.actions.openLocally')}
              ></Menu.MenuItem>
            )}
          {isDriveItemFolder(driveItem) &&
            isProjectAdmin(project, userMe) &&
            parentIsRoot && (
              <Menu.MenuItem
                id="openLocally"
                onClick={(e) => {
                  e.stopPropagation();
                  setContextMenuVisible(false);
                  onMenuEditFolderAccessRights(driveItem);
                }}
                label={t('documents:table.actions.editAccessRights')}
              ></Menu.MenuItem>
            )}
        </Menu>
      }
    >
      <Button
        iconProp={['fal', 'ellipsis-v']}
        className={classes.button}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setContextMenuVisible(!contextMenuVisible);
        }}
        ref={buttonRef}
        type="link"
        size={buttonSize}
      />
    </Dropdown>
  );
};

export default DocumentsTableContextMenu;
