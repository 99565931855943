import React, { useEffect, useState } from 'react';
import { ContactSearchResultItem } from '../../../../models/Contact';
import { NewInternalProjectMemberRequest } from '../../../../models/ProjectContacts';
import AddInternalContactDrawerContext from './AddInternalContactDrawerContext';
import { ContactId } from '../../../../models/Types';

interface AddInternalContactsDrawerContextProviderProps {
  children: React.ReactNode;
}

export const AddInternalContactsDrawerContextProvider: React.FC<
  AddInternalContactsDrawerContextProviderProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { children } = props;

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const defaultConfigValues = [];

  const [selectedContacts, setSelectedContacts] = useState<
    ContactSearchResultItem[]
  >([]);
  const [currentConfigValues, setCurrentConfigValues] =
    useState<NewInternalProjectMemberRequest[]>(defaultConfigValues);

  const [contactsToConfigure, setContactsToConfigure] = useState<ContactId[]>(
    []
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (selectedContacts.length > 0) {
      setContactsToConfigure([selectedContacts[0]?.data?.contact?.contactId]);
    }
  }, [selectedContacts]);

  //#endregion

  return (
    <AddInternalContactDrawerContext.Provider
      value={{
        selectedContacts,
        setSelectedContacts,
        contactsToConfigure,
        setContactsToConfigure,
        configValues: currentConfigValues,
        setConfigValues: setCurrentConfigValues,
      }}
    >
      {children}
    </AddInternalContactDrawerContext.Provider>
  );
};

export default AddInternalContactsDrawerContextProvider;
