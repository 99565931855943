import { Button, Tabs } from '@prio365/prio365-react-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Flex from '../../../../components/Flex';
import {
  ContactImportProcess,
  ExternalCompanyImport,
  ExternalOfficeImport,
} from '../../../../models/ContactImportProcess';
import { makePrioStyles } from '../../../../theme/utils';
import {
  apiFetchContactImportProcess,
  apiStartContactImportProcess,
  apiValidateContactImportProcess,
} from '../../api';
import { ExternalCompanyTab } from './tabs/ExternalCompanyTab';
import { ExternalContactTab } from './tabs/ExternalContactTab';

const useStyles = makePrioStyles((theme) => ({
  root: { height: '100%' },
  switchesContainer: {
    marginBottom: theme.spacing.regular,
  },
  buttonContainer: {
    marginBottom: '20px',
  },
}));

export const ContactImportEdit: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState('company');
  const { contactImportProcessId } = useParams<{
    contactImportProcessId: string;
  }>();
  const [contactImportProcess, setContactImportProcess] =
    useState<ContactImportProcess | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Switch states for each validation check
  /*
  const [validationChecks, setValidationChecks] = useState({
    checkFullName: true,
    checkShortName: true,
    checkWebsite: true,
    checkVatNumber: true,
    checkLegalForm: true,
    checkStreet: true,
    checkZip: true,
    checkPhone: true,
    checkEmail: true,
    checkCountryCode: true,
  });
  */

  useEffect(() => {
    const fetchContactImportProcess = async () => {
      try {
        const { data } = await apiFetchContactImportProcess(
          contactImportProcessId!
        );
        if (data) {
          setContactImportProcess(data);
        }
      } catch (error) {
        console.error('Error fetching contact import process:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchContactImportProcess();
  }, [contactImportProcessId]);

  //const renew validation
  const handleRenewValidation = async () => {
    setIsLoading(true);
    try {
      await apiValidateContactImportProcess(contactImportProcessId);
    } catch (error) {
      console.error('Error validating contact import process:', error);
    } finally {
      setIsLoading(false);
    }

    try {
      const { data } = await apiFetchContactImportProcess(
        contactImportProcessId!
      );
      if (data) {
        setContactImportProcess(data);
      }
    } catch (error) {
      console.error('Error fetching contact import process:', error);
    } finally {
      setIsLoading(false);
    }
  };

  //handleStartImportService
  const handleStartImportService = async () => {
    setIsLoading(true);
    try {
      await apiStartContactImportProcess(contactImportProcessId);
    } catch (error) {
      console.error('Error starting contact import process:', error);
    } finally {
      setIsLoading(false);
    }
    try {
      const { data } = await apiFetchContactImportProcess(
        contactImportProcessId!
      );
      if (data) {
        setContactImportProcess(data);
      }
    } catch (error) {
      console.error('Error fetching contact import process:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateContactImportProcess = (
    updatedCompany: ExternalCompanyImport,
    updatedOffice: ExternalOfficeImport
  ) => {
    setContactImportProcess((prevProcess) => {
      if (!prevProcess) return null;

      return {
        ...prevProcess,
        externalCompanyImports: prevProcess.externalCompanyImports.map(
          (company) =>
            company.externalCompanyImportId ===
            updatedCompany.externalCompanyImportId
              ? updatedCompany
              : company
        ),
        externalOfficeImports: prevProcess.externalOfficeImports.map(
          (office) =>
            office.externalCompanyImportId ===
            updatedOffice.externalCompanyImportId
              ? updatedOffice
              : office
        ),
      };
    });
  };
  /*
  const validateCompanyData = (
    externalCompanyImports: ExternalCompanyImport[],
    externalOfficeImports: ExternalOfficeImport[]
  ): {
    corruptedCompanies: ExternalCompanyImport[];
    corruptedOffices: ExternalOfficeImport[];
  } => {
    const corruptedCompanies: ExternalCompanyImport[] = [];
    const corruptedOffices: ExternalOfficeImport[] = [];

    const validationOptions = { strictMode: false };

    externalCompanyImports.forEach((company) => {
      const companyErrors = validateExternalCompanyImport(
        company,
        validationOptions,
        validationChecks
      );
      if (companyErrors.length > 0) {
        corruptedCompanies.push(company);
      }
    });

    externalOfficeImports.forEach((office) => {
      const officeErrors = validateExternalOfficeImport(
        office,
        validationOptions,
        validationChecks
      );
      if (officeErrors.length > 0) {
        corruptedOffices.push(office);
      }
    });

    return { corruptedCompanies, corruptedOffices };
  };

  const validateContactData = (
    externalContactImports: ExternalContactImport[]
  ) => {
    const corruptedContacts: ExternalContactImport[] = [];
    externalContactImports.forEach((contact) => {
      // TODO: Validate contact data
    });

    return corruptedContacts;
  };
*/
  const renderTabs = () => {
    /*
    const { corruptedCompanies, corruptedOffices } = validateCompanyData(
      contactImportProcess?.externalCompanyImports ?? [],
      contactImportProcess?.externalOfficeImports ?? []
    );

    const corruptedContacts = validateContactData(
      contactImportProcess?.externalContactImports ?? []
    );*/

    return [
      {
        label: t('settings:contactImporter.tabs.companies'),
        key: 'company',
        content: (
          <ExternalCompanyTab
            contactImportProcess={contactImportProcess}
            onSave={handleUpdateContactImportProcess}
          />
        ),
      },
      {
        label: t('settings:contactImporter.tabs.contacts'),
        key: 'contacts',
        content: (
          <ExternalContactTab contactImportProcess={contactImportProcess} />
        ),
      },
      /*
      {
        label: t('settings:contactImporter.tabs.corruptedCompanies'),
        key: 'corruptedCompanies',
        content: (
          <CorruptedCompanyDataTab
            corruptedCompanies={corruptedCompanies}
            corruptedOffices={corruptedOffices}
            allCompanies={contactImportProcess?.externalCompanyImports ?? []}
          />
        ),
      },
      {
        label: t('settings:contactImporter.tabs.corruptedContacts'),
        key: 'corruptedContacts',
        content: (
          <CorruptedContactsDataTab corruptedContacts={corruptedContacts} />
        ),
      },
      */
    ];
  };

  if (isLoading) {
    return <p>{t('settings:contactImportProcess.loading')}</p>;
  }

  return (
    <div className={classes.root}>
      <Flex.Row
        alignItems="center"
        childrenGap={10}
        className={classes.buttonContainer}
      >
        <Button
          type="primary"
          disabled={contactImportProcess.state !== 2 || isLoading}
          onClick={handleStartImportService}
        >
          {t('settings:contactImportProcess.startImportService')}
        </Button>
        <Button
          disabled={contactImportProcess.state !== 2 || isLoading}
          type="primary"
          onClick={handleRenewValidation}
        >
          {t('settings:contactImportProcess.renewValidation')}
        </Button>
      </Flex.Row>
      <Tabs
        contentClassName="fullHeightContactImporter"
        defaultTabKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        items={renderTabs()}
      />
    </div>
  );
};
