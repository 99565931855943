import React, { useState } from 'react';
import { Drawer, Button, Select, Input } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../../theme/utils';
import { Tooltip, Upload } from 'antd';
import { apiUploadCsvWithMapping } from '../../api';
import { CsvMappingDto } from '../../../../models/ContactImportProcess';

interface NewImportDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const useStyles = makePrioStyles((theme) => ({
  drawer: {
    zIndex: 999,
    padding: theme.spacing.regular,
  },
  uploadButton: {
    marginBottom: theme.spacing.large,
  },
  selectContainer: {
    marginTop: theme.spacing.regular,
  },
  actionButton: {
    marginLeft: theme.spacing.regular,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.large,
  },
  inputContainer: {
    marginBottom: theme.spacing.large,
  },
}));

const NewImportDrawer: React.FC<NewImportDrawerProps> = ({
  visible,
  onClose,
}) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({});
  const [nameSimilarityThreshold, setNameSimilarityThreshold] =
    useState<number>(0.75);
  const [maxLevenshteinDistance, setMaxLevenshteinDistance] =
    useState<number>(3);

  // can start import boolean to disable the button
  const [canStartImport, setCanStartImport] = useState(false);

  //isSaving for disable button
  const [isSaving, setIsSaving] = useState(false);

  const [file, setFile] = useState<File | null>(null);
  const classes = useStyles();

  const handleFileUpload = (file: any) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      // check in the firstline if there is , or more ; the use this as separator
      const text = e.target?.result as string;
      const separator = text.includes(',') ? ',' : ';';
      const rows = text.split('\n').map((row) => row.split(separator));
      setColumns(rows[0] || []); // Set only the first row as column headers
    };
    reader.readAsText(file);
    setFile(file);
    setCanStartImport(true);
    return false; // Prevent auto-upload
  };

  const csvMappingOptions = [
    'CompanyShortName',
    'CompanyFullName',
    'CompanyFullName2',
    'CompanyWebsite',
    'CompanyNotes',
    'CompanyCommercialRegisterNumber',
    'CompanyCommercialRegisterCity',
    'CompanyLegalForm',
    'CompanyIsArchived',
    'CompanyIsClient',
    'CompanyIsServiceProvider',
    'CompanyClassificationName',
    'BankCreditInstitutionName',
    'BankBIC',
    'BankIBAN',
    'OfficeName',
    'OfficePhone',
    'OfficeEMail',
    'OfficeStreet',
    'OfficeZip',
    'OfficeCity',
    'OfficeIsMainOffice',
    'OfficeFederalStateCode',
    'OfficeCountryCode',
    'OfficeIsArchived',
    'ContactGender',
    'ContactSalutation',
    'ContactFirstName',
    'ContactLastName',
    'ContactDepartment',
    'ContactPosition',
    'ContactEMail',
    'ContactEMail2',
    'ContactEMail3',
    'ContactPhone',
    'ContactPhone2',
    'ContactPhone3',
    'ContactCellphone',
    'ContactCellphone2',
    'ContactCellphone3',
    'ContactStreet',
    'ContactZip',
    'ContactCity',
    'ContactWebsite',
    'ContactFax',
    'ContactNotes',
    'ContactTitle',
    'ContactSignaturePrefix',
    'ContactIsArchived',
    'ContactContactClassificationName',
  ].map((key) => ({
    value: key,
    label: key,
  }));

  const handleSelectChange = (column: string, value: string) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [value]: column,
    }));
    console.log(value, column);
  };

  const handleUpload = async () => {
    if (!file || Object.keys(selectedOptions).length === 0) {
      // message.error(t('settings:companyImport.errorNoFileOrMapping'));
      return;
    }
    const mappingData: CsvMappingDto = {
      // Populate mapping data from selectedOptions
      CompanyShortName: selectedOptions['CompanyShortName'] || '',
      CompanyFullName: selectedOptions['CompanyFullName'] || '',
      CompanyFullName2: selectedOptions['CompanyFullName2'] || '',
      CompanyWebsite: selectedOptions['CompanyWebsite'] || '',
      CompanyNotes: selectedOptions['CompanyNotes'] || '',
      CompanyCommercialRegisterNumber:
        selectedOptions['CompanyCommercialRegisterNumber'] || '',
      CompanyCommercialRegisterCity:
        selectedOptions['CompanyCommercialRegisterCity'] || '',
      CompanyLegalForm: selectedOptions['CompanyLegalForm'] || '',
      CompanyIsArchived: selectedOptions['CompanyIsArchived'] || '',
      CompanyIsClient: selectedOptions['CompanyIsClient'] || '',
      CompanyIsServiceProvider:
        selectedOptions['CompanyIsServiceProvider'] || '',
      CompanyClassificationName:
        selectedOptions['CompanyClassificationName'] || '',
      BankCreditInstitutionName:
        selectedOptions['BankCreditInstitutionName'] || '',
      BankBIC: selectedOptions['BankBIC'] || '',
      BankIBAN: selectedOptions['BankIBAN'] || '',
      OfficeName: selectedOptions['OfficeName'] || '',
      OfficePhone: selectedOptions['OfficePhone'] || '',
      OfficeEMail: selectedOptions['OfficeEMail'] || '',
      OfficeStreet: selectedOptions['OfficeStreet'] || '',
      OfficeZip: selectedOptions['OfficeZip'] || '',
      OfficeCity: selectedOptions['OfficeCity'] || '',
      OfficeIsMainOffice: selectedOptions['OfficeIsMainOffice'] || '',
      OfficeFederalStateCode: selectedOptions['OfficeFederalStateCode'] || '',
      OfficeCountryCode: selectedOptions['OfficeCountryCode'] || '',
      OfficeIsArchived: selectedOptions['OfficeIsArchived'] || '',
      ContactGender: selectedOptions['ContactGender'] || '',
      ContactSalutation: selectedOptions['ContactSalutation'] || '',
      ContactFirstName: selectedOptions['ContactFirstName'] || '',
      ContactLastName: selectedOptions['ContactLastName'] || '',
      ContactDepartment: selectedOptions['ContactDepartment'] || '',
      ContactPosition: selectedOptions['ContactPosition'] || '',
      ContactEMail: selectedOptions['ContactEMail'] || '',
      ContactEMail2: selectedOptions['ContactEMail2'] || '',
      ContactEMail3: selectedOptions['ContactEMail3'] || '',
      ContactPhone: selectedOptions['ContactPhone'] || '',
      ContactPhone2: selectedOptions['ContactPhone2'] || '',
      ContactPhone3: selectedOptions['ContactPhone3'] || '',
      ContactCellphone: selectedOptions['ContactCellphone'] || '',
      ContactCellphone2: selectedOptions['ContactCellphone2'] || '',
      ContactCellphone3: selectedOptions['ContactCellphone3'] || '',
      ContactStreet: selectedOptions['ContactStreet'] || '',
      ContactZip: selectedOptions['ContactZip'] || '',
      ContactCity: selectedOptions['ContactCity'] || '',
      ContactWebsite: selectedOptions['ContactWebsite'] || '',
      ContactFax: selectedOptions['ContactFax'] || '',
      ContactNotes: selectedOptions['ContactNotes'] || '',
      ContactTitle: selectedOptions['ContactTitle'] || '',
      ContactSignaturePrefix: selectedOptions['ContactSignaturePrefix'] || '',
      ContactIsArchived: selectedOptions['ContactIsArchived'] || '',
      ContactContactClassificationName:
        selectedOptions['ContactContactClassificationName'] || '',
    };

    try {
      setIsSaving(true);
      const { result } = await apiUploadCsvWithMapping(file, mappingData);
      if (result.ok) {
        // message.success(t('settings:companyImport.uploadSuccess'));
        onClose();
      } else {
        // message.error(t('settings:companyImport.uploadError'));
      }
    } catch (error) {
      console.error('Upload error:', error);
      // message.error(t('settings:companyImport.uploadError'));
    }
    setIsSaving(false);
  };

  const getFilteredOptions = (column: string) => {
    const selectedValues = Object.values(selectedOptions);
    return csvMappingOptions.filter(
      (option) =>
        !selectedValues.includes(option.value) ||
        selectedOptions[column] === option.value
    );
  };

  return (
    <Drawer
      title={t('settings:companyImport.newDrawerTitle')}
      visible={visible}
      onClose={onClose}
      width={'large'}
      className={classes.drawer}
    >
      <Upload
        beforeUpload={handleFileUpload}
        accept=".csv"
        showUploadList={false}
      >
        <Button
          iconProp={['fal', 'file-upload']}
          className={classes.uploadButton}
          disabled={isSaving}
        >
          {t('settings:companyImport.uploadCSV')}
        </Button>
      </Upload>

      {columns.length > 0 && (
        <div className={classes.selectContainer}>
          {columns.map((column, index) => (
            <div key={index} style={{ marginBottom: '16px' }}>
              <label>{column}</label>
              <Select
                options={getFilteredOptions(column)}
                placeholder={t('settings:companyImport.selectColumns')}
                style={{ width: '100%' }}
                onChange={(value) => handleSelectChange(column, value)}
                value={selectedOptions[column]}
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </div>
          ))}
        </div>
      )}

      <div className={classes.inputContainer}>
        <Tooltip
          title={t('settings:companyImport.nameSimilarityThresholdExplanation')}
        >
          <label>{t('settings:companyImport.nameSimilarityThreshold')}</label>
          <Input
            type="number"
            value={nameSimilarityThreshold}
            onChange={(e) => setNameSimilarityThreshold(Number(e))}
            placeholder={t(
              'settings:companyImport.nameSimilarityThresholdPlaceholder'
            )}
            style={{ width: '100%' }}
          />
        </Tooltip>
      </div>

      <div className={classes.inputContainer}>
        <Tooltip
          title={t('settings:companyImport.maxLevenshteinDistanceExplanation')}
        >
          <label>{t('settings:companyImport.maxLevenshteinDistance')}</label>
          <Input
            type="number"
            value={maxLevenshteinDistance}
            onChange={(e) => setMaxLevenshteinDistance(Number(e))}
            placeholder={t(
              'settings:companyImport.maxLevenshteinDistancePlaceholder'
            )}
            style={{ width: '100%' }}
          />
        </Tooltip>
      </div>
      <div className={classes.buttonContainer}>
        <Button disabled={isSaving} onClick={onClose}>
          {t('common:actions.cancel')}
        </Button>
        <Button
          disabled={isSaving === true || canStartImport === false}
          type="primary"
          onClick={handleUpload}
          className={classes.actionButton}
        >
          {t('settings:companyImport.upload')}
        </Button>
      </div>
    </Drawer>
  );
};

export default NewImportDrawer;
