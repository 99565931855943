import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { ProjectExtensionType } from '../../../../models/ProjectExtension';
import { Toggle } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import SvgIcon from '../../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../../util/icon';

const useStyles = makePrioStyles((theme) => ({
  extensionItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.large,
    alignItems: 'center',
    width: '100%',
  },
  dividerExtensionItem: {
    borderTop: `1px solid ${theme.colors.application.typography.muted}40`,
    paddingTop: theme.spacing.regular,
  },
}));

interface AddInternalContactDrawerProjectExtensionItemProps {
  index: number;
  type: ProjectExtensionType;
  checked: boolean;
  hasDefaultAccess: boolean;
  setExtension: (value: boolean) => void;
}

export const AddInternalContactDrawerProjectExtensionItem: React.FC<
  AddInternalContactDrawerProjectExtensionItemProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { index, type, checked, hasDefaultAccess, setExtension } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnChange = (value: boolean) => {
    setExtension(value);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames(classes.extensionItem, {
        [classes.dividerExtensionItem]: index > 0,
      })}
    >
      <SvgIcon
        key={index}
        width={66}
        height={66}
        path={getSvgIconPathByType(type)}
      />
      <Flex.Item flex={1}>{t(`projects:addIn.${type}`)}</Flex.Item>
      <Toggle
        checked={checked}
        disabled={hasDefaultAccess}
        onChange={(value) => handleOnChange(value)}
      ></Toggle>
    </div>
  );
};

export default AddInternalContactDrawerProjectExtensionItem;
