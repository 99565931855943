import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { PrioTheme } from '../../../../theme/types';
import ProjectPickerPrioCL from '../../../projects/components/ProjectPickerPrioCL';
import JobTitleSelect from '../JobTitleSelect';
import { Select, Toggle, createForm } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import {
  ContactId,
  HourlyRateId,
  ProjectId,
  ProjectRole,
} from '../../../../models/Types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getAllHourlyRates,
} from '../../../../apps/main/rootReducer';
import { HourlyRate } from '../../../../models/HourlyRate';
import {
  ProjectExtension,
  ProjectExtensionType,
} from '../../../../models/ProjectExtension';
import { NewInternalProjectMemberRequest } from '../../../../models/ProjectContacts';
import { t } from 'i18next';
import AddInternalContactDrawerContext from './AddInternalContactDrawerContext';
import { useTheme } from 'react-jss';
import * as Yup from 'yup';
import AddInConfiguration from './AddInternalContactDrawerAddInConfiguration';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  content: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
    flex: 1,
    '& .prio-form': {
      overflow: 'unset',
    },
    '& .prio-form-content-scroll-container': {
      overflow: 'hidden',
    },
  },
  header: {
    fontSize: theme.font.fontSize.large,
    fontWeight: theme.font.fontWeight.bold,
    color: theme.colors.application.typography.default,
    paddingTop: theme.spacing.regular,
  },
  addInsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
  },
  contactSelectLabel: {
    fontSize: theme.font.fontSize.extraSmall,
    color: theme.colors.application.typography.muted,
    paddingBottom: theme.spacing.extraSmall,
  },
}));

export type ProjectExtensionData = {
  id: string;
  type: ProjectExtensionType;
  checked: boolean;
  default: boolean;
};

type AddInternalProjectMembersFormValues = {
  contactId: ContactId;
  projectId: ProjectId;
  jobTitle: string;
  hourlyRate: HourlyRateId;
  projectRoles: ProjectRole[];
  includeSubprojects?: boolean;
};

const Form = createForm<AddInternalProjectMembersFormValues>();

const pojectRoles: ProjectRole[] = [
  'projectAdmin',
  'projectAssistance',
  'projectController',
  'projectMember',
];

const roleOptions = pojectRoles?.map((i) => ({
  label: t(`contacts:projectContactsPage.projectRoles.${i}`),
  value: i,
  disabled: i === 'projectMember' ? true : false,
}));

interface AddInternalContactDrawerUserConfigFormProps {
  className?: string;
  projectId: ProjectId;
  setDisableSaveButton: (value: boolean) => void;
  projectExtensions: ProjectExtension[];
  configureIndividually?: boolean;
  setUserState: (value: { [contactId: string]: boolean }) => void;
}

export const AddInternalContactDrawerUserConfigForm: React.FC<
  AddInternalContactDrawerUserConfigFormProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    projectId,
    setDisableSaveButton,
    projectExtensions,
    configureIndividually = false,
    setUserState = () => {},
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const {
    configValues,
    setConfigValues,
    selectedContacts,
    contactsToConfigure,
    setContactsToConfigure,
  } = useContext(AddInternalContactDrawerContext);
  const hourlyRates = useSelector<RootReducerState, HourlyRate[]>((state) =>
    getAllHourlyRates(state, projectId)
  );

  const hourlyRateOptions = hourlyRates?.map((i) => ({
    label: i.name,
    value: i.hourlyRateId,
  }));

  const numberOfDefaultAccesses = projectExtensions?.filter(
    (i) => i?.hasDefaultAccess
  )?.length;

  const [hasSharedMailboxAccess, setHasSharedMailboxAccess] =
    useState(undefined);
  const [hasSharepointAccess, setHasSharepointAccess] = useState(undefined);
  const [hasPlannerAccess, setHasPlannerAccess] = useState(undefined);
  const [hasJiraAccess, setHasJiraAccess] = useState(undefined);

  const initialValues: AddInternalProjectMembersFormValues = useMemo(() => {
    return {
      contactId: undefined,
      projectId: projectId,
      jobTitle: '',
      hourlyRate: undefined,
      projectRoles: ['projectMember'],
      includeSubprojects: false,
    };
  }, [projectId]);

  const [defaultFormValues, setDefaultFormValues] =
    useState<AddInternalProjectMembersFormValues>(initialValues);

  const [currentFormValues, setCurrentFormValues] =
    useState<AddInternalProjectMembersFormValues>(initialValues);

  const projectExtensionSetters = {
    sharedMailbox: setHasSharedMailboxAccess,
    sharepoint: setHasSharepointAccess,
    planner: setHasPlannerAccess,
    jira: setHasJiraAccess,
  };

  const [projectExtensionData, setProjectExtensionData] = useState<
    ProjectExtensionData[]
  >([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const getDefaultValue = useCallback(
    (type: ProjectExtensionType) => {
      const defaultAccessValueFromProjectExtension = projectExtensions?.find(
        (i) => i?.projectExtensionType === type
      )?.hasDefaultAccess;
      return defaultAccessValueFromProjectExtension ?? false;
    },
    [projectExtensions]
  );

  const onContactSelectionChange = useCallback(
    (value: ContactId) => {
      setContactsToConfigure([value]);

      const contact = configValues.find((i) => i.contactId === value);
      const hasSharepointAccessValue = contact?.extensionAccesses.find(
        (ex) =>
          ex.projectExtensionId ===
          projectExtensions.find((i) => i.projectExtensionType === 'sharepoint')
            ?.projectExtensionId
      )?.shouldHaveAccess;
      const hasSharedMailboxAccessValue = contact?.extensionAccesses.find(
        (ex) =>
          ex.projectExtensionId ===
          projectExtensions.find(
            (i) => i.projectExtensionType === 'sharedMailbox'
          )?.projectExtensionId
      )?.shouldHaveAccess;
      const hasPlannerAccessValue = contact?.extensionAccesses.find(
        (ex) =>
          ex.projectExtensionId ===
          projectExtensions.find((i) => i.projectExtensionType === 'planner')
            ?.projectExtensionId
      )?.shouldHaveAccess;
      const hasJiraAccessValue = contact?.extensionAccesses.find(
        (ex) =>
          ex.projectExtensionId ===
          projectExtensions.find((i) => i.projectExtensionType === 'jira')
            ?.projectExtensionId
      )?.shouldHaveAccess;

      setCurrentFormValues((prev) => ({
        ...prev,
        contactId: contact.contactId,
        jobTitle: contact.jobTitle,
        hourlyRate: contact.hourlyRateId,
        projectRoles: contact.projectRoles,
        includeSubprojects: contact.isAddToAllSubprojects,
      }));

      setDefaultFormValues({
        contactId: contact.contactId,
        projectId: projectId,
        jobTitle: contact.jobTitle,
        hourlyRate: contact.hourlyRateId,
        projectRoles: contact.projectRoles,
        includeSubprojects: contact.isAddToAllSubprojects,
      });

      setHasSharepointAccess(hasSharepointAccessValue);
      setHasSharedMailboxAccess(hasSharedMailboxAccessValue);
      setHasPlannerAccess(hasPlannerAccessValue);
      setHasJiraAccess(hasJiraAccessValue);
    },
    [configValues, projectExtensions, projectId, setContactsToConfigure]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setHasSharedMailboxAccess(getDefaultValue('sharedMailbox'));
    setHasSharepointAccess(getDefaultValue('sharepoint'));
    setHasPlannerAccess(getDefaultValue('planner'));
    setHasJiraAccess(getDefaultValue('jira'));
  }, [getDefaultValue]);

  useEffect(() => {
    setProjectExtensionData(
      projectExtensions.map((i) => {
        const getCheckedValue = (type: ProjectExtensionType) => {
          if (type === 'sharedMailbox') {
            return hasSharedMailboxAccess;
          }
          if (type === 'sharepoint') {
            return hasSharepointAccess;
          }
          if (type === 'planner') {
            return hasPlannerAccess;
          }
          if (type === 'jira') {
            return hasJiraAccess;
          }
          return false;
        };
        return {
          id: i.projectExtensionId,
          type: i.projectExtensionType,
          checked:
            getCheckedValue(i.projectExtensionType) ?? i.hasDefaultAccess,
          default: i.hasDefaultAccess,
        };
      })
    );
  }, [
    projectExtensions,
    hasSharedMailboxAccess,
    hasSharepointAccess,
    hasPlannerAccess,
    hasJiraAccess,
  ]);

  useEffect(() => {
    setCurrentFormValues((prev) => ({
      ...prev,
      hasJiraAccess: hasJiraAccess,
      hasPlannerAccess: hasPlannerAccess,
      hasSharepointAccess: hasSharepointAccess,
      hasSharedMailboxAccess: hasSharedMailboxAccess,
    }));
  }, [
    hasJiraAccess,
    hasPlannerAccess,
    hasSharepointAccess,
    hasSharedMailboxAccess,
  ]);

  useEffect(() => {
    const allJobTitleSet = configValues.find((i) => !!!i.jobTitle)
      ? false
      : true;
    const allHourlyRateSet = configValues.find((i) => !!!i.hourlyRateId)
      ? false
      : true;
    const projectIdSet = currentFormValues.projectId ? true : false;

    const allRequiredFieldsFilled =
      allJobTitleSet && allHourlyRateSet && projectIdSet;
    setDisableSaveButton(!allRequiredFieldsFilled);

    if (setUserState) {
      setUserState(
        configValues.reduce(
          (acc, i) => ({
            ...acc,
            [i.contactId]: !!!i?.jobTitle
              ? false
              : true && !!!i?.hourlyRateId
              ? false
              : true && !!!currentFormValues?.projectId
              ? false
              : true,
          }),
          {}
        )
      );
    }
  }, [currentFormValues, setDisableSaveButton, configValues, setUserState]);

  useEffect(() => {
    let newInternalContacts: NewInternalProjectMemberRequest[] = [];
    if (!configureIndividually) {
      newInternalContacts = selectedContacts.map((i) => ({
        contactId: i.data.contact.contactId,
        jobTitle: currentFormValues.jobTitle,
        hourlyRateId: currentFormValues.hourlyRate,
        projectRoles: currentFormValues.projectRoles,
        isAddToAllSubprojects: currentFormValues.includeSubprojects,
        extensionAccesses: [
          {
            projectExtensionId: projectExtensions.find(
              (i) => i.projectExtensionType === 'sharedMailbox'
            )?.projectExtensionId,
            contactId: i.data.contact.contactId,
            shouldHaveAccess: hasSharedMailboxAccess,
            options: [],
          },
          {
            projectExtensionId: projectExtensions.find(
              (i) => i.projectExtensionType === 'sharepoint'
            )?.projectExtensionId,
            contactId: i.data.contact.contactId,
            shouldHaveAccess: hasSharepointAccess,
            options: [],
          },
          {
            projectExtensionId: projectExtensions.find(
              (i) => i.projectExtensionType === 'planner'
            )?.projectExtensionId,
            contactId: i.data.contact.contactId,
            shouldHaveAccess: hasPlannerAccess,
            options: [],
          },
          {
            projectExtensionId: projectExtensions.find(
              (i) => i.projectExtensionType === 'jira'
            )?.projectExtensionId,
            contactId: i.data.contact.contactId,
            shouldHaveAccess: hasJiraAccess,
            options: [],
          },
        ].filter((extension) => extension.projectExtensionId),
        notes: '',
        isArchived: false,
        isInternal: true,
        subprojectIds: [],
      }));
      setConfigValues(newInternalContacts);
    } else {
      newInternalContacts = [
        {
          contactId:
            contactsToConfigure[0] ??
            selectedContacts[0]?.data.contact.contactId,
          jobTitle: currentFormValues.jobTitle,
          hourlyRateId: currentFormValues.hourlyRate,
          projectRoles: currentFormValues.projectRoles,
          isAddToAllSubprojects: currentFormValues.includeSubprojects,
          extensionAccesses: [
            {
              projectExtensionId: projectExtensions.find(
                (i) => i.projectExtensionType === 'sharedMailbox'
              )?.projectExtensionId,
              contactId: selectedContacts[0]?.data.contact.contactId,
              shouldHaveAccess: hasSharedMailboxAccess,
              options: [],
            },
            {
              projectExtensionId: projectExtensions.find(
                (i) => i.projectExtensionType === 'sharepoint'
              )?.projectExtensionId,
              contactId: selectedContacts[0]?.data.contact.contactId,
              shouldHaveAccess: hasSharepointAccess,
              options: [],
            },
            {
              projectExtensionId: projectExtensions.find(
                (i) => i.projectExtensionType === 'planner'
              )?.projectExtensionId,
              contactId: selectedContacts[0]?.data.contact.contactId,
              shouldHaveAccess: hasPlannerAccess,
              options: [],
            },
            {
              projectExtensionId: projectExtensions.find(
                (i) => i.projectExtensionType === 'jira'
              )?.projectExtensionId,
              contactId: selectedContacts[0]?.data.contact.contactId,
              shouldHaveAccess: hasJiraAccess,
              options: [],
            },
          ].filter((extension) => extension.projectExtensionId),
          notes: '',
          isArchived: false,
          isInternal: true,
          subprojectIds: [],
        },
      ];

      setConfigValues((prev) => [
        ...prev.filter((i) => i.contactId !== newInternalContacts[0].contactId),
        ...newInternalContacts,
      ]);
    }
  }, [
    projectExtensions,
    currentFormValues,
    selectedContacts,
    setConfigValues,
    configureIndividually,
    contactsToConfigure,
    hasJiraAccess,
    hasPlannerAccess,
    hasSharepointAccess,
    hasSharedMailboxAccess,
  ]);
  //#endregion

  return (
    <div className={classNames(classes.content, className)}>
      {configureIndividually && (
        <div style={{ padding: `${theme.spacing.small}px 0px` }}>
          <div className={classes.contactSelectLabel}>
            {t(
              `contacts:addInternalContactDrawer.userConfigStep.form.label.selectContact`
            )}
          </div>
          <Select
            options={selectedContacts.map((i) => ({
              label: i.calculated.name,
              value: i.data.contact.contactId,
            }))}
            defaultValue={selectedContacts[0]?.data.contact.contactId}
            showSearch={false}
            onChange={onContactSelectionChange}
          ></Select>
        </div>
      )}
      <div className={classes.header}>
        {t(
          `contacts:addInternalContactDrawer.userConfigStep.form.title.general`
        )}
      </div>
      <Form
        initialValues={defaultFormValues}
        enableReinitialize
        onChange={(_, changedValue) => {
          setCurrentFormValues({
            ...currentFormValues,
            ...changedValue,
          });
        }}
      >
        <Form.Item
          name="projectId"
          label={t(
            `contacts:addInternalContactDrawer.userConfigStep.form.label.project`
          )}
          disabled={!!projectId}
          validationSchema={Yup.string().required('Required')}
        >
          <ProjectPickerPrioCL
            size={'default'}
            disabled={!!projectId}
            value={currentFormValues.projectId}
          />
        </Form.Item>
        <Form.Item
          name="jobTitle"
          label={t(
            `contacts:addInternalContactDrawer.userConfigStep.form.label.jobTitle`
          )}
          validationSchema={Yup.string().required('Required')}
        >
          <JobTitleSelect size="default" value={currentFormValues.jobTitle} />
        </Form.Item>
        <Form.Item
          name="hourlyRate"
          label={t(
            `contacts:addInternalContactDrawer.userConfigStep.form.label.hourlyRate`
          )}
          validationSchema={Yup.string().required('Required')}
        >
          <Select showSearch={false} options={hourlyRateOptions}></Select>
        </Form.Item>
        <Form.Item
          name="projectRoles"
          label={t(
            `contacts:addInternalContactDrawer.userConfigStep.form.label.projectRoles`
          )}
          validationSchema={Yup.string().required('Required')}
        >
          <Select
            showSearch={false}
            options={roleOptions}
            mode="tags"
            value={currentFormValues.projectRoles}
          ></Select>
        </Form.Item>
        <Form.Item
          name="includeSubprojects"
          label={t(
            `contacts:addInternalContactDrawer.userConfigStep.form.label.addToSubprojects`
          )}
          validationSchema={Yup.string().notRequired()}
        >
          <Toggle />
        </Form.Item>
      </Form>
      <AddInConfiguration
        projectExtensions={projectExtensionData}
        projectExtensionSetters={projectExtensionSetters}
        numberOfDefaultAccesses={numberOfDefaultAccesses}
      />
    </div>
  );
};

export default AddInternalContactDrawerUserConfigForm;
