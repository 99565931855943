import { Widget } from '../reducers';
import { PRIO } from '../../../constants';
import { ProjectId } from '../../../models/Types';

export interface WidgetAreaDisplayAction {
  type: string;
  selectedWidget?: Widget;
}

export const OPEN_WIDGET_AREA = PRIO + 'OPEN_WIDGET_AREA';

export const openWidgetArea: (
  selectedWidget?: Widget
) => WidgetAreaDisplayAction = (selectedWidget) => ({
  type: OPEN_WIDGET_AREA,
  selectedWidget,
});

export const CLOSE_WIDGET_AREA = PRIO + 'CLOSE_WIDGET_AREA';

export const closeWidgetArea: (
  selectedWidget?: Widget
) => WidgetAreaDisplayAction = (selectedWidget) => ({
  type: CLOSE_WIDGET_AREA,
  selectedWidget,
});

export const SELECT_WIDGET = PRIO + 'SELECT_WIDGET';

export const selectWidget: (
  selectedWidget?: Widget
) => WidgetAreaDisplayAction = (selectedWidget) => ({
  type: SELECT_WIDGET,
  selectedWidget,
});

export const SET_WIDGETBAR_ACTIVE_PROJECT =
  PRIO + 'SET_WIDGETBAR_ACTIVE_PROJECT';
export const setActiveProjectWidgetBar = (projectId: ProjectId) => ({
  type: SET_WIDGETBAR_ACTIVE_PROJECT,
  projectId,
});

export const SET_DOCUMENTSWIDGET_ACTIVE_PROJECT =
  PRIO + 'SET_DOCUMENTSWIDGET_ACTIVE_PROJECT';
export const setActiveProjectDocumentsWidget = (projectId: ProjectId) => ({
  type: SET_DOCUMENTSWIDGET_ACTIVE_PROJECT,
  projectId,
});

export const SET_BUDDY_ICON_VISIBILITY = PRIO + 'SET_BUDDY_ICON_VISIBILITY';
export const setBuddyIconVisibility = (isVisible: boolean) => ({
  type: SET_BUDDY_ICON_VISIBILITY,
  isVisible,
});

export const SET_NEW_TASK_CREATED = PRIO + 'SET_NEW_TASK_CREATED';
export const setNewTaskCreated = (isNewTaskCreated: boolean) => ({
  type: SET_NEW_TASK_CREATED,
  isNewTaskCreated,
});
