import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  Divider,
  Modal,
  notification,
  Select,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { DriveItem } from '../../../../models/Drive';
import {
  EmailString,
  GroupId,
  MessageId,
  ProjectId,
} from '../../../../models/Types';
import { useDrop } from 'react-dnd';
import {
  DndDriveItemDto,
  DndEmailDto,
  DND_TYPE_DRIVE_ITEM_FILE,
  DND_TYPE_EMAIL,
} from '../../../../dnd/types';
import {
  apiAddDriveItemsToAttachment,
  apiAddEmailAsEmailAttachment,
  apiAttachmentUploadUrl,
  apiDeleteAttachment,
  apiDownloadAttachment,
  apiDownloadAttachmentToBase64,
  apiFetchAttachments,
} from '../../api';
import {
  Importance,
  Message,
  MessageAttachment,
  MessageRecipient,
} from '../../../../models/Message';
import { makePrioStyles } from '../../../../theme/utils';
import classNames from 'classnames';
import Flex from '../../../../components/Flex';
import AddressBar from '../AddressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ExclamationOutlined } from '@ant-design/icons';
import MailEditor, { MailEditorRefProps } from './MailEditor';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { getAccessToken } from '../../../../store/authEffect';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentFolderItem,
  getMailSettings,
  getProject,
  getUserMe,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import PrioSpinner from '../../../../components/PrioSpinner';
import MessageAttachmentList from '../MessageAttachmentList';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import RenameAttachmentsDrawer from '../RenameAttachmentsDrawer';
import { Project } from '../../../../models/Project';
import { emailPattern } from '../../../../hooks/useEmailValidation';
import equals from 'deep-equal';
import { updateDraftMessage } from '../../actions/sagas';
import DebouncedInputSearch from '../../../../components/DebouncedInputField/DebouncedInputSearch';
import { updateMessage } from '../../actions/actionControllers/messageActionController';
import {
  addSendingMessageId,
  removeDraftMessage,
  removeSendingMessageId,
} from '../../actions/actionControllers/draftsActionController';
import Progress from '../../../../components/Progress/Progress';
import { v4 as uuid } from 'uuid';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import MailEditorActionButtowRow, {
  MailEditorActionButtowRowRefProps,
} from './MailEditorActionButtowRow';
/*
 * ############################################### Interface PROPS
 */
const inlineImageSrcRegex = /src="cid:.*?"/g;
const minMailCharacters = 10;

/*
 * ---------------------------------------------- Attachments
 */
export const downloadAttachment = async (
  projectId: ProjectId,
  messageId: MessageId,
  messageAttachment: MessageAttachment,
  t: TFunction
) => {
  if (!(await apiDownloadAttachment(projectId, messageId, messageAttachment))) {
    notification.open({
      message: t('common:error'),
      description: t('mail:errorMessages.messages.downloadAttachmentError'),
    });
  }
};

/*
 * ############################################### Styles
 */

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    '& .ant-select:not(.ant-select-customize-input) .ant-select-selector': {
      height: '100%',
      border: 'none',
      top: 0,
      bottom: 0,
    },
    '& .ant-select-single .ant-select-selector .ant-select-selection-item': {
      lineHeight: '1.5715',
      top: 0,
      bottom: 0,
    },
  },
  content: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '& .ant-upload': {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  button: {
    backgroundColor: theme.old.components.addressBar.backgroundColor,
    minWidth: 64,
  },
  fromSelect: {
    overflow: 'hidden',
    padding: `2px 0`,
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  selectOption: {
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fromTag: {
    borderRadius: '2px',
    border: '1px solid #f0f0f0',
    backgroundColor: '#f5f5f5',
    height: '26px',
    overflow: 'hidden',
    position: 'relative',
  },
  disabled: {
    pointerEvents: 'none',
  },
  mailEditor: {
    position: 'relative',
    flex: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  disabledMailEditor: {
    zIndex: 10,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.old.palette.backgroundPalette.alpha.content,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  addressBars: {
    padding: `${theme.old.spacing.unit(2)}px 0`,
    maxHeight: '277px',
    overflowY: 'scroll',
    '&$viewBelow': {
      maxHeight: '144px',
    },
  },
  viewBelow: {},
  ccBcc: {
    padding: `0 ${theme.old.spacing.unit(2)}px`,
  },
  flex1: {
    flex: 1,
    paddingRight: theme.old.spacing.unit(2),
  },
  flex2: {
    flex: 1,
    paddingLeft: theme.old.spacing.unit(2),
  },
  showFromRow: {
    flex: 1,
    paddingLeft: theme.old.spacing.unit(2),
    paddingRight: theme.old.spacing.unit(2),
  },
  showFromRowRow: {
    overflow: 'hidden',
  },
  divider: {
    margin: `${theme.old.spacing.unit(2)}px 0`,
  },
  subject: {
    borderTop: theme.old.borders.content,
    borderBottom: theme.old.borders.content,
  },
  subjectInput: {
    padding: theme.old.spacing.unit(2),
  },
  webLinkIcon: {
    color: 'currentColor!important',
  },
  attachments: {
    overflowY: 'scroll',
    maxHeight: 144,
    padding: `0 ${theme.old.spacing.unit(1)}px`,
  },
  dropTargetActive: {
    border: theme.old.borders.dashedHighlighted,
    borderWidth: 2,
  },
  importanceContainer: {
    height: '100%',
    width: theme.old.spacing.unit(7),
    padding: theme.old.spacing.unit(2),
  },
  highImportance: {
    height: '100%',
    width: '100%',
    color: theme.old.palette.chromaticPalette.red,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lowImportance: {
    height: '100%',
    width: '100%',
    color: theme.old.typography.colors.muted,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noMargin: {
    margin: '0px',
  },
  scrollable: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  primaryColorIcon: {
    color: `${theme.old.palette.primaryColor}!important`,
  },
  displayAllAttachmentButton: {
    '&.prio-btn': {
      backgroundColor: 'transparent',
      color: theme.old.palette.primaryColor,
      paddingLeft: 17,
      '&:hover': {
        backgroundColor: 'transparent',
        color: theme.colors.base.primary.dark,
        '& > .prio-button-icon': {
          color: theme.colors.base.primary.dark,
        },
      },
    },
  },
  toolbar: {
    '& .fr-toolbar.fr-bottom': {
      border: 'none',
    },
    '& .fr-toolbar.fr-bottom .fr-newline': {
      paddingTop: 0,
    },
    '& .fr-toolbar .fr-command.fr-btn, .fr-popup .fr-command.fr-btn, .fr-modal .fr-command.fr-btn':
      {
        margin: 0,
      },
  },
  spacingLoadingbar: {
    position: 'absolute',
    width: '100%',
    top: 0,
    zIndex: 2,
  },
}));

const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/*
 * ############################################### Component
 */

interface EmailComposerProps {
  className?: string;
  message: Message;
  disabled?: boolean;
  showSubject?: boolean;
  projectId: ProjectId;
  saveFromOuter?: boolean;
  openInNewWindow?: boolean;
  onSend?: (messageId?: MessageId) => void;
  onSendAfter?: (success?: boolean, messageId?: MessageId) => void;
  onCancel?: (messageId: MessageId) => Promise<void>;
  onBack?: () => void;
  toolbarIndex?: string;
  autofocus?: boolean;
}

export const EmailComposer: React.FC<EmailComposerProps> = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    message,
    projectId,
    disabled,
    openInNewWindow,
    onBack,
    showSubject,
    onSend,
    onSendAfter,
    saveFromOuter,
    onCancel,
    toolbarIndex,
    autofocus = true,
  } = props;

  const dispatch = useDispatch();
  /*
   * ---------------------------------------------- Drag And Drop
   */
  const [attachmentUploading, setAttachmentUploading] =
    useState<boolean>(false);

  const [droppedItems, dispatchDroppedItems] = useReducer<
    React.Reducer<{ [id: string]: boolean }, { id: string; value: boolean }>
  >((state, action) => {
    const { id, value } = action;
    return {
      ...state,
      [id]: value,
    };
  }, {});

  const [{ canDrop, isOver }, dropRef] = useDrop<any, any, any>({
    accept: [DND_TYPE_DRIVE_ITEM_FILE, DND_TYPE_EMAIL],
    drop: (item, monitor) => {
      const type = monitor.getItemType();
      if (monitor.didDrop()) return;
      switch (type) {
        case DND_TYPE_DRIVE_ITEM_FILE:
          dropDriveItems(
            (item as DndDriveItemDto).driveItems,
            (item as DndDriveItemDto).groupId
          );
          break;

        case DND_TYPE_EMAIL:
          dropMail(item as DndEmailDto);
          break;
        default:
      }
    },
    canDrop: () => true,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const dropMail = async (dndEmailDto: DndEmailDto) => {
    if (
      dndEmailDto.selectedMessages.find(
        (message) => message.id === dndEmailDto.message.id
      )
    ) {
      const ids = dndEmailDto.selectedMessages.map(() => uuid());
      ids.forEach((id) => {
        dispatchDroppedItems({ id, value: true });
      });
      await Promise.all(
        new Array(dndEmailDto.selectedMessages.length)
          .fill(null)
          .map((_, index) =>
            apiAddEmailAsEmailAttachment(
              projectId,
              dndEmailDto.selectedMessages[index].id,
              message.id
            )
          )
      ).then(async (results) => {
        if (results.some(({ data }) => !data)) {
          notification.open({
            message: t('common:error'),
            description: t(
              'mail:errorMessages.messages.addDriveItemToAttachmentError'
            ),
          });
        }
        setIsFetchingInlineAttachments(true);
        const { data: attachments } = await apiFetchAttachments(
          projectId,
          message.id
        );
        if (attachments.length > 0) {
          setMessageAttachments(attachments);
          dispatch(
            updateMessage(projectId, message.id, { hasAttachments: true })
          );
        }
        setIsFetchingInlineAttachments(false);
      });
      ids.forEach((id) => {
        dispatchDroppedItems({ id, value: false });
      });
    } else {
      const id = uuid();
      dispatchDroppedItems({ id, value: true });
      await apiAddEmailAsEmailAttachment(
        projectId,
        dndEmailDto.message.id,
        message.id
      ).then(async ({ data }) => {
        if (data) {
          setIsFetchingInlineAttachments(true);
          const { data: attachments } = await apiFetchAttachments(
            projectId,
            message.id
          );
          setMessageAttachments(attachments);

          dispatch(
            updateMessage(projectId, message.id, { hasAttachments: true })
          );
          setIsFetchingInlineAttachments(false);
        } else {
          notification.open({
            message: t('common:error'),
            description: t(
              'mail:errorMessages.messages.addDriveItemToAttachmentError'
            ),
          });
        }
      });
      dispatchDroppedItems({ id, value: false });
    }
  };

  const dropDriveItems = async (driveItems: DriveItem[], groupId: GroupId) => {
    const id = uuid();
    dispatchDroppedItems({ id, value: true });
    const { data } = await apiAddDriveItemsToAttachment(
      groupId,
      driveItems.map((x) => x.id),
      message.id,
      projectId
    );
    if (data) {
      setIsFetchingInlineAttachments(true);
      const { data: attachments } = await apiFetchAttachments(
        projectId,
        message.id
      );
      setMessageAttachments(attachments);
      dispatch(updateMessage(projectId, message.id, { hasAttachments: true }));
      setIsFetchingInlineAttachments(false);
    } else {
      notification.open({
        message: t('common:error'),
        description: t(
          'mail:errorMessages.messages.addDriveItemToAttachmentError'
        ),
      });
    }
    dispatchDroppedItems({ id, value: false });
  };
  /*
   * ---------------------------------------------- attributes
   */
  const actionButtonsRowRef = useRef<MailEditorActionButtowRowRefProps>(null);

  const mailEditorRef = useRef<MailEditorRefProps>(null);

  const me = useSelector(getUserMe);

  const [selectedMessageAttachmentIds, setSelectedMessageAttachmentIds] =
    useState<string[]>([]);

  const [attachmentDrawerOpen, setAttachmentDrawerOpen] =
    useState<boolean>(false);

  const [attachmentsCollapsed, setAttachmentCollapsed] =
    useState<boolean>(false);

  const isMe = projectId === 'me';
  const hasInlineImages: boolean =
    !!message?.body?.content?.match(inlineImageSrcRegex);

  // selectors
  const mailSettings = useSelector(getMailSettings);

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );
  const groupId = project?.groupId;
  const driveItem = useSelector<RootReducerState, DriveItem>((state) =>
    getCurrentFolderItem(state, `root-group-${groupId}`)
  );

  const projectMail: MessageRecipient = useMemo(
    () =>
      !project || project?.projectId === 'me'
        ? null
        : {
            emailAddress: {
              name: project.name ?? project.shortName ?? '',
              address: `${project.eMailPrefix}@${project.eMailSuffix}`,
            },
          },
    [project]
  );

  // messageSetter
  const [importance, setImportance] = useState<Importance>(
    message.importance ?? 'normal'
  );
  const [from, setFrom] = useState<MessageRecipient>(
    !isMe ? message.from : null
  );
  const [to, setTo] = useState<EmailString[]>(
    message.toRecipients.map(
      (r) => r.emailAddress.address ?? r.emailAddress.name
    ) ?? []
  );
  const [cc, setCc] = useState<EmailString[]>(
    message.ccRecipients.map(
      (r) => r.emailAddress.address ?? r.emailAddress.name
    ) ?? []
  );
  const [bcc, setBcc] = useState<EmailString[]>(
    message.bccRecipients.map(
      (r) => r.emailAddress.address ?? r.emailAddress.name
    ) ?? []
  );

  const [subject, setSubject] = useState<string>(message?.subject ?? '');
  const [messageBody, setMessageBody] = React.useState<string>(
    message?.body?.content ?? ''
  );
  const [messageBodyToSave, setMessageBodyToSave] = React.useState<string>(
    message?.body?.content ?? ''
  );
  const [messageText, setMessageText] = React.useState<string>('');
  const [messageAttachments, setMessageAttachments] = useState<
    MessageAttachment[]
  >(message.attachments ?? []);

  const refMessageAttachments = useRef<MessageAttachment[]>(messageAttachments);

  const [isFetchingInlineAttachments, setIsFetchingInlineAttachments] =
    useState<boolean>(true);

  const [attachmentUploadModalIsVisible, setAttachmentUploadModalIsVisible] =
    useState<boolean>(false);

  const refIsFetchingInlineAttachments = useRef<boolean>(
    isFetchingInlineAttachments
  );
  const waitForInlineAttachmentFetch = async () => {
    for (let i = 0; i < 10 && refIsFetchingInlineAttachments.current; i++) {
      await sleep(500);
    }
  };

  const isDropTargetActive = canDrop && isOver;

  const accessToken = useRef<string>(null);
  // saved methods

  const [showFrom, setShowFrom] = React.useState<boolean>(
    projectId !== 'me' &&
      !equals(
        message?.from?.emailAddress?.address,
        projectMail?.emailAddress?.address
      )
  );

  const [showCc, setShowCc] = React.useState<boolean>(
    mailSettings.showCcBccSelection.cc || message?.ccRecipients?.length > 0
  );
  const [showBcc, setShowBcc] = React.useState<boolean>(
    mailSettings.showCcBccSelection.bcc || message?.bccRecipients?.length > 0
  );

  const loadAttachmentData = useCallback(
    async (messageAttachments: MessageAttachment[]) => {
      return new Promise<string>(async (resolve) => {
        if (message.body) {
          setIsFetchingInlineAttachments(true);
          let messsageAttachmentsToParse = messageAttachments;
          let newAttachments = messageAttachments;
          if (messageAttachments.length === 0) {
            const { data } = await apiFetchAttachments(projectId, message.id);
            messsageAttachmentsToParse = data;
          }
          let body = message.body.content;
          var tests = body.matchAll(inlineImageSrcRegex);
          let mySubString = Array.from(tests);
          for (const attachmentElement of messsageAttachmentsToParse) {
            const url = await apiDownloadAttachmentToBase64(
              projectId,
              message.id,
              attachmentElement
            );
            if (mySubString) {
              const currentId = mySubString.find((element) =>
                element[0].includes(attachmentElement.contentId)
              );

              if (currentId) {
                body = body.replace(currentId[0], `src="${url}"`);
                // check if id should be updated
                var regex = new RegExp(`<img[^><]*?src="${url}".*?>`);
                if (regex) {
                  var imgWithId = regex.exec(body);
                  const testId = /id=".*?"/;
                  if (imgWithId?.length > 0) {
                    var id = imgWithId[0].match(testId);
                    if (id?.length > 0) {
                      body = body.replace(
                        id[0],
                        `id="${attachmentElement.contentId}"`
                      );
                    } else {
                      // add id
                      body = body.replace(
                        `src="${url}"`,
                        `id="${attachmentElement.contentId}" src="${url}"`
                      );
                    }
                  }
                }
              } else {
                // delete attachment data
                if (attachmentElement.isInline) {
                  await apiDeleteAttachment(
                    projectId,
                    message.id,
                    attachmentElement.id
                  );
                }
              }
            }
          }
          setMessageBody(body);
          setMessageAttachments(newAttachments);
          setIsFetchingInlineAttachments(false);
          resolve(body);
        }
      });
    },
    [message.body, message.id, projectId]
  );

  //-------------- Debounce --------------
  const cannotSend = useMemo(
    () =>
      to.length + cc.length + bcc.length === 0 ||
      to.filter((mail) => !mail?.match(emailPattern)).length > 0 ||
      cc.filter((mail) => !mail?.match(emailPattern)).length > 0 ||
      bcc.filter((mail) => !mail?.match(emailPattern)).length > 0,
    [to, cc, bcc]
  );

  const selectedMessageAttachments = (messageAttachments ?? []).filter(
    (attachment) => selectedMessageAttachmentIds.includes(attachment.id)
  );

  /*
   * ---------------------------------------------- effects
   */

  useEffect(() => {
    if (saveFromOuter) {
      saveAndOnBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveFromOuter]);

  useEffect(() => {
    if (!accessToken) {
      getAccessToken().then((token) => (accessToken.current = token));
    }
  }, [accessToken]);

  useEffect(() => {
    if (hasInlineImages || message?.hasAttachments) {
      const fetchAttachments = async () => {
        const { data } = await apiFetchAttachments(projectId, message?.id);
        if (Array.isArray(data)) {
          loadAttachmentData(data);
        }
      };
      fetchAttachments();
    } else {
      setIsFetchingInlineAttachments(false);
    }
  }, [projectId, message, t, hasInlineImages, loadAttachmentData]);

  /*
   * ---------------------------------------------- actions
   */

  const mailListForFrom = useMemo(
    () => [
      !isMe ? projectMail : { emailAddress: { name: '', address: '' } },
      ...(me
        ? [
            {
              emailAddress: {
                name: me.displayName,
                address: me.mail,
              },
            },
          ]
        : []),
    ],
    [projectMail, me, isMe]
  );

  const textUpdated = async (body: string) => {
    actionButtonsRowRef.current?.setIsUpdating(true);
    await waitForInlineAttachmentFetch();
    // var div = document.createElement('div');
    // div.innerHTML = body;
    // setMessageText(div.textContent || div.innerText || '');
    setMessageBodyToSave(body);
    dispatch(
      updateDraftMessage(
        projectId,
        message.id,
        {
          body: {
            content: body,
            contentType: 1,
          },
        },
        refMessageAttachments.current,
        setMessageAttachments,
        false,
        () => actionButtonsRowRef.current?.setIsUpdating(false)
      )
    );
  };

  const saveAndOnBack = () => {
    setTimeout(() => {
      forceUpdateDraft((success) => {
        if (success) {
          onBack();
          dispatch(removeDraftMessage(projectId, message.id));
        }
      });
    }, 500);
  };

  const toggleFrom = () => {
    if (showFrom) {
      handleFromChange({
        emailAddress: {
          address: projectMail.emailAddress.address,
        },
      });
    }
    setShowFrom(!showFrom);
  };

  const toggleCc = () => {
    if (showCc) {
      setCc([]);
    }
    setShowCc(!showCc);
  };

  const toggleBcc = () => {
    if (showBcc) {
      setBcc([]);
    }
    setShowBcc(!showBcc);
  };

  const updateSubject = (value: string) => {
    setSubject(value);
    actionButtonsRowRef.current?.setIsUpdating(true);
    dispatch(
      updateDraftMessage(
        projectId,
        message.id,
        {
          subject: value,
        },
        messageAttachments,
        setMessageAttachments,
        false,
        () => actionButtonsRowRef.current?.setIsUpdating(false)
      )
    );
  };

  const handleOnSend = () => {
    if (attachmentUploading || isFetchingInlineAttachments) {
      setAttachmentUploadModalIsVisible(true);
    } else {
      if (messageText.length <= minMailCharacters || subject === '') {
        let confirmationContent = '';
        if (messageText.length <= minMailCharacters) {
          confirmationContent = t(
            'mail:modals.sendMessageCoformation.shortMessageContent'
          );
        }
        if (subject === '') {
          confirmationContent +=
            '\n' + t('mail:modals.sendMessageCoformation.noSubjectContent');
        }

        Modal.confirm({
          title: t('mail:modals.sendMessageCoformation.title'),
          content: confirmationContent,
          okText: t('mail:modals.sendMessageCoformation.okText'),
          cancelText: t('mail:modals.sendMessageCoformation.cancelText'),
          onOk() {
            send();
          },
          onCancel() {},
        });
      } else {
        send();
      }
    }
  };

  const send = async () => {
    setAttachmentUploadModalIsVisible(false);
    dispatch(addSendingMessageId(projectId, message.id));
    actionButtonsRowRef.current?.setIsUpdating(true);
    const bodyContent = mailEditorRef.current?.getHtml();

    setTimeout(() => {
      dispatch(
        updateDraftMessage(
          projectId,
          message.id,
          {
            ...(bodyContent
              ? {
                  body: {
                    content: bodyContent,
                    contentType: 1,
                  },
                }
              : {}),
            from,
            toRecipients: to.map((m) => ({ emailAddress: { address: m } })),
            ccRecipients: cc.map((m) => ({ emailAddress: { address: m } })),
            bccRecipients: bcc.map((m) => ({ emailAddress: { address: m } })),
            subject,
            importance,
          },
          messageAttachments,
          setMessageAttachments,
          true,
          (success) => {
            actionButtonsRowRef.current?.setIsUpdating(false);
            dispatch(removeSendingMessageId(projectId, message.id));
            if (onSendAfter) {
              onSendAfter(success, message.id);
            }
          }
        )
      );
      if (onSend) {
        onSend(message.id);
      }
    }, 500);
  };

  const forceUpdateDraft = async (then?: (success: boolean) => void) => {
    actionButtonsRowRef.current?.setIsUpdating(true);
    dispatch(
      updateDraftMessage(
        projectId,
        message.id,
        {
          body: {
            content: messageBodyToSave,
            contentType: 1,
          },
          from,
          toRecipients: to.map((m) => ({ emailAddress: { address: m } })),
          ccRecipients: cc.map((m) => ({ emailAddress: { address: m } })),
          bccRecipients: bcc.map((m) => ({ emailAddress: { address: m } })),
          subject,
          importance,
        },
        messageAttachments,
        setMessageAttachments,
        false,
        (success: boolean) => {
          actionButtonsRowRef.current?.setIsUpdating(false);
          if (then) {
            then(success);
          }
        }
      )
    );
  };

  const openAttachmentDrawer = () => setAttachmentDrawerOpen(true);
  const closeAttachmentDrawer = () => setAttachmentDrawerOpen(false);

  /*
   * ---------------------------------------------- Files
   */

  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    action: apiAttachmentUploadUrl(projectId, message.id),
    headers: { Authorization: `Bearer ${accessToken.current}` },
    beforeUpload: async () => {
      const _newToken = await getAccessToken();
      accessToken.current = _newToken;
    },
    async onChange(info: UploadChangeParam<UploadFile<any>>) {
      const { status, response, name } = info.file;
      if (info.fileList.find(({ status }) => status === 'uploading')) {
        setAttachmentUploading(true);
      } else {
        setAttachmentUploading(false);
      }
      if (status === 'done') {
        if (response && Array.isArray(response)) {
          setMessageAttachments(response);
          dispatch(
            updateMessage(projectId, message.id, { hasAttachments: true })
          );
        } else {
          notification.open({
            message: t('common:error'),
            description: t('mail:errorMessages.messages.fileUploadError', {
              name,
            }),
          });
        }
      } else if (status === 'error') {
        notification.open({
          message: t('common:error'),
          description: t('mail:errorMessages.messages.fileUploadError', {
            name,
          }),
        });
      }
    },
    showUploadList: false,
  };

  const deleteAttachment = async (attachmentId: string) => {
    const { result } = await apiDeleteAttachment(
      projectId,
      message.id,
      attachmentId
    );

    if (result.status >= 200 && result.status < 300) {
      const filteredAttachments = (messageAttachments ?? []).filter(
        (attachment) => attachment.id !== attachmentId
      );
      setMessageAttachments(filteredAttachments);
      if (filteredAttachments.length === 0) {
        dispatch(
          updateMessage(projectId, message.id, { hasAttachments: false })
        );
      }
      setSelectedMessageAttachmentIds(
        selectedMessageAttachmentIds.filter((id) => attachmentId !== id)
      );
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.deleteAttachmentError'),
      });
    }
  };

  const onDownloadAttachment = (messageAttachment: MessageAttachment) =>
    downloadAttachment(projectId, message.id, messageAttachment, t);

  const onDownloadAll = () => {
    selectedMessageAttachmentIds.forEach((id) => {
      let attachment = (messageAttachments ?? []).find(
        (attachment) => attachment.id === id
      );
      if (attachment?.contentBytes?.length > 0) {
        const linkSource = `data:${attachment.contentType};base64,${attachment.contentBytes}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = attachment.name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        downloadAttachment(projectId, message.id, attachment, t);
      }
    });
  };

  const handleDisplayAllAttachments = () => {
    setAttachmentCollapsed(!attachmentsCollapsed);
  };

  const handleAddressBarChange = (
    value: EmailString[],
    type: 'to' | 'cc' | 'bcc',
    setter: (value: EmailString[]) => void
  ) => {
    setter(value);
    actionButtonsRowRef.current?.setIsUpdating(true);
    dispatch(
      updateDraftMessage(
        projectId,
        message.id,
        {
          ...(type === 'to'
            ? {
                toRecipients: value.map((m) => ({
                  emailAddress: { address: m },
                })),
              }
            : {}),
          ...(type === 'cc'
            ? {
                ccRecipients: value.map((m) => ({
                  emailAddress: { address: m },
                })),
              }
            : {}),
          ...(type === 'bcc'
            ? {
                bccRecipients: value.map((m) => ({
                  emailAddress: { address: m },
                })),
              }
            : {}),
        },
        messageAttachments,
        setMessageAttachments,
        false,
        () => actionButtonsRowRef.current?.setIsUpdating(false)
      )
    );
  };

  const handleImportanceChange = (
    value: Importance,
    setter: (value: Importance) => void
  ) => {
    setter(value);
    actionButtonsRowRef.current?.setIsUpdating(true);
    dispatch(
      updateDraftMessage(
        projectId,
        message.id,
        {
          importance: value,
        },
        messageAttachments,
        setMessageAttachments,
        false,
        () => actionButtonsRowRef.current?.setIsUpdating(false)
      )
    );
  };

  const handleFromChange = (value: MessageRecipient) => {
    setFrom(value);
    actionButtonsRowRef.current?.setIsUpdating(true);
    dispatch(
      updateDraftMessage(
        projectId,
        message.id,
        {
          from: value,
        },
        messageAttachments,
        setMessageAttachments,
        false,
        () => actionButtonsRowRef.current?.setIsUpdating(false)
      )
    );
  };

  const onAttachmentSelectionChanged = (e: CheckboxChangeEvent) => {
    const attachmentId = e.target.id;
    if (e.target.checked) {
      setSelectedMessageAttachmentIds([
        ...selectedMessageAttachmentIds,
        attachmentId,
      ]);
    } else {
      setSelectedMessageAttachmentIds(
        selectedMessageAttachmentIds.filter((id) => id !== attachmentId)
      );
    }
  };

  const onSelectionChangeAll = (values: string[]) => {
    setSelectedMessageAttachmentIds(values);
  };

  /*
   * ---------------------------------------------- Components
   */
  const showSpinner = (condition: boolean) => {
    if (condition) {
      return <PrioSpinner size="large" />;
    }
    return null;
  };

  useEffect(() => {
    refMessageAttachments.current = messageAttachments;
  }, [messageAttachments, refMessageAttachments]);

  useEffect(() => {
    refIsFetchingInlineAttachments.current = isFetchingInlineAttachments;
  }, [isFetchingInlineAttachments, refIsFetchingInlineAttachments]);

  useEffect(() => {
    if (!isMe && mailSettings?.showCcBccSelection?.from) {
      setShowFrom(true);
    }
  }, [isMe, mailSettings?.showCcBccSelection?.from]);

  useEffect(() => {
    if (mailSettings?.showCcBccSelection?.cc) {
      setShowCc(true);
    }
  }, [mailSettings?.showCcBccSelection?.cc]);

  useEffect(() => {
    if (mailSettings?.showCcBccSelection?.bcc) {
      setShowBcc(true);
    }
  }, [mailSettings?.showCcBccSelection?.bcc]);

  useEffect(() => {
    var div = document.createElement('div');
    div.innerHTML = messageBodyToSave;
    setMessageText(div.textContent || div.innerText || '');
  }, [messageBodyToSave]);

  return (
    <div
      ref={dropRef}
      className={classNames(classes.root, className, {
        [classes.dropTargetActive]: isDropTargetActive,
      })}
    >
      <Upload
        className={classNames(classes.content, {
          [classes.disabled]: disabled,
        })}
        {...uploadProps}
        openFileDialogOnClick={false}
      >
        <Flex.Column
          className={classNames(classes.addressBars, {
            [classes.viewBelow]: mailSettings?.messageViewLayout === 'bottom',
          })}
        >
          {(showFrom || (!isMe && mailSettings?.showCcBccSelection?.from)) && (
            <>
              <Flex.Row
                childrenGap={theme.old.spacing.unit(1)}
                className={classes.showFromRow}
              >
                <Flex.Row
                  flex={1}
                  childrenGap={3.2}
                  className={classes.showFromRowRow}
                >
                  <Button type="link">{t(`mail:addressBar.types.from`)}</Button>
                  <div className={classes.fromSelect}>
                    <Select
                      defaultValue={mailListForFrom[0]?.emailAddress?.address}
                      value={from?.emailAddress?.address}
                      className={classes.fromTag}
                      bordered={false}
                      onChange={(mail) => {
                        const i = mailListForFrom.findIndex(
                          (m) => m.emailAddress?.address === mail
                        );
                        handleFromChange(
                          mailListForFrom[i] && mailListForFrom[i]
                        );
                      }}
                    >
                      {mailListForFrom.map((m) => (
                        <Select.Option
                          title={m?.emailAddress?.address}
                          value={m?.emailAddress?.address}
                          className={classes.selectOption}
                        >
                          <Typography.Text style={{ color: '#000' }}>
                            {m?.emailAddress?.name}
                          </Typography.Text>
                          <Typography.Text
                            style={{ color: theme.old.typography.colors.muted }}
                          >{` <${m?.emailAddress?.address}>`}</Typography.Text>
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                </Flex.Row>
                <Flex.Row>
                  {!isMe && !mailSettings?.showCcBccSelection?.from && (
                    <Button type="link" onClick={toggleFrom}>
                      VON
                    </Button>
                  )}
                  {!mailSettings?.showCcBccSelection?.cc && (
                    <Button type="link" onClick={toggleCc}>
                      CC
                    </Button>
                  )}
                  {!mailSettings?.showCcBccSelection?.bcc && (
                    <Button type="link" onClick={toggleBcc}>
                      BCC
                    </Button>
                  )}
                  {openInNewWindow && (
                    <Button
                      type="link"
                      onClick={() => {
                        forceUpdateDraft((success) => {
                          if (success) {
                            const width = window.screen.availWidth / 2;
                            const height = window.screen.availHeight * 0.6;
                            window.open(
                              `/view/${projectId}/composer/${message.id}`,
                              '_blank',
                              `width=${width},height=${height},top=${
                                height / 4
                              }`
                            );
                            if (onBack) onBack();
                          }
                        });
                      }}
                      iconProp={['fal', 'external-link-alt']}
                    ></Button>
                  )}
                </Flex.Row>
              </Flex.Row>
              <Divider className={classes.divider} />
            </>
          )}
          <Flex.Row className={classes.flex1}>
            <AddressBar
              type="to"
              value={to}
              onChange={(value) => handleAddressBarChange(value, 'to', setTo)}
              className={classes.flex2}
              focus={autofocus && message.toRecipients.length === 0}
              tabIndex={0}
            />
            {!(
              showFrom ||
              (!isMe && mailSettings?.showCcBccSelection?.from)
            ) && (
              <Flex.Row>
                {!isMe && !mailSettings?.showCcBccSelection?.from && (
                  <Button type="link" onClick={toggleFrom}>
                    VON
                  </Button>
                )}
                {!mailSettings?.showCcBccSelection?.cc && (
                  <Button type="link" onClick={toggleCc}>
                    CC
                  </Button>
                )}
                {!mailSettings?.showCcBccSelection?.bcc && (
                  <Button type="link" onClick={toggleBcc}>
                    BCC
                  </Button>
                )}
                {openInNewWindow && (
                  <Button
                    type="link"
                    onClick={() => {
                      forceUpdateDraft((success) => {
                        if (success) {
                          const width = window.screen.availWidth / 2;
                          const height = window.screen.availHeight * 0.6;
                          window.open(
                            `/view/${projectId}/composer/${message.id}`,
                            '_blank',
                            `width=${width},height=${height},top=${height / 4}`
                          );
                          if (onBack) {
                            onBack();
                          }

                          dispatch(removeDraftMessage(projectId, message.id));
                        }
                      });
                    }}
                    iconProp={['fal', 'external-link-alt']}
                  ></Button>
                )}
              </Flex.Row>
            )}
          </Flex.Row>
          {(showCc || mailSettings?.showCcBccSelection?.cc) && (
            <>
              <Divider className={classes.divider} />
              <AddressBar
                type="cc"
                value={cc}
                onChange={(value) => handleAddressBarChange(value, 'cc', setCc)}
                className={classes.ccBcc}
                tabIndex={1}
              />
            </>
          )}
          {(showBcc || mailSettings?.showCcBccSelection?.bcc) && (
            <>
              <Divider className={classes.divider} />
              <AddressBar
                type="bcc"
                value={bcc}
                onChange={(value) =>
                  handleAddressBarChange(value, 'bcc', setBcc)
                }
                className={classes.ccBcc}
                tabIndex={2}
              />
            </>
          )}
        </Flex.Column>

        {showSubject && (
          <Flex.Row className={classes.subject}>
            <DebouncedInputSearch
              placeholder={t('mail:composer.subject.placeholder')}
              value={subject}
              onChange={updateSubject}
              bordered={false}
              className={classes.subjectInput}
            />
            {(importance === 'high' || importance === 'low') && (
              <div className={classes.importanceContainer}>
                {importance === 'high' ? (
                  <ExclamationOutlined className={classes.highImportance} />
                ) : (
                  <FontAwesomeIcon
                    className={classes.lowImportance}
                    icon={['fal', 'arrow-down']}
                  />
                )}
              </div>
            )}
          </Flex.Row>
        )}
        <div className={classes.mailEditor}>
          {(attachmentUploading ||
            Object.values(droppedItems).find((value) => !!value)) && (
            <div className={classes.spacingLoadingbar}>
              <Progress.Dots />
            </div>
          )}
          {isFetchingInlineAttachments && messageAttachments.length === 0 && (
            <div
              className={classes.disabledMailEditor}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <PrioSpinner size="large" />
            </div>
          )}
          <div className={classes.scrollable}>
            {(messageAttachments ?? []).filter((message) => !message.isInline)
              .length > 0 && (
              <>
                <MessageAttachmentList
                  messageAttachments={(messageAttachments ?? []).filter(
                    (message) => !message.isInline
                  )}
                  onDeleteAttachment={deleteAttachment}
                  onDownloadAttachment={onDownloadAttachment}
                  selectedMessageAttachmentIds={selectedMessageAttachmentIds}
                  onSelectionChange={onAttachmentSelectionChanged}
                  onSelectionChangeAll={onSelectionChangeAll}
                  receivedMessage={false}
                  onSetSelectedMessageAttachmentIds={(attachments) => {
                    setSelectedMessageAttachmentIds(attachments);
                  }}
                  downloadAll={onDownloadAll}
                  openAttachmentDrawer={openAttachmentDrawer}
                  onSetMessageAttachments={(attachments) => {
                    setMessageAttachments(attachments);
                    if (attachments.length === 0) {
                      dispatch(
                        updateMessage(projectId, message.id, {
                          hasAttachments: false,
                        })
                      );
                    }
                  }}
                  projectId={projectId}
                  messageId={message.id}
                  isMe={isMe}
                  attachmentsCollapsed={attachmentsCollapsed}
                />
                {(messageAttachments ?? []).length > 6 && (
                  <Button
                    type={'link'}
                    className={classes.displayAllAttachmentButton}
                    onClick={handleDisplayAllAttachments}
                    iconProp={['fal', attachmentsCollapsed ? 'plus' : 'minus']}
                  >
                    {attachmentsCollapsed
                      ? t('mail:messageDisplay.displayAllAttachments')
                      : t('mail:messageDisplay.closeDisplayAllAttachments')}
                  </Button>
                )}

                <Divider className={classes.noMargin}></Divider>
              </>
            )}
            <MailEditor
              ref={mailEditorRef}
              autofocus={autofocus && message.toRecipients.length > 0}
              body={messageBody}
              toolbarContainer={`prio-mail-composer-toolbar${
                toolbarIndex ? `-${toolbarIndex}` : ''
              }`}
              textUpdated={textUpdated}
            />
          </div>
          <div
            id={`prio-mail-composer-toolbar${
              toolbarIndex ? `-${toolbarIndex}` : ''
            }`}
            className={classes.toolbar}
          />
        </div>
        <MailEditorActionButtowRow
          ref={actionButtonsRowRef}
          projectId={projectId}
          messageId={message.id}
          messageAttachments={messageAttachments}
          selectedMessageAttachmentIds={selectedMessageAttachmentIds}
          cannotSend={cannotSend}
          attachmentUploading={attachmentUploading}
          uploadProps={uploadProps}
          onImportanceChange={handleImportanceChange}
          setImportance={setImportance}
          onDeleteAttachment={deleteAttachment}
          onCancel={onCancel}
          onSend={handleOnSend}
          onSaveAndBack={onBack ? saveAndOnBack : undefined}
          onAttachmentSelectionChanged={onAttachmentSelectionChanged}
          onDownloadAttachment={onDownloadAttachment}
        />
      </Upload>
      <Modal
        visible={attachmentUploadModalIsVisible}
        okText={
          attachmentUploading || isFetchingInlineAttachments
            ? t('mail:composer.sendModal.okTextIsUploadingAttachments')
            : t('mail:composer.sendModal.okText')
        }
        cancelText={t('mail:composer.sendModal.cancelText')}
        onOk={send}
        onCancel={() => setAttachmentUploadModalIsVisible(false)}
        title={t('mail:composer.sendModal.title')}
      >
        <Flex.Row childrenGap={theme.old.spacing.defaultPadding}>
          {showSpinner(attachmentUploading || isFetchingInlineAttachments)}
          <Flex.Item flex={1}>
            {attachmentUploading || isFetchingInlineAttachments
              ? t('mail:composer.sendModal.contentIsUploadingAttachments')
              : t('mail:composer.sendModal.contentText')}
          </Flex.Item>
        </Flex.Row>
      </Modal>
      {messageAttachments !== null && (
        <RenameAttachmentsDrawer
          projectId={projectId}
          messageId={message.id}
          messageAttachments={selectedMessageAttachments}
          targetFolder={driveItem}
          attachmentDrawerOpen={attachmentDrawerOpen}
          onClose={closeAttachmentDrawer}
        />
      )}
    </div>
  );
};

export default EmailComposer;
