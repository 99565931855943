import { useContext, useMemo } from 'react';
import {
  CompanyId,
  GlobalRole,
  OfficeId,
  OfficeRole,
  ProjectId,
  ProjectRole,
} from '../../../models/Types';
import { AccessItem, RoleAccessMap } from './useAccessRights';
import AccessRightsContext, {
  AccessRightsContextProps,
} from '../context/AccessRightsContext';

interface AccessRightsSearchItems {
  projectId?: ProjectId;
  officeId?: OfficeId;
  companyId?: CompanyId;
}

type AccessItemMap = {
  [Item in AccessItem]: {
    globalRoles: GlobalRole[];
    projectRoles: ProjectRole[];
    officeRoles: OfficeRole[];
  };
};

const accessItemMap: AccessItemMap = {
  writeOtherUserTimeRecord: {
    globalRoles: [
      'globalAssistance',
      'globalHR',
      'globalAdmin',
      'globalController',
    ],
    projectRoles: ['projectAssistance', 'projectAdmin', 'projectController'],
    officeRoles: ['officeAdmin', 'officeAssistance', 'officeController'],
  },
  writeOtherUserTimeRecordByProjectRole: {
    globalRoles: [],
    projectRoles: ['projectAssistance', 'projectAdmin', 'projectController'],
    officeRoles: [],
  },
  writeOtherUserTimeRecordByGlobalRole: {
    globalRoles: [
      'globalAssistance',
      'globalHR',
      'globalAdmin',
      'globalController',
    ],
    projectRoles: [],
    officeRoles: [],
  },
  writeOtherUserAbsence: {
    globalRoles: ['globalHR', 'globalAdmin'],
    projectRoles: [],
    officeRoles: ['officeHR'],
  },
  writeOtherUserAbsenceByGlobalRole: {
    globalRoles: ['globalHR', 'globalAdmin'],
    projectRoles: [],
    officeRoles: [],
  },
  writeOtherUserAbsenceByOfficeRole: {
    globalRoles: [],
    projectRoles: [],
    officeRoles: ['officeHR'],
  },
  showControllingModule: {
    globalRoles: ['globalController'],
    projectRoles: [],
    officeRoles: ['officeController'],
  },
  showOfficesInControllingModule: {
    globalRoles: [],
    projectRoles: [],
    officeRoles: ['officeController'],
  },
  showGlobalInControllingModule: {
    globalRoles: ['globalController'],
    projectRoles: [],
    officeRoles: [],
  },
  showHrModule: {
    globalRoles: ['globalHR'],
    projectRoles: [],
    officeRoles: ['officeHR'],
  },
  showOfficesInHrModule: {
    globalRoles: ['globalHR'],
    projectRoles: [],
    officeRoles: ['officeHR'],
  },
  showGlobalInHrModule: {
    globalRoles: ['globalHR'],
    projectRoles: [],
    officeRoles: [],
  },
  showProjectSettings: {
    globalRoles: ['globalAdmin'],
    projectRoles: ['projectAdmin', 'projectAssistance'],
    officeRoles: ['officeAdmin', 'officeAssistance'],
  },
  showProjectSettingsProjectPhase: {
    globalRoles: ['globalAdmin'],
    projectRoles: ['projectAdmin'],
    officeRoles: ['officeAdmin'],
  },
  showProjectAdminSettings: {
    globalRoles: ['globalAdmin'],
    projectRoles: [],
    officeRoles: [],
  },
  showContactImportSettings: {
    globalRoles: ['globalAdmin'],
    projectRoles: [],
    officeRoles: [],
  },
  showProjectControlling: {
    globalRoles: ['globalAdmin', 'globalController'],
    projectRoles: ['projectAdmin', 'projectController'],
    officeRoles: ['officeController'],
  },
  showAllProjectTimeRecords: {
    globalRoles: ['globalController', 'globalAssistance'],
    projectRoles: ['projectAdmin', 'projectAssistance', 'projectController'],
    officeRoles: ['officeController'],
  },
  showPrioSettings: {
    globalRoles: ['globalAdmin', 'globalHR', 'globalAssistance'],
    projectRoles: [],
    officeRoles: ['officeAdmin', 'officeHR', 'officeAssistance'],
  },
  showAllPrioSettings: {
    globalRoles: ['globalAdmin'],
    projectRoles: [],
    officeRoles: [],
  },
  showUserRoleSettings: {
    globalRoles: ['globalAdmin'],
    projectRoles: [],
    officeRoles: ['officeAdmin'],
  },
  showUserCompanyData: {
    globalRoles: ['globalAdmin'],
    projectRoles: [],
    officeRoles: ['officeAdmin'],
  },
  showAddInsSettings: {
    globalRoles: ['globalAdmin'],
    projectRoles: [],
    officeRoles: ['officeAdmin'],
  },
  showUserCoreData: {
    globalRoles: ['globalAdmin', 'globalHR'],
    projectRoles: [],
    officeRoles: ['officeHR'],
  },
  showUserAbsenceOverview: {
    globalRoles: ['globalHR'],
    projectRoles: [],
    officeRoles: ['officeHR'],
  },
  showTemplateSettings: {
    globalRoles: ['globalAdmin', 'globalAssistance'],
    projectRoles: [],
    officeRoles: ['officeAdmin', 'officeAssistance'],
  },
  showGlobalTemplateSettings: {
    globalRoles: ['globalAdmin', 'globalAssistance'],
    projectRoles: [],
    officeRoles: [],
  },
  showOfficeTemplateSettings: {
    globalRoles: [],
    projectRoles: [],
    officeRoles: ['officeAdmin', 'officeAssistance'],
  },
  showGlobalRoleSettings: {
    globalRoles: ['globalAdmin'],
    projectRoles: [],
    officeRoles: [],
  },
  showOfficeRoleSettings: {
    globalRoles: [],
    projectRoles: [],
    officeRoles: ['officeAdmin', 'officeHR'],
  },
  showOfficeLicenceSettings: {
    globalRoles: [],
    projectRoles: [],
    officeRoles: ['officeAdmin'],
  },
  showGlobalLicenceSettings: {
    globalRoles: ['globalAdmin'],
    projectRoles: [],
    officeRoles: [],
  },
  showUserProfile: {
    globalRoles: ['globalEmployee'],
    projectRoles: [],
    officeRoles: [],
  },
  showProjectModule: {
    globalRoles: ['globalEmployee'],
    projectRoles: [],
    officeRoles: [],
  },
  showContactsModule: {
    globalRoles: ['globalEmployee'],
    projectRoles: [],
    officeRoles: [],
  },
  showCalendar: {
    globalRoles: ['globalEmployee'],
    projectRoles: [],
    officeRoles: [],
  },
  showAllContactsInEmployeePicker: {
    globalRoles: ['globalEmployee'],
    projectRoles: [],
    officeRoles: [],
  },
  archiveContact: {
    globalRoles: ['globalAdmin', 'globalAssistance'],
    projectRoles: [],
    officeRoles: [],
  },
  archiveCompany: {
    globalRoles: ['globalAdmin', 'globalAssistance'],
    projectRoles: [],
    officeRoles: [],
  },
  exportContact: {
    globalRoles: ['globalEmployee'],
    projectRoles: [],
    officeRoles: [],
  },
  exportCompany: {
    globalRoles: ['globalEmployee'],
    projectRoles: [],
    officeRoles: [],
  },
  editInternalContact: {
    globalRoles: ['globalAdmin', 'globalAssistance', 'globalHR'],
    projectRoles: [],
    officeRoles: ['officeAdmin', 'officeHR'],
  },
  editExternalContact: {
    globalRoles: ['globalEmployee'],
    projectRoles: [],
    officeRoles: [],
  },
  editInternalCompany: {
    globalRoles: ['globalAdmin', 'globalAssistance'],
    projectRoles: [],
    officeRoles: [],
  },
  editExternalCompany: {
    globalRoles: ['globalEmployee'],
    projectRoles: [],
    officeRoles: [],
  },
};

const hasProjectAccess = (
  item: AccessItem,
  searchItems: AccessRightsSearchItems,
  accessRightsContext: AccessRightsContextProps
) => {
  const { projectId, companyId, officeId } = searchItems;
  const {
    checkProjectRolesOfProjectId,
    checkProjectRolesOfOfficeId,
    checkProjectRolesOfCompanyId,
  } = accessRightsContext;
  const { projectRoles } = accessItemMap[item];
  if (projectRoles.length === 0) {
    return false;
  }
  return (
    (projectId && checkProjectRolesOfProjectId(projectRoles, projectId)) ||
    (officeId && checkProjectRolesOfOfficeId(projectRoles, officeId)) ||
    (companyId && checkProjectRolesOfCompanyId(projectRoles, companyId))
  );
};

const hasOfficeAccess = (
  item: AccessItem,
  searchItems: AccessRightsSearchItems,
  accessRightsContext: AccessRightsContextProps
) => {
  const { projectId, companyId, officeId } = searchItems;
  const {
    checkOfficeRolesOfOfficeId,
    checkOfficeRolesOfCompanyId,
    checkOfficeRolesOfProjectId,
  } = accessRightsContext;
  const { officeRoles } = accessItemMap[item];
  if (officeRoles.length === 0) {
    return false;
  }
  return (
    (projectId && checkOfficeRolesOfProjectId(officeRoles, projectId)) ||
    (officeId && checkOfficeRolesOfOfficeId(officeRoles, officeId)) ||
    (companyId && checkOfficeRolesOfCompanyId(officeRoles, companyId))
  );
};

const hasGlobalAccess = (
  item: AccessItem,
  accessRightsContext: AccessRightsContextProps
) => {
  const { checkGlobalRoles } = accessRightsContext;
  const { globalRoles } = accessItemMap[item];
  if (globalRoles.length === 0) {
    return false;
  }
  return checkGlobalRoles(globalRoles);
};

const hasAccess = (
  item: AccessItem,
  searchItems: AccessRightsSearchItems,
  accessRightsContext: AccessRightsContextProps
) => {
  return (
    hasProjectAccess(item, searchItems, accessRightsContext) ||
    hasOfficeAccess(item, searchItems, accessRightsContext) ||
    hasGlobalAccess(item, accessRightsContext)
  );
};

const useAccessRights2: (
  items?: AccessItem[],
  searchItems?: AccessRightsSearchItems
) => {
  access: RoleAccessMap;
} & AccessRightsContextProps = (
  items = [],
  searchItems = {
    projectId: undefined,
    officeId: undefined,
    companyId: undefined,
  }
) => {
  const accessRightsContext = useContext(AccessRightsContext);

  return useMemo(() => {
    const map: RoleAccessMap = items.reduce((map, item) => {
      map[item] = hasAccess(item, searchItems, accessRightsContext);
      return map;
    }, {} as RoleAccessMap);
    return { ...accessRightsContext, access: map };
  }, [items, searchItems, accessRightsContext]);
};

export default useAccessRights2;
