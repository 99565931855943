import { VirtualTable } from '@prio365/prio365-react-library';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../../../components/Flex';
import {
  ContactImportProcess,
  ExternalContactImport,
} from '../../../../../models/ContactImportProcess';
import ContactImporterFilterBar from '../components/ContactImporterFilterBar';

interface ExternalContactTabProps {
  contactImportProcess: ContactImportProcess | null;
}

export const ExternalContactTab: React.FC<ExternalContactTabProps> = ({
  contactImportProcess,
}) => {
  const { t } = useTranslation();

  if (!contactImportProcess) {
    return <p>Loading contact details...</p>;
  }

  //#region Column definition

  const columns: Column<ExternalContactImport>[] = [
    {
      id: 'firstName',
      alignSelf: true,
      width: 300,
      title: t('settings:companyImport.table.firstName'),
      accessor: 'firstName',
      Cell: (cellProps) => cellProps.value,
      sortingFn: (a, b) => (a.firstName || '').localeCompare(b.firstName || ''),
    },
    {
      id: 'lastName',
      alignSelf: true,
      width: 300,
      title: t('settings:companyImport.table.lastName'),
      accessor: 'lastName',
      Cell: (cellProps) => cellProps.value,
      sortingFn: (a, b) => (a.lastName || '').localeCompare(b.lastName || ''),
    },
    {
      id: 'existingCompanyId',
      alignSelf: true,
      width: 250,
      title: t('settings:companyImport.table.existingCompanyId'),
      accessor: 'existingCompanyId',
      Cell: (cellProps) =>
        cellProps.value === '00000000-0000-0000-0000-000000000000'
          ? '-'
          : cellProps.value,
      sortingFn: (a, b) =>
        (a.existingCompanyId || '').localeCompare(b.existingCompanyId || ''), // Alphabetical sort
    },
    {
      id: 'description',
      alignSelf: true,
      width: 200,
      title: t('settings:companyImport.table.description'),
      accessor: 'description',
      Cell: (cellProps) => cellProps.value,
      sortingFn: (a, b) =>
        (a.description || '').localeCompare(b.description || ''), // Alphabetical sort for description
    },
    {
      id: 'action',
      alignSelf: true,
      width: 150,
      title: t('settings:companyImport.table.action'),
      accessor: 'action',
      Cell: (cellProps) =>
        t(`settings:companyImport.action.${cellProps.value}`), // Assuming `ContactImportAction` is localized
      sortingFn: (a, b) => a.action - b.action, // Sort by action
    },
    {
      id: 'menu',
      width: 100,
      alignSelf: true,
      accessor: 'externalCompanyImportId',
      title: ' ',
      Cell: ({ originalData }) => <Flex.Row justifyContent="center"></Flex.Row>,
    },
  ];
  //#endregion

  const handleSearchChange = (searchValue) => {
    console.log(
      '🚀 ~ handleSearchChange ~ searchValue:',
      searchValue.target.value
    );
  };

  return (
    <>
      <ContactImporterFilterBar
        filterActive={false}
        searchChangeHandler={handleSearchChange}
      />

      <VirtualTable<ExternalContactImport>
        columns={columns}
        data={contactImportProcess.externalContactImports}
        id="external-contact-table"
        resizable="absolute"
        rowsAreSelectable={true}
      />

      {/* <h3>Contact Details</h3> */}
      {/* Render the contact data from contactImportProcess */}
      {/* {contactImportProcess.externalContactImports.map((contact) => (
        <div key={contact.externalContactImportId}>
          <p>First Name: {contact.firstName}</p>
          <p>Last Name: {contact.lastName}</p>
        </div>
      ))} */}

      {/* {contactImportProcess.externalCompanyImports.length < 1 && (
        <Empty customMessage={'test'} />
      )} */}
    </>
  );
};
