import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../../theme/utils';
import { VirtualTable } from '@prio365/prio365-react-library';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import {
  ExternalCompanyImport,
  ExternalContactImport,
  ExternalOfficeImport,
  UpdateContactCompanyFromImportProcessDto,
} from '../../../../../models/ContactImportProcess';
import Flex from '../../../../../components/Flex';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import ExternalCompanyDrawer from '../components/ExternalCompanyDrawer';
import { apiUpdateContactsFromImportProcess } from '../../../api';
import ContactImporterFilterBar from '../components/ContactImporterFilterBar';

const useStyles = makePrioStyles((theme) => ({
  root: { height: '100%' },
}));

interface ExternalCompanyTabProps {
  contactImportProcess: {
    contactImportProcessId: string;
    externalCompanyImports: ExternalCompanyImport[];
    externalContactImports: ExternalContactImport[]; // To count contacts
    externalOfficeImports: ExternalOfficeImport[]; // Office lookup
  } | null;
  onSave: (
    updatedCompany: ExternalCompanyImport,
    updatedOffice: ExternalOfficeImport
  ) => void; // Add this prop
}

export const ExternalCompanyTab: React.FC<ExternalCompanyTabProps> = ({
  contactImportProcess,
  onSave,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [selectedSearchItems, setSelectedSearchItems] = useState<
    ExternalCompanyImport[]
  >([]);

  const [selectedCompany, setSelectedCompany] =
    useState<ExternalCompanyImport | null>(null);
  const [office, setOffice] = useState<ExternalOfficeImport | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleRowClick: (
    item: ExternalCompanyImport
  ) => VirtualListItemOnRowProps<ExternalCompanyImport> = useCallback(
    (company) => {
      return {
        onClick: (e) => {
          const officeForCompany =
            contactImportProcess?.externalOfficeImports.find(
              (office) =>
                office.externalCompanyImportId ===
                company.externalCompanyImportId
            );
          setSelectedCompany(company);
          setOffice(officeForCompany || null);
          setIsDrawerOpen(true);
        },
      };
    },
    [contactImportProcess?.externalOfficeImports]
  );

  const handleSave = async (
    updatedCompany: ExternalCompanyImport,
    updatedOffice: ExternalOfficeImport
  ) => {
    // Prepare the DTO
    const updateContactCompanyDto: UpdateContactCompanyFromImportProcessDto = {
      contactImportProcessId: contactImportProcess.contactImportProcessId,
      updateExternalCompanyImports: [updatedCompany],
      updateExternalContactImports: [], // Add if needed
      updateExternalOfficeImports: [updatedOffice],
      updateExternalBankAccountImports: [], // Add if needed
    };

    // Call the API
    const result = await apiUpdateContactsFromImportProcess(
      contactImportProcess.contactImportProcessId,
      updateContactCompanyDto
    );

    if (result.result.ok) {
      onSave(updatedCompany, updatedOffice);
      console.log('Update successful');
    } else {
      console.error('Update failed', result.data);
    }

    setIsDrawerOpen(false);
  };

  const onSelectionChange = (items: ExternalCompanyImport[]) => {
    setSelectedSearchItems(items);
  };

  // Function to close the drawer
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedCompany(null);
    setOffice(null);
  };

  // Function to count how many contacts belong to each company
  const getContactCountForCompany = (companyId: string) => {
    return (
      contactImportProcess?.externalContactImports.filter(
        (contact) => contact.externalCompanyImportId === companyId
      ).length || 0
    );
  };

  // Lookup the office details for each company
  const getOfficeDetails = (
    companyId: string,
    field: keyof ExternalOfficeImport
  ): string => {
    const office = contactImportProcess?.externalOfficeImports.find(
      (office) => office.externalCompanyImportId === companyId
    );
    return office && office[field] ? String(office[field]) : ''; // Ensure the return is a string
  };

  // Define the columns for the company table with sorting functions
  const columns: Column<ExternalCompanyImport>[] = [
    {
      id: 'fullName',
      alignSelf: true,
      width: 300,
      title: t('settings:companyImport.table.fullName'),
      accessor: 'fullName',
      Cell: (cellProps) => cellProps.value,
      sortingFn: (a, b) => (a.fullName || '').localeCompare(b.fullName || ''), // Alphabetical sort
    },
    {
      id: 'existingCompanyId',
      alignSelf: true,
      width: 250,
      title: t('settings:companyImport.table.existingCompanyId'),
      accessor: 'existingCompanyId',
      Cell: (cellProps) =>
        cellProps.value === '00000000-0000-0000-0000-000000000000'
          ? '-'
          : cellProps.value,
      sortingFn: (a, b) =>
        (a.existingCompanyId || '').localeCompare(b.existingCompanyId || ''), // Alphabetical sort
    },
    {
      id: 'phone',
      alignSelf: true,
      width: 250,
      title: t('settings:companyImport.table.phone'),
      accessor: 'externalCompanyImportId',
      Cell: (cellProps) => getOfficeDetails(cellProps.value, 'phone'),
      sortingFn: (a, b) =>
        getOfficeDetails(a.externalCompanyImportId, 'phone').localeCompare(
          getOfficeDetails(b.externalCompanyImportId, 'phone')
        ), // Sort by phone
    },
    {
      id: 'email',
      alignSelf: true,
      width: 250,
      title: t('settings:companyImport.table.email'),
      accessor: 'externalCompanyImportId',
      Cell: (cellProps) => getOfficeDetails(cellProps.value, 'email'),
      sortingFn: (a, b) =>
        getOfficeDetails(a.externalCompanyImportId, 'email').localeCompare(
          getOfficeDetails(b.externalCompanyImportId, 'email')
        ), // Sort by email
    },
    {
      id: 'street',
      alignSelf: true,
      width: 250,
      title: t('settings:companyImport.table.street'),
      accessor: 'externalCompanyImportId',
      Cell: (cellProps) => getOfficeDetails(cellProps.value, 'street'),
      sortingFn: (a, b) =>
        getOfficeDetails(a.externalCompanyImportId, 'street').localeCompare(
          getOfficeDetails(b.externalCompanyImportId, 'street')
        ), // Sort by email
    },
    {
      id: 'contactCount',
      alignSelf: true,
      width: 20,
      title: t('settings:companyImport.table.contactCount'),
      accessor: 'externalCompanyImportId',
      Cell: (cellProps) => getContactCountForCompany(cellProps.value),
      sortingFn: (a, b) =>
        getContactCountForCompany(a.externalCompanyImportId) -
        getContactCountForCompany(b.externalCompanyImportId), // Numeric sort by contact count
    },
    {
      id: 'description',
      alignSelf: true,
      width: 200,
      title: t('settings:companyImport.table.description'),
      accessor: 'description',
      Cell: (cellProps) => cellProps.value,
      sortingFn: (a, b) =>
        (a.description || '').localeCompare(b.description || ''), // Alphabetical sort for description
    },
    {
      id: 'action',
      alignSelf: true,
      width: 150,
      title: t('settings:companyImport.table.action'),
      accessor: 'action',
      Cell: (cellProps) =>
        t(`settings:companyImport.action.${cellProps.value}`), // Assuming `ContactImportAction` is localized
      sortingFn: (a, b) => a.action - b.action, // Sort by action
    },
    {
      id: 'menu',
      width: 100,
      alignSelf: true,
      accessor: 'externalCompanyImportId',
      title: ' ',
      Cell: ({ originalData }) => <Flex.Row justifyContent="center"></Flex.Row>,
    },
  ];

  if (!contactImportProcess || !contactImportProcess.externalCompanyImports) {
    return <p>{t('settings:companyImport.noData')}</p>;
  }

  const handleSearchChange = (searchValue) => {
    console.log(
      '🚀 ~ handleSearchChange ~ searchValue:',
      searchValue.target.value
    );
  };

  return (
    <div className={classNames(classes.root)}>
      <ContactImporterFilterBar
        filterActive={false}
        searchChangeHandler={handleSearchChange}
      />

      <VirtualTable<ExternalCompanyImport>
        columns={columns}
        data={contactImportProcess.externalCompanyImports}
        id="external-company-table"
        resizable="absolute"
        rowsAreSelectable={true}
        onSelectionChange={onSelectionChange}
        selectedItems={selectedSearchItems}
        onRow={handleRowClick} // Handle row click
      />

      <ExternalCompanyDrawer
        visible={isDrawerOpen}
        onClose={closeDrawer}
        selectedCompany={selectedCompany}
        office={office}
        onSave={handleSave}
        contacts={contactImportProcess.externalContactImports.filter(
          (contact) =>
            contact.externalCompanyImportId ===
            selectedCompany?.externalCompanyImportId
        )}
      />
    </div>
  );
};
