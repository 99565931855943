import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressBook as falAddressBook,
  faAddressCard as falAddressCard,
  faAlarmClock as falAlarmClock,
  faAngleUp as falAngleUp,
  faAngleDown as falAngleDown,
  faAngleDoubleRight as falAngleDoubleRight,
  faArchive as falArchive,
  faArrowDown as falArrowDown,
  faArrowDownRight as falArrowDownRight,
  faArrowFromLeft as falArrowFromLeft,
  faArrowLeft as falArrowLeft,
  faArrowRight as falArrowRight,
  faArrowRotateRight as falArrowRotateRight,
  faArrowRotateLeft as falArrowRotateLeft,
  faArrowUp as falArrowUp,
  faArrowUpRightFromSquare as falArrowUpRightFromSquare,
  faArrowUpToLine as falArrowUpToLine,
  faArrowUpRight as falArrowUpRight,
  faArrowRightToBracket as falArrowRightToBracket,
  faArrowToBottom as falArrowToBottom,
  faArrowToTop as falArrowToTop,
  faArrowTurnDownRight as falArrowTurnDownRight,
  faArrowDownArrowUp as falArrowDownArrowUp,
  faBaby as falBaby,
  faBan as falBan,
  faBars as falBars,
  faBell as falBell,
  faBookOpenCover as falBookOpenCover,
  faBoxArchive as falBoxArchive,
  faBuilding as falBuilding,
  faBusinessTime as falBusinessTime,
  faCakeCandles as falCakeCandles,
  faCalendar as falCalendar,
  faCalendarAlt as falCalendarAlt,
  faCalendarCheck as falCalendarCheck,
  faCalendarCirclePlus as falCalendarCirclePlus,
  faCalendarClock as falCalendarClock,
  faCalendarDay as falCalendarDay,
  faCalendarMinus as falCalendarMinus,
  faCalendarPlus as falCalendarPlus,
  faCalendarRange as falCalendarRange,
  faCalendarStar as falCalendarStar,
  faCalendarWeek as falCalendarWeek,
  faChartSimple as falChartSimple,
  faCheck as falCheck,
  faCheckCircle as falCheckCircle,
  faChevronCircleRight as falChevronCircleRight,
  faChevronCircleLeft as falChevronCircleLeft,
  faChevronDoubleLeft as falChevronDoubleLeft,
  faChevronDoubleRight as falChevronDoubleRight,
  faChevronDown as falChevronDown,
  faChevronLeft as falChevronLeft,
  faChevronRight as falChevronRight,
  faChevronUp as falChevronUp,
  faCircle as falCircle,
  faCircleEnvelope as falCircleEnvelope,
  faClipboardCheck as falClipboardCheck,
  faClipboardList as falClipboardList,
  faClock as falClock,
  faClockSeven as falClockSeven,
  faCloud as falCloud,
  faCoffee as falCoffee,
  faCoins as falCoins,
  faComment as falComment,
  faComments as falComments,
  faMessageBot as falMessageBot,
  faMessageArrowDown as falMessageArrowDown,
  faCompress as falCompress,
  faCog as falCog,
  faCopy as falCopy,
  faCrutches as falCrutches,
  faDotCircle as falDotCircle,
  faEdit as falEdit,
  faEllipsisV as falEllipsisV,
  faEllipsisH as falEllipsisH,
  faEmptySet as falEmptySet,
  faEnvelope as falEnvelope,
  faEnvelopes as falEnvelopes,
  faEnvelopeOpen as falEnvelopeOpen,
  faEnvelopeOpenDollar as falEnvelopeOpenDollar,
  faEnvelopeOpenText as falEnvelopeOpenText,
  faEuroSign as falEuroSign,
  faExchange as falExchange,
  faExclamation as falExclamation,
  faExclamationCircle as falExclamationCircle,
  faExclamationTriangle as falExclamationTriangle,
  faExpand as falExpand,
  faExternalLinkAlt as falExternalLinkAlt,
  faEye as falEye,
  faFax as falFax,
  faEyeSlash as falEyeSlash,
  faFile as falFile,
  faFilePlus as falFilePlus,
  faFiles as falFiles,
  faFileAlt as falFileAlt,
  faFileCsv as falFileCsv,
  faFileExcel as falFileExcel,
  faFileExport as falFileExport,
  faFileImport as falFileImport,
  faFileImage as falFileImage,
  faFileMedical as falFileMedical,
  faFileUpload as falFileUpload,
  faFilter as falFilter,
  faFlag as falFlag,
  faFolder as falFolder,
  faFolderBookmark as falFolderBookmark,
  faFolderPlus as falFolderPlus,
  faFolders as falFolders,
  faFolderTree as falFolderTree,
  faGears as falGears,
  faGlassCitrus as falGlassCitrus,
  faGlobe as falGlobe,
  faGlobeEurope as falGlobeEurope,
  faGraduationCap as falGraduationCap,
  faGripDotsVertical as falGripDotsVertical,
  faGripVertical as falGripVertical,
  faHandHoldingDollar as falHandsHoldingDollar,
  faHistory as falHistory,
  faHorizontalRule as falHorizontalRule,
  faHospital as falHospital,
  faHousePersonLeave as falHousePersonLeave,
  faImage as falImage,
  faInbox as falInbox,
  faInboxIn as falInboxIn,
  faInfoCircle as falInfoCircle,
  faInfinity as falInfinity,
  faGauge as falGauge,
  faIslandTropical as falIslandTropical,
  faKey as falKey,
  faLink as falLink,
  faList as falList,
  faLock as falLock,
  faLongArrowDown as falLongArrowDown,
  faLongArrowUp as falLongArrowUp,
  faMap as falMap,
  faMapMarkerAlt as falMapMarkerAlt,
  faMemo as falMemo,
  faMobile as falMobile,
  faMoneyBillTransfer as falMoneyBillTransfer,
  faMinus as falMinus,
  faMinusCircle as falMinusCircle,
  faMinusSquare as falMinusSquare,
  faMoneyBill as falMoneyBill,
  faNotebook as falNotebook,
  faNotes as falNotes,
  faPaperclip as falPaperclip,
  faPaperPlane as falPaperPlane,
  faPaperPlaneTop as falPaperPlaneTop,
  faPen as falPen,
  faPencil as falPencil,
  faPersonBreastfeeding as falPersonBreastfeeding,
  faPeopleArrows as falPeopleArrows,
  faPeopleGroup as falPeopleGroup,
  faPhone as falPhone,
  faPills as falPills,
  faPlay as falPlay,
  faPlus as falPlus,
  faPlusCircle as falPlusCircle,
  faPlusSquare as falPlusSquare,
  faPrint as falPrint,
  faPuzzlePiece as falPuzzlePiece,
  faQuestionCircle as falQuestionCircle,
  faReceipt as falReceipt,
  faRectangleHistory as falRectangleHistory,
  faRectangleLandscape as falRectangleLandscape,
  faRedo as falRedo,
  faRepeat as falRepeat,
  faReply as falReply,
  faReplyAll as falReplyAll,
  faRoad as falRoad,
  faRotateRight as falRotateRight,
  faRotateLeft as falRotateLeft,
  faSearch as falSearch,
  faSchool as falSchool,
  faSigma as falSigma,
  faSignOut as falSignOut,
  faSpinnerThird as falSpinnerThird,
  faStar as falStar,
  faSync as falSync,
  faTachometerAlt as falTachometerAlt,
  faTachometerAltFast as falTachometerAltFast,
  faTasks as falTasks,
  faTasksAlt as falTasksAlt,
  faTimelineArrow as falTimelineArrow,
  faTimes as falTimes,
  faTimesCircle as falTimesCircle,
  faTrash as falTrash,
  faTrashAlt as falTrashAlt,
  faTrashRestore as falTrashRestore,
  faTrashUndo as falTrashUndo,
  faTriangleExclamation as falTriangleExclamation,
  faTurnDownRight as falTurnDownRight,
  faUniversity as falUniversity,
  faUpload as falUpload,
  faUser as falUser,
  faUserCog as falUserCog,
  faUserClock as falUserClock,
  faUserEdit as falUserEdit,
  faUserFriends as falUserFriends,
  faUserGraduate as falUserGraduate,
  faUserMagnifyingGlass as falUserMagnifyingGlass,
  faUserPlus as falUserPlus,
  faUsers as falUsers,
  faUserShield as falUserShield,
  faVideo as falVideo,
  faFaceWorried as falFaceWorried,
  faStarChristmas as falStarChristmas,
  faBriefcase as falBriefcase,
  faSquareArrowRight as falSquareArrowRight,
  faBookmark as falBookmark,
  faBookmarkSlash as falBookmarkSlash,
  faAngleRight as falAngleRight,
  faAngleLeft as falAngleLeft,
  faEquals as falEquals,
  faNotEqual as falNotEqual,
  faGreaterThanEqual as falGreaterThanEqual,
  faLessThanEqual as falLessThanEqual,
  faGreaterThan as falGreaterThan,
  faLessThan as falLessThan,
  faDolly as falDolly,
  faDollyEmpty as falDollyEmpty,
  faSquare as falSquare,
  faTriangle as falTriangle,
  faSquareDashed as falSquareDashed,
  faSailboat as falSailboat,
  faSwimmer as falSwimmer,
  faFlagCheckered as falFlagCheckered,
  faRunning as falRunning,
  faPenClip as falPenClip,
  faDatabase as falDatabase,
} from '@fortawesome/pro-light-svg-icons';

import {
  faBarsProgress as farBarsProgress,
  faBookmark as farBookmark,
  faCheck as farCheck,
  faMinus as farMinus,
  faMessageBot as farMessageBot,
  faTimes as farTimes,
} from '@fortawesome/pro-regular-svg-icons';
import {} from '@fortawesome/pro-thin-svg-icons';

import {
  faAdjust as fasAdjust,
  faBan as fasBan,
  faBell as fasBell,
  faBug as fasBug,
  faCheckCircle as fasCheckCircle,
  faChevronCircleLeft as fasChevronCircleLeft,
  faChevronCircleRight as fasChevronCircleRight,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCircle as fasCircle,
  faCircleCheck as fasCirlceCheck,
  faCircleXmark as fasCirlceXMark,
  faCloud as fasCloud,
  faEnvelope as fasEnvelope,
  faExclamationCircle as fasExclamationCircle,
  faExternalLink as fasExternalLink,
  faFile as fasFile,
  faFileArchive as fasFileArchive,
  faFileExcel as fasFileExcel,
  faFileImage as fasFileImage,
  faFilePdf as fasFilePdf,
  faFilePowerpoint as fasFilePowerpoint,
  faFileVideo as fasFileVideo,
  faFileWord as fasFileWord,
  faFilter as fasFilter,
  faFlag as fasFlag,
  faFolder as fasFolder,
  faGear as fasGear,
  faGripDotsVertical as fasGripDotsVertical,
  faHeart as fasHeart,
  faInfoCircle as fasInfoCircle,
  faMobile as fasMobile,
  faNotebook as fasNotebook,
  faPhone as fasPhone,
  faSignOutAlt as fasSignOutAlt,
  faStar as fasStar,
  faTag as fasTag,
  faTimesCircle as fasTimesCircle,
  faTriangle as fasTriangle,
  faTurnDownRight as fasTurnDownRight,
  faSquare as fasSquare,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCircleEnvelope as fadCircleEnvelope,
  faFolderXmark as fadFolderXmark,
  faUserRobot as fadUserRobot,
} from '@fortawesome/pro-duotone-svg-icons';

const falIcons: IconDefinition[] = [
  falAddressBook,
  falAddressCard,
  falAlarmClock,
  falAngleUp,
  falAngleDown,
  falAngleDoubleRight,
  falArchive,
  falArrowDown,
  falArrowDownRight,
  falArrowFromLeft,
  falArrowLeft,
  falArrowRight,
  falArrowRotateRight,
  falArrowRotateLeft,
  falArrowUp,
  falArrowUpRightFromSquare,
  falArrowUpToLine,
  falArrowUpRight,
  falArrowRightToBracket,
  falArrowToBottom,
  falArrowToTop,
  falArrowTurnDownRight,
  falArrowDownArrowUp,
  falBaby,
  falBan,
  falBars,
  falBell,
  falBookOpenCover,
  falBookmarkSlash,
  falBoxArchive,
  falBuilding,
  falBusinessTime,
  falCakeCandles,
  falCalendar,
  falCalendarAlt,
  falCalendarCheck,
  falCalendarCirclePlus,
  falCalendarClock,
  falCalendarDay,
  falCalendarMinus,
  falCalendarPlus,
  falCalendarRange,
  falCalendarStar,
  falCalendarWeek,
  falChartSimple,
  falCheck,
  falCheckCircle,
  falChevronCircleLeft,
  falChevronCircleRight,
  falChevronDoubleLeft,
  falChevronDoubleRight,
  falChevronDown,
  falChevronLeft,
  falChevronRight,
  falChevronUp,
  falCircle,
  falCircleEnvelope,
  falClipboardCheck,
  falClipboardList,
  falClock,
  falClockSeven,
  falCloud,
  falCoffee,
  falCoins,
  falComment,
  falComments,
  falMessageBot,
  falMessageArrowDown,
  falCompress,
  falCog,
  falCopy,
  falCrutches,
  falDotCircle,
  falEdit,
  falEllipsisV,
  falEllipsisH,
  falEmptySet,
  falEnvelope,
  falEnvelopes,
  falEnvelopeOpen,
  falEnvelopeOpenDollar,
  falEnvelopeOpenText,
  falEuroSign,
  falExchange,
  falExclamation,
  falExclamationCircle,
  falExclamationTriangle,
  falExpand,
  falExternalLinkAlt,
  falEye,
  falFax,
  falEyeSlash,
  falFile,
  falFilePlus,
  falFiles,
  falFileAlt,
  falFileCsv,
  falFileExcel,
  falFileExport,
  falFileImport,
  falFileImage,
  falFileMedical,
  falFileUpload,
  falFilter,
  falFlag,
  falFolder,
  falFolderBookmark,
  falFolderPlus,
  falFolders,
  falFolderTree,
  falGears,
  falGlassCitrus,
  falGlobe,
  falGlobeEurope,
  falHandsHoldingDollar,
  falGraduationCap,
  falGripDotsVertical,
  falGripVertical,
  falHistory,
  falHorizontalRule,
  falHospital,
  falHousePersonLeave,
  falImage,
  falInbox,
  falInboxIn,
  falInfoCircle,
  falInfinity,
  falGauge,
  falIslandTropical,
  falKey,
  falLink,
  falList,
  falLock,
  falLongArrowDown,
  falLongArrowUp,
  falMap,
  falMapMarkerAlt,
  falMemo,
  falMobile,
  falMinus,
  falMinusCircle,
  falMinusSquare,
  falMoneyBill,
  falMoneyBillTransfer,
  falNotebook,
  falNotes,
  falPaperclip,
  falPaperPlane,
  falPaperPlaneTop,
  falPen,
  falPencil,
  falPersonBreastfeeding,
  falPeopleArrows,
  falPeopleGroup,
  falPhone,
  falPills,
  falPlay,
  falPlus,
  falPlusCircle,
  falPlusSquare,
  falPrint,
  falPuzzlePiece,
  falQuestionCircle,
  falReceipt,
  falRectangleHistory,
  falRectangleLandscape,
  falRedo,
  falRepeat,
  falReply,
  falReplyAll,
  falRoad,
  falRotateRight,
  falRotateLeft,
  falSearch,
  falSchool,
  falSigma,
  falSignOut,
  falSpinnerThird,
  falStar,
  falSync,
  falTachometerAlt,
  falTachometerAltFast,
  falTasks,
  falTasksAlt,
  falTimelineArrow,
  falTimes,
  falTimesCircle,
  falTrash,
  falTrashAlt,
  falTrashRestore,
  falTrashUndo,
  falTriangleExclamation,
  falTurnDownRight,
  falUniversity,
  falUpload,
  falUser,
  falUserCog,
  falUserClock,
  falUserEdit,
  falUserFriends,
  falUserGraduate,
  falUserMagnifyingGlass,
  falUserPlus,
  falUsers,
  falUserShield,
  falVideo,
  falFaceWorried,
  falStarChristmas,
  falBriefcase,
  falSquareArrowRight,
  falBookmark,
  falAngleRight,
  falAngleLeft,
  falEquals,
  falNotEqual,
  falGreaterThanEqual,
  falLessThanEqual,
  falGreaterThan,
  falLessThan,
  falDolly,
  falDollyEmpty,
  falSquare,
  falTriangle,
  falSquareDashed,
  falSailboat,
  falSwimmer,
  falFlagCheckered,
  falRunning,
  falPenClip,
  falDatabase,
];

const farIcons: IconDefinition[] = [
  farBarsProgress,
  farBookmark,
  farCheck,
  farMinus,
  farMessageBot,
  farTimes,
];

const fasIcons: IconDefinition[] = [
  fasAdjust,
  fasBan,
  fasBell,
  fasBug,
  fasCirlceCheck,
  fasCirlceXMark,
  fasCheckCircle,
  fasChevronCircleLeft,
  fasChevronCircleRight,
  fasChevronLeft,
  fasChevronRight,
  fasCircle,
  fasCloud,
  fasEnvelope,
  fasExclamationCircle,
  fasExternalLink,
  fasFile,
  fasFileArchive,
  fasFileExcel,
  fasFileImage,
  fasFilePdf,
  fasFilePowerpoint,
  fasFileVideo,
  fasFileWord,
  fasFilter,
  fasFlag,
  fasFolder,
  fasGear,
  fasGripDotsVertical,
  fasHeart,
  fasInfoCircle,
  fasMobile,
  fasNotebook,
  fasPhone,
  fasSignOutAlt,
  fasSquare,
  fasStar,
  fasTag,
  fasTimesCircle,
  fasTriangle,
  fasTurnDownRight,
];

const fadIcons: IconDefinition[] = [
  fadCircleEnvelope,
  fadFolderXmark,
  fadUserRobot,
];
const fatIcons: IconDefinition[] = [];

const icons = {
  add: () => {
    library.add(
      ...falIcons,
      ...farIcons,
      ...fasIcons,
      ...fadIcons,
      ...fatIcons
    );
  },
};

export default icons;
