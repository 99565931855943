export enum ContactImportState {
  Pending,
  InProgress,
  Validated,
  ImportRequested,
  Imported,
  Failed,
}

//EContactImportAction
export enum ContactImportAction {
  NotSet,
  Skip,
  UpdateMissing,
  OverrideExisting,
  Create,
}

export interface ExternalImport {
  initiatorContactId: string;
  contactImportProcessId: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  action: ContactImportAction;
}

export interface ExternalContactImport extends ExternalImport {
  externalContactImportId: string;
  externalCompanyImportId?: string | null; // Optional, wenn es kein Unternehmen gibt
  externalOfficeImportId?: string | null; // Optional, wenn es kein Büro gibt
  gender: string; // Typ kann als Enum definiert werden, wenn nötig
  salutation: string;
  firstName: string;
  lastName: string;
  department: string;
  position: string;
  email: string;
  email2?: string; // Optional
  email3?: string; // Optional
  phone: string;
  phone2?: string; // Optional
  phone3?: string; // Optional
  cellphone: string;
  cellphone2?: string; // Optional
  cellphone3?: string; // Optional
  street: string;
  zip: string;
  city: string;
  website?: string; // Optional
  fax?: string; // Optional
  notes?: string; // Optional
  title?: string; // Optional
  signaturePrefix?: string; // Optional
  existingContactId: string;
  existingCompanyId: string;
  existingOfficeId: string;
}

export interface ExternalCompanyImport extends ExternalImport {
  externalCompanyImportId: string;
  shortName: string;
  fullName: string;
  fullName2?: string; // Optional
  website?: string; // Optional
  notes?: string; // Optional
  commercialRegisterNumber?: string; // Optional
  commercialRegisterCity?: string; // Optional
  legalForm?: string; // Optional
  externalOfficeImport: ExternalOfficeImport[]; // Array von ExternalOfficeImport
  vatNumber?: string; // Optional
  externalBankAccountImports: ExternalBankAccountImport[]; // Array von ExternalBankAccountImport
  isClient: boolean;
  isServiceProvider: boolean;
  companyClassificationName?: string; // Optional
  existingCompanyId: string;
}

export interface ExternalOfficeImport extends ExternalImport {
  externalOfficeImportId: string;
  name: string;
  phone: string;
  email: string;
  street: string;
  zip: string;
  city: string;
  isMainOffice: boolean;
  federalStateCode?: string; // Optional
  countryCode?: string; // Optional
  externalCompanyImportId: string;
  existingOfficeId: string;
}

export interface ExternalBankAccountImport {
  externalBankAccountImportId: string;
  externalCompanyImportId?: string; // Optional
  creditInstitutionName: string;
  bic: string;
  iban: string;
  rowVersion: ArrayBuffer; // Typ für RowVersion
}
export interface ContactImportProcess {
  contactImportProcessId: string;
  initiatorContactId: string;
  createdAt: Date;
  updatedAt: Date;
  jsonReport: string;
  description: string;
  state: ContactImportState;
  externalCompanyImportsCount: number;
  externalContactImportsCount: number;

  // Arrays of related imports
  externalCompanyImports: ExternalCompanyImport[];
  externalContactImports: ExternalContactImport[];
  externalOfficeImports: ExternalOfficeImport[];
}

export interface UpdateContactCompanyFromImportProcessDto {
  contactImportProcessId: string;
  updateExternalCompanyImports: ExternalCompanyImport[];
  updateExternalContactImports: ExternalContactImport[];
  updateExternalOfficeImports: ExternalOfficeImport[];
  updateExternalBankAccountImports: ExternalBankAccountImport[];
}

export interface ImportExtractionDto {
  NameSimilarityThreshold?: number;
  MaxLevenshteinDistance?: number;
}

export interface CsvMappingDto extends ImportExtractionDto {
  CompanyShortName: string;
  CompanyFullName: string;
  CompanyFullName2: string;
  CompanyWebsite: string;
  CompanyNotes: string;
  CompanyCommercialRegisterNumber: string;
  CompanyCommercialRegisterCity: string;
  CompanyLegalForm: string;
  CompanyIsArchived: string;
  CompanyIsClient: string;
  CompanyIsServiceProvider: string;
  CompanyClassificationName: string;
  BankCreditInstitutionName: string;
  BankBIC: string;
  BankIBAN: string;
  OfficeName: string;
  OfficePhone: string;
  OfficeEMail: string;
  OfficeStreet: string;
  OfficeZip: string;
  OfficeCity: string;
  OfficeIsMainOffice: string;
  OfficeFederalStateCode: string;
  OfficeCountryCode: string;
  OfficeIsArchived: string;
  ContactGender: string;
  ContactSalutation: string;
  ContactFirstName: string;
  ContactLastName: string;
  ContactDepartment: string;
  ContactPosition: string;
  ContactEMail: string;
  ContactEMail2: string;
  ContactEMail3: string;
  ContactPhone: string;
  ContactPhone2: string;
  ContactPhone3: string;
  ContactCellphone: string;
  ContactCellphone2: string;
  ContactCellphone3: string;
  ContactStreet: string;
  ContactZip: string;
  ContactCity: string;
  ContactWebsite: string;
  ContactFax: string;
  ContactNotes: string;
  ContactTitle: string;
  ContactSignaturePrefix: string;
  ContactIsArchived: string;
  ContactContactClassificationName: string;
}
