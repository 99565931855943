import React from 'react';
import { makePrioStyles } from '../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@prio365/prio365-react-library';
import { PrioTheme } from '../theme/types';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';

const useStyles = makePrioStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    justifyContent: 'left',
    color: theme.colors.application.typography.muted,
  },
  icon: {
    marginRight: theme.spacing.regular,
    fontSize: theme.font.fontSize.large,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    fontSize: theme.font.fontSize.small,
  },
}));

interface InformationBoxProps {
  className?: string;
  description: string;
  marginBottom?: 'large' | 'regular' | 'small' | 'none';
  marginTop?: 'large' | 'regular' | 'small' | 'none';
  type?: 'info' | 'warning';
  icon?: IconName;
}

export const InformationBox: React.FC<InformationBoxProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    description,
    marginBottom = 'large',
    marginTop = 'none',
    icon,
    type = 'info',
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const marginBottomValue =
    marginBottom === 'none' ? 0 : theme.spacing[marginBottom];

  const marginTopValue = theme.spacing[marginTop];

  const iconString: IconName = type === 'info' ? 'circle-info' : 'warning';
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames(classes.boxContainer, className)}
      style={{
        marginBottom: marginBottomValue,
        marginTop: marginTopValue,
      }}
    >
      <span className={classes.icon}>
        <FontAwesomeIcon
          icon={['fal', icon ? icon : iconString]}
          color={
            type === 'warning' ? theme.colors.base.yellow.default : undefined
          }
        />
      </span>
      <span className={classes.text}>{description}</span>
    </div>
  );
};

export default InformationBox;
