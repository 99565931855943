import React, { useRef, useState } from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import NavigationBar from '../../../components/NavigationBar';
import { ProjectId, ProjectRole } from '../../../models/Types';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../../theme/types';

import ProjectContactsPage from './ProjectContactsPage';
import { Button, Modal, Select } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { Form, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createExternalProjectContact } from '../../projects/actions';
import { apiImportProjectMember } from '../api';
import AddExternalContactDrawer from './AddExternalContactDrawer';
import { getUserMe } from '../../../apps/main/rootReducer';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import {
  ProjectMember,
  ProjectMemberCalculatedData,
} from '../../../models/ProjectContacts';
import useProjectsContext from '../../projects/hooks/useProjectsContext';
import AddInternalContactDrawer from './AddInternalProjectContactDrawer/AddInternalContactDrawer';
import InformationBox from '../../../components/InformationBox';
import ProjectPickerPrioCL from '../../projects/components/ProjectPickerPrioCL';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  body: {
    overflow: 'hidden',
  },
  menu: {
    maxWidth: theme.old.components.menuMaxWidth,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  page: {
    height: '100%',
  },
  content: {
    overflow: 'hidden',
  },
  projectPicker: {
    width: '100%',
    height: '30px',
    marginBottom: theme.spacing.small,
    '& .ant-select-arrow': {
      marginRight: '6px',
    },
    '& .ant-select-suffix': {
      color: theme.colors.application.typography.active,
    },
  },
  p: {
    marginBottom: '4px',
  },
  modalContainer: {
    fontSize: '15px',
    '& .prio-select-selection-item': {
      fontSize: '15px',
    },
    '& .ant-select-selection-item': {
      color: theme.colors.application.typography.default,
    },
  },
  modalInfo: {
    marginTop: theme.spacing.small,
    color: theme.colors.application.typography.muted,
    fontSize: theme.font.fontSize.small,
  },
}));

interface ProjectContactsSubModuleProps {
  projectId: ProjectId;
}

export const ProjectContactsSubModule: React.FC<
  ProjectContactsSubModuleProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { projectId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getProjectById } = useProjectsContext();
  const userMe = useSelector(getUserMe);

  const [externalUserDrawerVisible, setExternalUserDrawerVisible] =
    useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectId>('');
  const [selectedContactType, setSelectedContactType] =
    useState<string>('internal');
  const [importModalVisible, setImportModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAddInternalContactDrawerOpen, setIsAddInternalContactDrawerOpen] =
    useState(false);

  const myProjectRoles: ProjectRole[] =
    userMe?.prioData.projectRoles[projectId];
  const myOfficeRoles =
    userMe?.prioData.officeRoles[getProjectById(projectId)?.internalOfficeId];
  const myGlobalRoles = userMe?.prioData.globalRoles;
  const { optimisticWrite } = useFilterContext<
    ProjectMember,
    ProjectMemberCalculatedData
  >();

  const canImportInternal =
    myProjectRoles?.includes('projectAdmin') ||
    myOfficeRoles?.includes('officeAdmin') ||
    myGlobalRoles?.includes('globalAdmin');

  const updateTableRef = useRef<(() => void) | null>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnFinish = async (values) => {
    setLoading(true);
    optimisticWrite(
      values.contacts.map((contact) => ({
        data: {
          contactId: contact.contactId,
          externalProjectContactId: contact.contactId,
          internalProjectContactId: null,
          projectId: projectId,
          jobTitle: contact.jobTitle,
          notes: contact.notes,
          projectRoles: [],
        },
        calculated: { projectExtensionAccessProjectExtensionMaps: [] },
        method: 'add',
        callback: async () => {
          const response = await (dispatch as any)(
            createExternalProjectContact({
              contactId: contact.contactId,
              projectId: projectId,
              jobTitle: contact.jobTitle,
              notes: contact.notes,
            })
          );
          if (response.status === 200) {
            return {
              result: response,
              data: {
                data: {
                  contactId: contact.contactId,
                  externalProjectContactId: contact.contactId,
                  internalProjectContactId: null,
                  projectId: projectId,
                  jobTitle: contact.jobTitle,
                  notes: contact.notes,
                  projectRoles: [] as ProjectRole[],
                },
                calculated: {
                  projectExtensionAccessProjectExtensionMaps: [],
                },
              },
            };
          } else {
            notification.open({
              message: t('common:error'),
              description: t(
                'contacts:projectContactsPage.addExternalContactDrawer.error'
              ),
            });
            return {
              result: response,
              data: [],
            };
          }
        },
      }))
    );

    setLoading(false);
    form.resetFields();
    setExternalUserDrawerVisible(false);
  };
  //#endregion

  //#region ------------------------------ ImportModal
  const options = [
    {
      value: 'internal',
      label: t('contacts:projectContactsPage.importModal.options.internal'),
    },
    {
      value: 'external',
      label: t('contacts:projectContactsPage.importModal.options.external'),
    },
    {
      value: 'all',
      label: t('contacts:projectContactsPage.importModal.options.all'),
    },
  ];

  const renderContent = (
    <Form form={form} layout="vertical">
      <Form.Item
        label={t('contacts:projectContactsPage.importModal.project')}
        name="project"
        rules={[
          {
            required: true,
            message: t(
              'contacts:projectContactsPage.importModal.projectRequired'
            ),
          },
        ]}
      >
        <ProjectPickerPrioCL
          className={classes.projectPicker}
          filter={(project) => project.projectId !== projectId}
          onChange={(value) => setSelectedProject(value as string)}
          size="default"
        />
      </Form.Item>
      <Form.Item
        label={t('contacts:projectContactsPage.importModal.contactType')}
        name="contactType"
        rules={[
          {
            required: true,
            message: t(
              'contacts:projectContactsPage.importModal.contactTypeRequired'
            ),
          },
        ]}
      >
        <Select
          options={options}
          onChange={(value) => setSelectedContactType(value)}
          showSearch={false}
          defaultValue={canImportInternal ? 'internal' : 'external'}
          disabled={!canImportInternal}
        />
      </Form.Item>
      <Form.Item>
        <InformationBox
          description={t('contacts:projectContactsPage.importModal.info')}
          marginBottom="none"
        />
      </Form.Item>
    </Form>
  );

  const handleImportMembers = async () => {
    setLoading(true);
    // TODO: optimisticWrite verwenden
    const data = await apiImportProjectMember(
      {
        importMembersFromProjectId: selectedProject,
        importInternalProjectMembers:
          selectedContactType === 'internal' || selectedContactType === 'all'
            ? true
            : false,
        importExternalProjectMembers:
          selectedContactType === 'external' || selectedContactType === 'all'
            ? true
            : false,
      },
      projectId
    );
    if (data.result.status >= 200 && data.result.status < 300) {
      setTimeout(() => {
        if (updateTableRef.current) {
          updateTableRef.current();
        }
        setLoading(false);
        setImportModalVisible(false);
      }, 3000);
    } else {
      setLoading(false);
      setImportModalVisible(false);
      notification.error({
        message: t('common:error'),
        description: t('contacts:projectContactsPage.importModal.error'),
      });
    }
  };
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <NavigationBar>
        {canImportInternal && (
          <Button
            iconProp={['fal', 'user-plus']}
            onClick={() => setIsAddInternalContactDrawerOpen(true)}
          >
            {t(
              'contacts:projectContactsPage.navigatioBarButtons.addInternalContact'
            )}
          </Button>
        )}

        <Button
          iconProp={['fal', 'user-plus']}
          onClick={() => setExternalUserDrawerVisible(true)}
        >
          {t(
            'contacts:projectContactsPage.navigatioBarButtons.addExternalContact'
          )}
        </Button>
        <Button
          iconProp={['fal', 'file-import']}
          type="link"
          onClick={() => setImportModalVisible(true)}
        >
          {t('contacts:projectContactsPage.navigatioBarButtons.import')}
        </Button>
      </NavigationBar>
      <Flex.Row flex={1} className={classes.body}>
        <Flex.Item flex={1} className={classes.content}>
          <Routes>
            <Route
              path="/"
              element={
                <ProjectContactsPage
                  projectId={projectId}
                  className={classes.page}
                />
              }
            />
          </Routes>
        </Flex.Item>
      </Flex.Row>
      <AddExternalContactDrawer
        visible={externalUserDrawerVisible}
        onClose={() => {
          setExternalUserDrawerVisible(false);
          form.resetFields();
        }}
        onFinish={handleOnFinish}
        loading={loading}
        form={form}
        projectId={projectId}
      />
      <AddInternalContactDrawer
        projectId={projectId}
        drawerVisible={isAddInternalContactDrawerOpen}
        setDrawerVisible={setIsAddInternalContactDrawerOpen}
      />
      <Modal
        visible={importModalVisible}
        title={t('contacts:projectContactsPage.importModal.title')}
        onOk={handleImportMembers}
        onClose={() => setImportModalVisible(false)}
        okText={t('contacts:projectContactsPage.importModal.import')}
        cancelText={t('contacts:projectContactsPage.importModal.cancel')}
        destroyOnClose
        okButtonProps={{ disabled: !selectedProject || loading, loading }}
      >
        {renderContent}
      </Modal>
    </Flex.Column>
  );
};

export default ProjectContactsSubModule;
