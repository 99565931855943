import { createContext } from 'react';
import { ContactSearchResultItem } from '../../../../models/Contact';
import { NewInternalProjectMemberRequest } from '../../../../models/ProjectContacts';
import { ContactId } from '../../../../models/Types';

export interface DefaultContextValuesProps {
  selectedContacts: ContactSearchResultItem[];
  setSelectedContacts: (contacts: ContactSearchResultItem[]) => void;
  contactsToConfigure: ContactId[];
  setContactsToConfigure: (contacts: ContactId[]) => void;
  configValues: NewInternalProjectMemberRequest[];
  setConfigValues: (
    values:
      | NewInternalProjectMemberRequest[]
      | ((
          value: NewInternalProjectMemberRequest[]
        ) => NewInternalProjectMemberRequest[])
  ) => void;
}

const defaultValue: DefaultContextValuesProps = {
  selectedContacts: [],
  setSelectedContacts: (contacts: ContactSearchResultItem[]) => {},
  contactsToConfigure: [],
  setContactsToConfigure: (contacts: ContactId[]) => {},
  configValues: [],
  setConfigValues: (values: NewInternalProjectMemberRequest[]) => {},
};

const AddInternalContactDrawerContext =
  createContext<DefaultContextValuesProps>(defaultValue);

export default AddInternalContactDrawerContext;
