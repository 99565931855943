import React, { useEffect, useRef, useState } from 'react';
import { GlobalProjectSettingType, ProjectId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import {
  Typography,
  Radio,
  Select,
  Input,
  Collapse,
  Divider,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { ProjectMailSetting } from '../../../models/Project';
import {
  apiCreateProjectMailSetting,
  apiFetchProjectMailSetting,
  apiUpdateProjectMailSetting,
} from '../api';
import { apiFetchGlobalProjectSetting } from '../../settings/api';
import { GlobalProjectSetting } from '../../../models/Configuration';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/languages/de.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { apiFetchEmailProjectSettingPreview } from '../../mail/api';
import classNames from 'classnames';
import HTMLTextEditor from '../../../components/HTMLTextEditor';
import equals from 'deep-equal';
import DebouncedInputSearch from '../../../components/DebouncedInputField/DebouncedInputSearch';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: theme.old.spacing.defaultPadding,
  },
  content: {
    marginBottom: theme.old.spacing.unit(1.5),
  },
  label: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
    paddingBottom: theme.old.spacing.unit(0.5),
  },
  creatButton: {
    color: '#000',
    padding: 0,
    '&.ant-btn-link:hover': {
      color: '#000',
    },
    '&.ant-btn-link:focus': {
      color: '#000',
    },
    '&.ant-btn-link:active': {
      color: '#000',
    },
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
    '& > label:nth-child(n+2)': {
      marginTop: theme.old.spacing.baseSpacing,
    },
  },
  radioButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 0,
    '& > span:nth-child(2)': {
      flex: 1,
      paddingRight: 0,
    },
    '& .ant-radio': {
      top: 0,
    },
  },
  radioLabelCol: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  preview: {
    padding: theme.old.spacing.defaultPadding,
    border: theme.old.borders.sub,
    borderRadius: theme.old.borders.radius,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  editor: {
    '& .fr-box.fr-basic': {
      maxHeight: 500,
    },
    cursor: 'initial',
  },
  disabledEditor: {
    cursor: 'not-allowed',
  },
}));

const previewFunc = (type: GlobalProjectSettingType, value: string) => {
  if (!value) {
    return null;
  }
  switch (type) {
    case 'emailSubject': {
      return <div>{value}</div>;
    }
    case 'emailSignature': {
      return <FroalaEditorView model={value} />;
    }
    case 'projectNumberRegex': {
      return <div>{value}</div>;
    }
    case 'projectDocumentPrefix': {
      return <div>{value}</div>;
    }
  }
};

interface ProjectMailSettingsManagementProps {
  className?: string;
  projectId: ProjectId;
  type: GlobalProjectSettingType;
  initialPreview: string;
}

export const ProjectMailSettingsManagement: React.FC<
  ProjectMailSettingsManagementProps
> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();

  const { className, projectId, type, initialPreview } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [projectMailSetting, setProjectMailSetting] =
    useState<ProjectMailSetting>(null);
  const projectMailSettingRef = useRef<ProjectMailSetting>(projectMailSetting);

  const [hasEdited, setHasEdited] = useState<boolean>(false);

  const [globalProjectSettings, setGlobalProjectSettings] = useState<
    GlobalProjectSetting[]
  >([]);
  const isUpdatingRef = useRef(false);

  const [preview, setPreview] = useState<string>(initialPreview ?? null);

  const [isFetchingProjectSettings, setIsFetchingProjectSettings] =
    useState<boolean>(false);

  const [isFetchingGlobalSettings, setIsFetchingGlobalSettings] =
    useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleChangeProjectSetting = (value: string) => {
    if (
      projectMailSettingRef?.current &&
      !equals(projectMailSettingRef?.current?.objectJson?.value, value)
    ) {
      setHasEdited(true);
      const newValue: ProjectMailSetting = {
        ...projectMailSettingRef?.current,
        objectJson: { value },
        objectType: type,
        globalProjectSettingId:
          globalProjectSettings?.length > 0
            ? globalProjectSettings[0].globalProjectSettingId
            : null,
      };
      setProjectMailSetting(newValue);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setPreview(initialPreview);
  }, [initialPreview]);

  useEffect(() => {
    const fetchProjectMailSetting = async () => {
      setIsFetchingProjectSettings(true);
      const { data } = await apiFetchProjectMailSetting(projectId, type);
      if (data) {
        setProjectMailSetting(data);
      }
      setIsFetchingProjectSettings(false);
    };
    fetchProjectMailSetting();
  }, [projectId, type]);

  useEffect(() => {
    const fetchGlobalProjectMailSetting = async () => {
      setIsFetchingGlobalSettings(true);
      const { data } = await apiFetchGlobalProjectSetting(type);
      if (data) {
        setGlobalProjectSettings(data);
      }
      setIsFetchingGlobalSettings(false);
    };
    fetchGlobalProjectMailSetting();
  }, [type]);

  useEffect(() => {
    const updateSettings = async () => {
      if (
        hasEdited &&
        projectMailSetting &&
        projectMailSetting.objectType != null &&
        !isUpdatingRef.current
      ) {
        isUpdatingRef.current = true;

        try {
          let response;
          if (
            projectMailSetting.projectSettingId !==
            '00000000-0000-0000-0000-000000000000'
          ) {
            response = await apiUpdateProjectMailSetting(
              type,
              projectId,
              projectMailSetting
            );
          } else {
            response = await apiCreateProjectMailSetting(
              type,
              projectId,
              projectMailSetting
            );
          }

          if (response && response.data) {
            const { data: previewData } =
              await apiFetchEmailProjectSettingPreview(projectId, [type]);
            if (previewData) {
              setProjectMailSetting(response.data);
              setPreview(previewData[type]);
            } else {
              notification.open({
                message: t('common:error'),
                description: t('common:errorMessages.saveChangeError'),
              });
            }
          } else {
            notification.open({
              message: t('common:error'),
              description: t('common:errorMessages.saveChangeError'),
            });
          }
        } catch (error) {
          console.error('API-Fehler', error);
          notification.open({
            message: t('common:error'),
            description: t('common:errorMessages.saveChangeError'),
          });
        } finally {
          isUpdatingRef.current = false;
        }
      }
    };

    updateSettings();
  }, [projectId, projectMailSetting, hasEdited, type, t]);

  useEffect(() => {
    projectMailSettingRef.current = projectMailSetting;
  }, [projectMailSetting, projectMailSettingRef]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Typography.Title>
        {t(`projects:projectMailSettingsManagement.labels.${type}`)}
      </Typography.Title>
      <Radio.Group
        className={classes.radioGroup}
        value={projectMailSetting?.useProjectSetting ?? false}
        onChange={(e) => {
          setProjectMailSetting({
            ...projectMailSetting,
            useProjectSetting: e.target.value,
          });
          setHasEdited(true);
        }}
      >
        <Radio value={false} className={classes.radioButton}>
          <Flex.Row>
            <div className={classes.radioLabelCol}>
              {t('projects:projectMailSettingsManagement.labels.globalSetting')}
            </div>
            <Flex.Item flex={1}>
              <Select
                onChange={(value: string) =>
                  setProjectMailSetting({
                    ...projectMailSetting,
                    globalProjectSettingId: value,
                    objectType: type,
                    objectJson: projectMailSetting.objectJson ?? {
                      value: '',
                    },
                  })
                }
                onClick={(e) => e.preventDefault()}
                className={classes.fullWidth}
                disabled={projectMailSetting?.useProjectSetting}
                value={projectMailSetting?.globalProjectSettingId}
              >
                {globalProjectSettings.map((globalSetting) => (
                  <Select.Option
                    value={globalSetting.globalProjectSettingId}
                    key={globalSetting.globalProjectSettingId}
                  >
                    {globalSetting.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Flex.Item>
          </Flex.Row>
        </Radio>
        <Radio value={true} className={classes.radioButton}>
          <Flex.Row>
            <div className={classes.radioLabelCol}>
              {t(
                'projects:projectMailSettingsManagement.labels.projectSetting'
              )}
            </div>
            <Flex.Item flex={1}>
              {type !== 'emailSignature' ? (
                <DebouncedInputSearch
                  className={classes.fullWidth}
                  onClick={(e) => e.preventDefault()}
                  onChange={handleChangeProjectSetting}
                  disabled={!projectMailSetting?.useProjectSetting}
                  value={projectMailSetting?.objectJson?.value}
                />
              ) : isFetchingProjectSettings || isFetchingGlobalSettings ? (
                <Input.TextArea className={classes.fullWidth} disabled />
              ) : (
                <HTMLTextEditor
                  value={projectMailSetting?.objectJson?.value}
                  onChange={handleChangeProjectSetting}
                  disabled={!projectMailSetting?.useProjectSetting}
                  onClick={(e) => e.preventDefault()}
                  className={classNames(classes.editor, {
                    [classes.disabledEditor]:
                      !projectMailSetting?.useProjectSetting,
                  })}
                />
              )}
            </Flex.Item>
          </Flex.Row>
        </Radio>
      </Radio.Group>
      <Collapse ghost className={classes.collapse}>
        <Collapse.Panel
          header={
            <Flex.Row flex={1}>
              <div className={classes.radioLabelCol}>
                {t('projects:projectMailSettingsManagement.preview')}
              </div>

              <Divider className={classes.divider} />
            </Flex.Row>
          }
          key="1"
        >
          <div className={classes.preview}>{previewFunc(type, preview)}</div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default ProjectMailSettingsManagement;
