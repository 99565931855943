import { createContext } from 'react';
import { FilterPickerType } from '../FilterPicker';

export type FilterPickerFilterFunction<T = unknown> = (value: T) => boolean;

export type FilterPickerContextProps<T = unknown> = Partial<
  Record<FilterPickerType, FilterPickerFilterFunction<T>>
>;

const defaultFilterPickerContext: FilterPickerContextProps = {};

const FilterPickerContext = createContext<FilterPickerContextProps>(
  defaultFilterPickerContext
);

export default FilterPickerContext;
