import { Col, Form, Row, Switch } from 'antd';
import { Button, Input, Select } from '@prio365/prio365-react-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EMailSuffix,
  ProjectExtensionId,
  ProjectId,
} from '../../../../../../models/Types';
import { colon } from '../../../../../../util/forms';
import {
  getEmailprefix,
  getEmailSuffixIndex,
} from '../../../../../../util/helpers';
import { apiFetchEmailSuffixes } from '../../../../../settings/api';
import { makePrioStyles } from '../../../../../../theme/utils';
import InformationBox from '../../../../../../components/InformationBox';
import { apiGetProjectExtensionSuggestion } from '../../../../api';
import {
  CreateProjectExtension,
  GetProjectExtensionSuggestion,
  ProjectExtension,
  ProjectExtensionSuggestion,
  UpdateProjectExtension,
} from '../../../../../../models/ProjectExtension';
import Flex from '../../../../../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {
    minHeight: '100px',
    overflow: 'hidden',
  },

  eMailSuffixSelect: {
    '&:before': {
      content: '"@"',
      display: 'inline-block',
      position: 'absolute',
      left: -22,
      top: 3,
      color: theme.old.typography.colors.muted,
    },
  },
  atSign: {
    textAlign: 'center',
    paddingTop: theme.spacing.large,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  success: {
    backgroundColor: theme.colors.base.green.default,
  },
  warning: {
    backgroundColor: theme.colors.base.yellow.default,
  },
  submitButton: {
    position: 'absolute',
    bottom: '32px',
  },
}));

interface SharedMailboxProjectExtensionFormProps {
  projectExtension: ProjectExtension;
  addProjectExtension: CreateProjectExtension;
  projectId: ProjectId;
  updateProjectExtension: (
    projectId: ProjectId,
    projectExtensionId: ProjectExtensionId,
    projectExtension: UpdateProjectExtension
  ) => Promise<ProjectExtension>;
  createProjectExtension: (
    projectId: ProjectId,
    createProjectExtension: CreateProjectExtension
  ) => Promise<ProjectExtension>;
}

export interface SharedMailboxProjectExtensionFormModel {
  displayName: '';
  email: '';
  hasDefaultAccess: true;
  projectId: ProjectId;
}

export const SharedMailboxProjectExtensionForm: React.FC<
  SharedMailboxProjectExtensionFormProps
> = (props) => {
  const {
    projectExtension,
    addProjectExtension,
    projectId,
    updateProjectExtension,
    createProjectExtension,
  } = props;
  const classes = useStyles();

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formSuggestion] = Form.useForm();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [projectExtensionSuggestion, setProjectExtensionSuggestion] =
    useState<ProjectExtensionSuggestion>(null);
  // suggestion Error
  const [loading, setLoading] = useState<boolean>(false);
  const [emailSuffixes, setEmailSuffixes] = useState<EMailSuffix[]>([]);
  const [sharedMailboxForm] = useState<SharedMailboxProjectExtensionFormModel>({
    displayName: '',
    email: '',
    hasDefaultAccess: true,
    projectId: projectId,
  });

  // on effect projectExtension set form
  useEffect(() => {
    if (projectExtension) {
      form.setFieldsValue({
        displayName: projectExtension?.displayName,
      });
    }
  }, [form, projectExtension]);

  useEffect(() => {
    setDisabled(true);

    const loadEmailSuffixes = async () => {
      const { data } = await apiFetchEmailSuffixes();
      if (data) {
        setEmailSuffixes(data);
        if (data?.length > 0) {
          if (!sharedMailboxForm?.email) {
            form.setFieldsValue({
              eMailSuffix: data[0],
            });
          } else {
            form.setFieldsValue({
              eMailSuffix:
                data[getEmailSuffixIndex(sharedMailboxForm.email, data)],
            });
          }
        }
      }
    };
    loadEmailSuffixes();
    if (sharedMailboxForm?.email) {
      form.setFieldsValue({
        eMailPrefix: getEmailprefix(sharedMailboxForm.email),
      });
      form.setFieldsValue({
        displayName: sharedMailboxForm.displayName,
      });
    }
    setDisabled(false);
  }, [form, sharedMailboxForm]);

  const saveOrUpdateExtension = async (
    formModel: SharedMailboxProjectExtensionFormModel
  ) => {
    setLoading(true);
    if (projectExtension && !addProjectExtension) {
      await updateProjectExtension(
        projectId,
        projectExtension?.projectExtensionId,
        {
          displayName: formModel.displayName,
        }
      );
    } else if (addProjectExtension && !projectExtension) {
      await createProjectExtension(projectId, {
        displayName: formModel.displayName,
        projectExtensionType: 'sharedMailbox',
        value: projectExtensionSuggestion?.createProjectExtensionDto?.value,
        hasDefaultAccess: formModel.hasDefaultAccess ?? true,
      });
    }
    setLoading(false);
  };

  const getMailSuggestions = async (values) => {
    setLoading(true);
    let getProjectExtensionSuggestion: GetProjectExtensionSuggestion = {
      key: null,
      value: values.eMailPrefix + '@' + values.eMailSuffix,
      projectExtensionType: 'sharedMailbox',
    };

    const { data } = await apiGetProjectExtensionSuggestion(
      projectId,
      getProjectExtensionSuggestion
    );
    if (data) {
      setProjectExtensionSuggestion(data);
    }
    setLoading(false);
  };
  return (
    <>
      {projectExtension !== null ? (
        <>
          <p>
            {t('projects:addIn.extensions.sharedMailbox.form.email')}
            {': '}
            {projectExtension?.value}
          </p>

          <InformationBox
            description={t(
              'projects:addIn.extensions.sharedMailbox.editDescriptionMail'
            )}
          />
          <Form
            form={form}
            layout="vertical"
            className={classes.root}
            initialValues={sharedMailboxForm}
            onFinish={(values) => {
              saveOrUpdateExtension(values);
            }}
            onValuesChange={(_, values) => {
              //nothingToDo
            }}
          >
            <Form.Item
              name="displayName"
              colon={colon}
              validateTrigger="onBlur"
              label={t(
                'projects:addIn.extensions.sharedMailbox.form.displayName'
              )}
              rules={[
                {
                  required: true,
                  message: t('projects:form.validation.missingDisplayName'),
                },
              ]}
            >
              <Input
                disabled={loading || disabled}
                full
                value={projectExtension?.displayName}
              />
            </Form.Item>
            <InformationBox
              description={t(
                'projects:addIn.extensions.sharedMailbox.displayNameDescription'
              )}
            />

            <Button htmlType="submit" disabled={disabled || loading}>
              {t('projects:projectCreation.save')}
            </Button>
          </Form>
        </>
      ) : (
        <>
          <Form
            form={formSuggestion}
            layout="vertical"
            //className={classes.root}
            //initialValues={}
            onFinish={(values) => {
              getMailSuggestions(values);
            }}
            onValuesChange={(_, values) => {
              setProjectExtensionSuggestion(null);
            }}
          >
            <Row>
              <Col span={11}>
                <Form.Item
                  name="eMailPrefix"
                  label={t('projects:form.labels.eMailPrefix')}
                  validateTrigger="onBlur"
                  colon={colon}
                  rules={[
                    {
                      required: true,
                      message: t('projects:form.validation.missingEmailPrefix'),
                    },
                  ]}
                >
                  <Input disabled={disabled || loading} size="default" full />
                </Form.Item>
              </Col>
              <Col span={1}>
                <p className={classes.atSign}>@</p>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="eMailSuffix"
                  label={t('projects:form.labels.eMailSuffix')}
                  colon={colon}
                  validateTrigger="onChange"
                  rules={[
                    {
                      required: true,
                      message: t('projects:form.validation.missingEmailSuffix'),
                    },
                  ]}
                >
                  <Select
                    size="default"
                    showSearch={false}
                    disabled={loading || disabled}
                  >
                    {emailSuffixes.map((emailSuffix) => (
                      <Select.Option value={emailSuffix} key={emailSuffix}>
                        {emailSuffix}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={2}>
                <div className={classes.container}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    className={
                      projectExtensionSuggestion
                        ? projectExtensionSuggestion.existingProjectExtensions
                            ?.length > 0 ||
                          projectExtensionSuggestion.errorString !== null
                          ? classes.warning
                          : classes.success
                        : ''
                    }
                    disabled={loading}
                    loading={loading}
                    iconProp={[
                      'fal',
                      projectExtensionSuggestion
                        ? projectExtensionSuggestion.existingProjectExtensions
                            ?.length > 0 ||
                          projectExtensionSuggestion.errorString !== null
                          ? 'triangle-exclamation'
                          : 'check'
                        : 'magnifying-glass',
                    ]}
                  />
                </div>
              </Col>
            </Row>
            {projectExtensionSuggestion === null && (
              <InformationBox
                description={t(
                  'projects:addIn.extensions.sharedMailbox.suggestionDescription'
                )}
              />
            )}
            {projectExtensionSuggestion?.existingProjectExtensions?.length >
              0 && (
              <InformationBox
                description={t(
                  'projects:addIn.extensions.sharedMailbox.alreadyExistsAsAddIn'
                )}
                icon="triangle-exclamation"
              />
            )}
            {projectExtensionSuggestion !== null &&
              projectExtensionSuggestion?.createProjectExtensionDto !==
                null && (
                <>
                  <InformationBox
                    description={t(
                      'projects:addIn.extensions.sharedMailbox.readyToCreate'
                    )}
                    icon="check"
                  />
                </>
              )}
            {projectExtensionSuggestion !== null &&
              projectExtensionSuggestion.errorString !== null &&
              projectExtensionSuggestion?.existingProjectExtensions?.length ===
                0 && (
                <>
                  <InformationBox
                    description={t(
                      'projects:addIn.extensions.sharedMailbox.mailExistsOrInvalid'
                    )}
                    icon="triangle-exclamation"
                  />
                </>
              )}
          </Form>
          {projectExtensionSuggestion !== null &&
            projectExtensionSuggestion?.createProjectExtensionDto !== null && (
              <>
                <Form
                  form={form}
                  layout="vertical"
                  className={classes.root}
                  initialValues={sharedMailboxForm}
                  onFinish={(values) => {
                    saveOrUpdateExtension(values);
                  }}
                  onValuesChange={(_, values) => {
                    //nothingToDo
                  }}
                >
                  <Form.Item
                    name="displayName"
                    colon={colon}
                    validateTrigger="onBlur"
                    label={t(
                      'projects:addIn.extensions.sharedMailbox.form.displayName'
                    )}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'projects:form.validation.missingDisplayName'
                        ),
                      },
                    ]}
                  >
                    <Input disabled={loading || disabled} full />
                  </Form.Item>
                  <InformationBox
                    description={t(
                      'projects:addIn.extensions.sharedMailbox.displayNameDescription'
                    )}
                  />
                  <Form.Item
                    name="hasDefaultAccess"
                    colon={colon}
                    required={true}
                    label={t('projects:addIn.extensions.form.hasDefaultAccess')}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'projects:form.validation.hasDefaultAccessRequired'
                        ),
                      },
                    ]}
                    valuePropName="checked"
                  >
                    <Switch disabled={loading || disabled} />
                  </Form.Item>
                  <InformationBox
                    description={t(
                      'projects:addIn.extensions.sharedMailbox.hasDefaultAccessDescription'
                    )}
                  />
                  <Flex.Row flex={1} justifyContent="flex-end">
                    <Button
                      htmlType="submit"
                      disabled={disabled || loading}
                      className={classes.submitButton}
                    >
                      {t('projects:projectCreation.save')}
                    </Button>
                  </Flex.Row>
                </Form>
              </>
            )}
        </>
      )}
    </>
  );
};

export default SharedMailboxProjectExtensionForm;
