import { createContext } from 'react';
import {
  GlobalRole,
  OfficeId,
  OfficeRole,
  ProjectId,
  ProjectRole,
} from '../../../models/Types';

export declare type CheckProjectRolesMethod<Id = string> = (
  roles: ProjectRole[],
  id?: Id,
  xor?: boolean
) => boolean;

export declare type CheckOfficeRolesMethod<Id = string> = (
  roles: OfficeRole[],
  id?: Id,
  xor?: boolean
) => boolean;

export declare type CheckGlobalRoles = (
  roles: GlobalRole[],
  xor?: boolean
) => boolean;

export interface AccessRightsContextProps {
  //projectRoles
  checkProjectRolesOfProjectId: CheckProjectRolesMethod<ProjectId>;
  checkProjectRolesOfOfficeId: CheckProjectRolesMethod<OfficeId>;
  checkProjectRolesOfCompanyId: CheckProjectRolesMethod<OfficeId>;
  checkProjectRoles: (roles: ProjectRole[], xor?: boolean) => boolean;
  //officeRoles
  checkOfficeRolesOfOfficeId: CheckOfficeRolesMethod<OfficeId>;
  checkOfficeRolesOfCompanyId: CheckOfficeRolesMethod<OfficeId>;
  checkOfficeRolesOfProjectId: CheckOfficeRolesMethod<ProjectId>;
  checkOfficeRoles: (roles: OfficeRole[], xor?: boolean) => boolean;
  //globalRoles
  checkGlobalRoles: CheckGlobalRoles;
}

const initialValue: AccessRightsContextProps = {
  checkProjectRolesOfProjectId: () => false,
  checkProjectRolesOfOfficeId: () => false,
  checkProjectRolesOfCompanyId: () => false,
  checkProjectRoles: () => false,
  checkOfficeRolesOfOfficeId: () => false,
  checkOfficeRolesOfCompanyId: () => false,
  checkOfficeRolesOfProjectId: () => false,
  checkOfficeRoles: () => false,
  checkGlobalRoles: () => false,
};

const AccessRightsContext = createContext(initialValue);

export default AccessRightsContext;
