import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import { PrioTheme } from '../../../../theme/types';
import { Drawer, Steps } from '@prio365/prio365-react-library';
import FilterContextProvider from '../../../../components/Filter/FilterContextProvider';
import { DefaultSearchParameterItem } from '../../../../components/Filter/types';
import {
  ContactCompanyData,
  ContactCompanysCalculatedData,
} from '../../../../models/Contact';
import { ProjectId } from '../../../../models/Types';
import AddInternalContactDrawerUserSelectStep from './AddInternalContactDrawerUserSelectStep';
import AddInternalContactDrawerUserConfigStep from './AddInternalContactDrawerUserConfigStep';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../../apps/main/rootReducer';
import { useTranslation } from 'react-i18next';
import AddInternalContactsDrawerContextProvider from './AddInternalContactsDrawerContextProvider';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import {
  ProjectMember,
  ProjectMemberCalculatedData,
} from '../../../../models/ProjectContacts';

interface AddInternalContactDrawerProps {
  className?: string;
  projectId: ProjectId;
  drawerVisible: boolean;
  setDrawerVisible: (visible: boolean) => void;
}

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
}));

export const AddInternalContactDrawer: React.FC<
  AddInternalContactDrawerProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, projectId, drawerVisible, setDrawerVisible } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { data } = useFilterContext<
    ProjectMember,
    ProjectMemberCalculatedData
  >();

  const existingProjectMembersContactIds = useMemo(
    () => (data?.items ?? [])?.map((contact) => contact.data.contactId),
    [data]
  );

  const userMe = useSelector(getUserMe);
  const [currentStep, setCurrentStep] = useState<number>(0);

  const customDefaultSearchParameters: DefaultSearchParameterItem[] = useMemo(
    () => [
      {
        parameterName: 'Calculated.InternalExternal',
        defaultValue: 'Internal',
        defaultMethod: 'eq',
      },
      {
        parameterName: 'Calculated.CompanyContact',
        defaultValue: 'Contact',
        defaultMethod: 'eq',
      },
      {
        parameterName: 'Data.OfficeId',
        defaultMethod: 'eq',
        defaultValue: userMe?.myOfficeId,
      },
      {
        parameterName: 'Data.IsArchived',
        defaultMethod: 'eq',
        defaultValue: 'false',
      },
    ],
    [userMe]
  );

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnClose = () => {
    setDrawerVisible(false);
    setCurrentStep(0);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  //#region ------------------------------ Render
  const renderContent = useCallback(() => {
    if (currentStep === 0) {
      return (
        <FilterContextProvider<
          ContactCompanyData,
          ContactCompanysCalculatedData
        >
          searchType="companyContacts"
          equalityFunction={({ calculated: a }, { calculated: b }) =>
            a.name === b.name
          }
          customDefaultSearchParameters={customDefaultSearchParameters}
          disableURLQuery
        >
          <AddInternalContactDrawerUserSelectStep
            setDrawerVisible={setDrawerVisible}
            setCurrentStep={setCurrentStep}
            existingProjectMembersContactIds={existingProjectMembersContactIds}
          />
        </FilterContextProvider>
      );
    }
    return (
      <AddInternalContactDrawerUserConfigStep
        setDrawerVisible={setDrawerVisible}
        setCurrentStep={setCurrentStep}
        projectId={projectId}
      />
    );
  }, [
    currentStep,
    setDrawerVisible,
    projectId,
    customDefaultSearchParameters,
    existingProjectMembersContactIds,
  ]);
  //#endregion

  return (
    <Drawer
      className={classNames(classes.root, className)}
      title={t('contacts:addInternalContactDrawer.title')}
      headerPosition={'left'}
      visible={drawerVisible}
      width="large"
      closable={false}
      onClose={handleOnClose}
      subheader={
        <Steps
          direction="vertical"
          items={[
            {
              title: t('contacts:addInternalContactDrawer.step.select'),
              tailContent: 'tail content',
              iconProp: ['fal', 'search'],
              stepNumber: 0,
            },
            {
              title: t('contacts:addInternalContactDrawer.step.configure'),
              iconProp: ['fal', 'gear'],
              stepNumber: 1,
            },
          ]}
          current={currentStep}
          showPointerOnHover={false}
        />
      }
    >
      <AddInternalContactsDrawerContextProvider>
        {renderContent()}
      </AddInternalContactsDrawerContextProvider>
    </Drawer>
  );
};

export default AddInternalContactDrawer;
