import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContactImportAction,
  ExternalCompanyImport,
  ExternalContactImport,
  ExternalOfficeImport,
} from '../../../../../models/ContactImportProcess';
import { makePrioStyles } from '../../../../../theme/utils';
import {
  Button,
  Drawer,
  Input,
  Select,
  Tabs,
} from '@prio365/prio365-react-library';
import { Form, List, Switch } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const useStyles = makePrioStyles((theme) => ({
  drawer: {
    zIndex: 999,
    padding: 0,
  },
  listContainer: {
    marginBottom: theme.spacing.regular,
  },
  formItem: {
    marginBottom: theme.spacing.regular,
    width: '100%',
  },
  switchItem: {
    marginBottom: theme.spacing.regular,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.regular,
  },
}));

interface ExternalCompanyDrawerProps {
  visible: boolean;
  onClose: () => void;
  selectedCompany: ExternalCompanyImport | null;
  office: ExternalOfficeImport | null;
  contacts: ExternalContactImport[];
  onSave: (
    company: ExternalCompanyImport,
    office: ExternalOfficeImport
  ) => void;
}

const ExternalCompanyDrawer: React.FC<ExternalCompanyDrawerProps> = ({
  visible,
  onClose,
  selectedCompany,
  office,
  contacts,
  onSave,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState('company');
  const [form] = Form.useForm();
  const [companyData, setCompanyData] = useState<ExternalCompanyImport | null>(
    selectedCompany
  );
  const [officeData, setOfficeData] = useState<ExternalOfficeImport | null>(
    office
  );

  useEffect(() => {
    setCompanyData(selectedCompany);
    setOfficeData(office);
    form.setFieldsValue({
      fullName: selectedCompany?.fullName,
      shortName: selectedCompany?.shortName,
      description: selectedCompany?.description,
      website: selectedCompany?.website,
      notes: selectedCompany?.notes,
      commercialRegisterNumber: selectedCompany?.commercialRegisterNumber,
      commercialRegisterCity: selectedCompany?.commercialRegisterCity,
      legalForm: selectedCompany?.legalForm,
      vatNumber: selectedCompany?.vatNumber,
      isClient: selectedCompany?.isClient,
      isServiceProvider: selectedCompany?.isServiceProvider,
      phone: office?.phone,
      email: office?.email,
      street: office?.street,
      city: office?.city,
      zip: office?.zip,
      countryCode: office?.countryCode,
      isMainOffice: office?.isMainOffice,
      action: selectedCompany?.action,
    });
  }, [selectedCompany, office, form]);

  const handleFinish = (values: any) => {
    const updatedCompany = {
      ...companyData,
      ...values,
    } as ExternalCompanyImport;
    const updatedOffice = { ...officeData, ...values } as ExternalOfficeImport;
    onSave(updatedCompany, updatedOffice);
    onClose(); // Close the drawer after saving
  };

  const contactImportActionOptions = [
    {
      value: ContactImportAction.NotSet,
      label: t('settings:companyImport.drawerActions.notSet'),
    },
    {
      value: ContactImportAction.Skip,
      label: t('settings:companyImport.drawerActions.skip'),
    },
    {
      value: ContactImportAction.UpdateMissing,
      label: t('settings:companyImport.drawerActions.updateMissing'),
    },
    {
      value: ContactImportAction.OverrideExisting,
      label: t('settings:companyImport.drawerActions.overrideExisting'),
    },
    {
      value: ContactImportAction.Create,
      label: t('settings:companyImport.drawerActions.create'),
    },
  ];

  const renderTabs = () => [
    {
      label: t('settings:contactImporter.tabs.company'),
      key: 'company',
      content: (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          initialValues={{
            fullName: companyData?.fullName,
            shortName: companyData?.shortName,
            description: companyData?.description,
            website: companyData?.website,
            notes: companyData?.notes,
            commercialRegisterNumber: companyData?.commercialRegisterNumber,
            commercialRegisterCity: companyData?.commercialRegisterCity,
            legalForm: companyData?.legalForm,
            vatNumber: companyData?.vatNumber,
            isClient: companyData?.isClient,
            isServiceProvider: companyData?.isServiceProvider,
            phone: officeData?.phone,
            email: officeData?.email,
            street: officeData?.street,
            city: officeData?.city,
            zip: officeData?.zip,
            countryCode: officeData?.countryCode,
            isMainOffice: officeData?.isMainOffice,
            action: companyData?.action,
          }}
        >
          {/* Company and Office Fields */}
          <Form.Item
            name="fullName"
            label={t('settings:companyImport.table.fullName')}
            rules={[
              { required: true, message: t('common:validation.required') },
            ]}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="shortName"
            label={t('settings:companyImport.table.shortName')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="description"
            label={t('settings:companyImport.table.description')}
            className={classes.formItem}
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="website"
            label={t('settings:companyImport.table.website')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="notes"
            label={t('settings:companyImport.table.notes')}
            className={classes.formItem}
          >
            <TextArea rows={2} />
          </Form.Item>

          <Form.Item
            name="commercialRegisterNumber"
            label={t('settings:companyImport.table.commercialRegisterNumber')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="commercialRegisterCity"
            label={t('settings:companyImport.table.commercialRegisterCity')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="legalForm"
            label={t('settings:companyImport.table.legalForm')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="vatNumber"
            label={t('settings:companyImport.table.vatNumber')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          {/* Switch for Client/Service Provider */}
          <Form.Item
            name="isClient"
            label={t('settings:companyImport.table.isClient')}
            valuePropName="checked"
            className={classes.switchItem}
          >
            <Switch />
          </Form.Item>

          <Form.Item
            name="isServiceProvider"
            label={t('settings:companyImport.table.isServiceProvider')}
            valuePropName="checked"
            className={classes.switchItem}
          >
            <Switch />
          </Form.Item>

          {/* Office Fields */}
          <Form.Item
            name="phone"
            label={t('settings:companyImport.table.phone')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="email"
            label={t('settings:companyImport.table.email')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="street"
            label={t('settings:companyImport.table.street')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="city"
            label={t('settings:companyImport.table.city')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="zip"
            label={t('settings:companyImport.table.zip')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="countryCode"
            label={t('settings:companyImport.table.countryCode')}
            className={classes.formItem}
          >
            <Input full />
          </Form.Item>

          <Form.Item
            name="isMainOffice"
            label={t('settings:companyImport.table.isMainOffice')}
            valuePropName="checked"
            className={classes.switchItem}
          >
            <Switch />
          </Form.Item>

          <Form.Item
            name="action"
            label={t('settings:companyImport.table.action')}
            className={classes.formItem}
          >
            <Select
              options={contactImportActionOptions}
              defaultValue={ContactImportAction.NotSet} // Optional: default selection
            />
          </Form.Item>

          <div className={classes.buttonContainer}>
            <Button type="primary" htmlType="submit">
              {t('common:actions.save')}
            </Button>
            <Button style={{ marginLeft: 8 }} onClick={onClose}>
              {t('common:actions.cancel')}
            </Button>
          </div>
        </Form>
      ),
    },
    {
      label: t('settings:contactImporter.tabs.contacts'),
      key: 'contacts',
      content: (
        <div className={classes.listContainer}>
          <List
            itemLayout="horizontal"
            dataSource={contacts.filter(
              (contact) =>
                contact.externalCompanyImportId ===
                selectedCompany?.externalCompanyImportId
            )}
            renderItem={(contact: ExternalContactImport) => (
              <List.Item>
                <List.Item.Meta
                  title={`${contact.firstName} ${contact.lastName}`}
                  description={`E-Mail:${contact.email}${contact.department}, ${contact.position}`}
                />
              </List.Item>
            )}
          />
        </div>
      ),
    },
  ];

  return (
    <Drawer
      title={companyData?.fullName || t('settings:companyImport.drawer.title')}
      onClose={onClose}
      visible={visible}
      width={'default'}
    >
      <Tabs
        contentClassName="fullHeightContactImporter"
        defaultTabKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        items={renderTabs()}
      />
    </Drawer>
  );
};

export default ExternalCompanyDrawer;
