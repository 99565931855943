import {
  Input,
  SelectFilter,
  SelectFilterOption,
} from '@prio365/prio365-react-library';
import {} from 'antd';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../../../components/Flex';
import { makePrioStyles } from '../../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  switchesContainer: {
    background: 'white',
    padding: '16px',
    borderRadius: '4px',
    margin: '32px 0 16px 0',
  },
  selectComponent: {
    minWidth: '400px',
    maxWidth: '400px',
  },
}));

export interface ContactImportFilterOption {
  label: ReactElement;
  searchValue: string;
  value: string;
  active: boolean;
}
interface ContactImporterFilterBarProps {
  className?: string;
  options?: SelectFilterOption[];
  filterActive: boolean;
  filterPlaceholder?: string;
  filterSelectHandler?: (any) => void;
  searchChangeHandler?: (any) => void;
}

export const ContactImporterFilterBar: React.FC<
  ContactImporterFilterBarProps
> = (props) => {
  //#region ------------------------------ Defaults dd
  const {
    filterActive,
    filterPlaceholder,
    options,
    filterSelectHandler,
    searchChangeHandler,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row
      className={classes.switchesContainer}
      alignItems="center"
      justifyContent="start"
      childrenGap={30}
    >
      <Input
        size="small"
        placeholder={t('searchPlaceHolder')}
        prefixIcon={['fal', 'magnifying-glass']}
        onChange={searchChangeHandler}
      />
      {filterActive && (
        <Flex.Row alignItems="center" childrenGap={20}>
          <SelectFilter
            options={options}
            onChange={filterSelectHandler}
            useTriggerButton={true}
            triggerButtonPlaceholder={filterPlaceholder}
          />
        </Flex.Row>
      )}
    </Flex.Row>
  );
};

export default ContactImporterFilterBar;
