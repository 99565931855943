import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { notification, Typography } from 'antd';
import {
  Button,
  Popover,
  Toggle,
  useTheme,
} from '@prio365/prio365-react-library';
import TextArea from 'antd/lib/input/TextArea';
import { PrioTheme } from '../../../theme/types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveDraftMessageId,
  getContactsByIdState,
  getMessage,
  getUserMeContactId,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import UserAvatar from '../../../components/UserAvatar';
import { MessageId, ProjectId } from '../../../models/Types';
import {
  apiGetLastAiEmailResponse,
  apiResetConversation,
  sendChatMessage,
} from '../api';
import { Contact } from '../../../models/Contact';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { fetchMessage } from '../../mail/actions/actionControllers/messageActionController';
import Flex from '../../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  messagesContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing.regular,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.regular,
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
    '&:hover $messageActions': {
      display: 'flex',
    },
  },
  messageBox: {
    alignItems: 'flex-start',
    padding: theme.spacing.regular,
    borderRadius: '0px 8px 8px 8px',
    width: '100%',
    fontSize: '14px',
    maxWidth: '91%',
    overflowWrap: 'break-word',
  },
  messageBoxUser: {
    backgroundColor: theme.colors.base.blue[30],
    borderRadius: '8px 0px 8px 8px',
    marginLeft: theme.spacing.large,
    marginRight: '0px',
  },
  messageBoxBuddy: {
    backgroundColor: '#EEF3FA',
    borderRadius: '0px 8px 8px 8px',
    marginLeft: '0px',
    marginRight: theme.spacing.large,
  },
  dateText: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.colors.application.typography.default,
  },
  dateTextUser: {
    textAlign: 'left',
    marginLeft: theme.spacing.large,
    paddingLeft: theme.spacing.small,
  },
  dateTextBuddy: {
    textAlign: 'right',
    marginRight: theme.spacing.large,
    paddingRight: theme.spacing.small,
  },

  messageFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '24px',
  },

  chatTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'right',
    padding: theme.spacing.regular,
  },

  prioBuddyTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.small,
  },

  messageActions: {
    display: 'none',
    justifyContent: 'flex-start',
    marginRight: theme.spacing.large,
  },

  messageContainerHover: {
    '&:hover $messageActions': {
      display: 'flex',
    },
  },

  newMessageContainer: {
    display: 'flex',
    padding: theme.spacing.regular,
    flexDirection: 'column',
    gap: theme.spacing.small,
  },
  textAreaContainer: {
    display: 'flex',
    backgroundColor: '#fff',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing.small,
  },
  textAreaField: {
    padding: theme.spacing.small,
    height: '116px',
    border: '1px solid #E0E6EE',
    borderRadius: '2px',
    background: '#FFF',
    width: '100%',
    ' & .ant-input': {
      color: theme.colors.application.typography.default,
    },
  },
  lastResponseLabel: {
    color: theme.colors.application.typography.default,
    fontSize: theme.font.fontSize.extraSmall,
  },
  titleDraftQuickActions: {
    color: theme.colors.application.typography.default,
    fontSize: theme.font.fontSize.extraSmall,
    fontWeight: theme.font.fontWeight.bold,
  },
}));

interface PrioBuddyProps {
  messageId?: string;
  projectId: ProjectId;
}

interface ChatMessage {
  sender: string;
  text: string;
  createdAt: string;
}

type QuickActionTypes = 'spelling' | 'structure' | 'formal' | 'informal';

const PrioBuddy: React.FC<PrioBuddyProps> = (props) => {
  //#region ------------------------------ Defaults
  const { messageId, projectId } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userId = useSelector(getUserMeContactId);
  const contactsById = useSelector(getContactsByIdState);
  const activeDraftMessageId = useSelector<RootReducerState, MessageId>(
    (state) => getActiveDraftMessageId(state, projectId)
  );

  const draftSourceMessageId = useSelector<RootReducerState, string>((state) =>
    activeDraftMessageId
      ? getMessage(state, projectId, activeDraftMessageId)
          ?.customSingleValueExtendedProperties?.originMessageId
      : null
  );
  const newMail = !draftSourceMessageId && activeDraftMessageId;
  const isNewMail = !!newMail;
  const draftFolder = !draftSourceMessageId && !activeDraftMessageId;
  const messageIdentifier = isNewMail
    ? activeDraftMessageId
    : draftFolder
    ? messageId
    : draftSourceMessageId;
  const [isDraftIncluded, setIsDraftIncluded] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [userMessage, setUserMessage] = useState('');
  const [hasLastAiResponse, setHasLastAiResponse] = useState(false);
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>([
    {
      sender: 'bot',
      text: t('common:widgetArea.chat.hello', {
        name: contactsById[userId]?.firstName,
      }),
      createdAt: new Date().toISOString(),
    },
  ]);
  const lastMessageRef = useRef<HTMLDivElement | null>(null);
  const KI: Contact = {
    contactId: 'PrioBuddy',
    firstName: 'PrioBuddy',
    lastName: '',
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleCopy = (message) => {
    navigator.clipboard.writeText(message.text).then(() => {
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 1500);
    });
  };

  const scrollToLastMessage = () => {
    lastMessageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  const formatDate = (dateString) => {
    if (!dateString.endsWith('Z')) {
      dateString += 'Z';
    }
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} | ${date
      .toLocaleTimeString()
      .slice(0, -3)}`;
  };

  const printToChat = (message: string, type: 'user' | 'bot') => {
    if (type === 'user') {
      setChatHistory((prev) => [
        ...prev,
        { sender: 'user', text: message, createdAt: new Date().toISOString() },
      ]);
      return;
    }

    setChatHistory((prev) => [
      ...prev,
      { sender: 'bot', text: message, createdAt: new Date().toISOString() },
    ]);
  };

  const handleSendMessage = async () => {
    if (userMessage.trim() === '') return;
    setIsSending(true);
    printToChat(userMessage, 'user');
    setUserMessage('');

    const type = isNewMail ? 'NewEmailAi' : 'EmailResponseAi';
    const { data } = await sendChatMessage(
      userMessage,
      type,
      messageIdentifier,
      isDraftIncluded,
      projectId
    );

    printToChat(data, 'bot');
    scrollToLastMessage();
    setIsSending(false);
  };

  const loadInitialMessagesAndLastAiResponse = useCallback(async () => {
    const { data } = await apiGetLastAiEmailResponse(messageIdentifier);
    if (data && typeof data === 'string') {
      setChatHistory([
        { sender: 'bot', text: data, createdAt: new Date().toISOString() },
      ]);
      setHasLastAiResponse(true);
    } else {
      setChatHistory([
        {
          sender: 'bot',
          text: t('common:widgetArea.chat.hello', {
            name: contactsById[userId]?.firstName,
          }),
          createdAt: new Date().toISOString(),
        },
      ]);
      setHasLastAiResponse(false);
    }
    scrollToLastMessage();
  }, [messageIdentifier, contactsById, userId, t]);

  const resetConversation = async () => {
    const result = await apiResetConversation(messageIdentifier);
    if (result.result.status === 200) {
      setHasLastAiResponse(false);
      setChatHistory([
        {
          sender: 'bot',
          text: t('common:widgetArea.chat.hello', {
            name: contactsById[userId]?.firstName,
          }),
          createdAt: new Date().toISOString(),
        },
      ]);
    } else {
      notification.error({
        message: t('common:widgetArea.chat.resetChatError'),
      });
    }
  };

  const handleQuickAction = async (action: QuickActionTypes) => {
    const userMessage = t(
      `common:widgetArea.chat.quickActions.${action}Message`
    );
    setIsSending(true);
    printToChat(userMessage, 'user');
    const { data } = await sendChatMessage(
      userMessage,
      'NewEmailAi',
      messageIdentifier,
      true,
      projectId
    );

    printToChat(data, 'bot');
    setIsSending(false);
  };
  // #endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (messageIdentifier) loadInitialMessagesAndLastAiResponse();
  }, [messageIdentifier, loadInitialMessagesAndLastAiResponse]);

  useEffect(() => {
    lastMessageRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [chatHistory, t]);

  useEffect(() => {
    if (activeDraftMessageId)
      dispatch(fetchMessage(projectId, activeDraftMessageId));
  }, [activeDraftMessageId, dispatch, projectId]);
  //#endregion

  return (
    <div className={classes.root}>
      <div className={classes.chatTitle}>
        <div className={classes.prioBuddyTitle}>
          <UserAvatar
            contact={KI}
            backgroundColor="#1a2f41"
            size="medium"
            icon={'message-bot' as IconName}
          />
          <Typography.Title
            level={3}
            style={{
              color: theme.colors.application.typography.default,
              margin: 0,
            }}
          >
            {t('common:widgetArea.prioBuddy')}
          </Typography.Title>
        </div>
        {(chatHistory.length > 1 || hasLastAiResponse) && (
          <Button
            type="default"
            tooltipPosition="left"
            tooltip={t('common:widgetArea.chat.resetChat')}
            iconProp={['fal', 'trash']}
            onClick={resetConversation}
            style={{
              cursor: 'pointer',
              color: theme.colors.base.red.default,
            }}
          ></Button>
        )}
      </div>
      <div className={classes.messagesContainer}>
        {hasLastAiResponse && (
          <div className={classes.lastResponseLabel}>
            {t('common:widgetArea.chat.lastPrioBuddyResponse')}
          </div>
        )}
        {chatHistory?.map((message, index) => {
          const isLastMessage = index === chatHistory?.length - 1;
          const user = contactsById[userId];
          const creater = chatHistory[index]?.sender === 'user' ? user : KI;
          const isUser = creater === user;
          const messageBoxClass = isUser
            ? classes.messageBoxUser
            : classes.messageBoxBuddy;
          const dateTextClass = isUser
            ? classes.dateTextUser
            : classes.dateTextBuddy;

          const title = (
            <div>
              {!isUser && (hasLastAiResponse ? true : index > 1) && (
                <div className={classes.messageActions}>
                  <Button
                    type="default"
                    size="small"
                    iconProp={copied ? ['fal', 'check'] : ['fal', 'copy']}
                    style={{
                      paddingLeft: theme.spacing.small,
                      cursor: 'pointer',
                      color: copied ? '#4CAF50' : '#0052CC',
                      transition: 'color 0.3s ease',
                    }}
                    onClick={() => handleCopy(message)}
                    tooltip={t('common:widgetArea.chat.messageActions.copy')}
                  />
                  <Button
                    type="default"
                    size="small"
                    iconProp={['fal', 'message-arrow-down']}
                    style={{
                      padding: theme.spacing.small,
                      display: 'none', // vorerst nicht anzeigen
                      cursor: 'pointer',
                      color: '#0052CC',
                    }}
                    onClick={() => {}}
                    tooltip=""
                  />
                </div>
              )}
            </div>
          );

          return (
            <div
              className={`${classes.messageContainer} ${classes.messageContainerHover}`}
            >
              <div
                key={index}
                ref={isLastMessage ? lastMessageRef : null}
                className={`${classes.messageBox} ${messageBoxClass}`}
              >
                <div
                  style={{
                    color: theme.colors.application.typography.default,
                    whiteSpace: 'pre-wrap',
                  }}
                  dangerouslySetInnerHTML={{ __html: message.text }}
                />
              </div>
              <div className={isUser ? '' : classes.messageFooter}>
                <div>{title}</div>
                <Typography className={`${classes.dateText} ${dateTextClass}`}>
                  {formatDate(message.createdAt)}
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.newMessageContainer}>
        {isNewMail && isDraftIncluded && (
          <>
            <div className={classes.titleDraftQuickActions}>
              {t('common:widgetArea.chat.quickActions.title')}
            </div>
            <div>
              <Button
                type="link"
                size="small"
                disabled={isSending}
                tooltip={t('common:widgetArea.chat.quickActions.spelling')}
                onClick={() => handleQuickAction('spelling')}
              >
                {t('common:widgetArea.chat.quickActions.spellingShort')}
              </Button>
              <Button
                type="link"
                size="small"
                disabled={isSending}
                tooltip={t('common:widgetArea.chat.quickActions.structure')}
                onClick={() => handleQuickAction('structure')}
              >
                {t('common:widgetArea.chat.quickActions.structureShort')}
              </Button>
              <Button
                type="link"
                size="small"
                disabled={isSending}
                tooltip={t('common:widgetArea.chat.quickActions.moreFormel')}
                onClick={() => handleQuickAction('formal')}
              >
                {t('common:widgetArea.chat.quickActions.moreFormelShort')}
              </Button>
              <Button
                type="link"
                size="small"
                disabled={isSending}
                tooltip={t('common:widgetArea.chat.quickActions.moreInformal')}
                onClick={() => handleQuickAction('informal')}
              >
                {t('common:widgetArea.chat.quickActions.moreInformalShort')}
              </Button>
            </div>
          </>
        )}
        <div className={classes.textAreaContainer}>
          <TextArea
            placeholder={t(
              `common:widgetArea.chat.${
                !isDraftIncluded ? 'newMessage' : 'improveDraft'
              }`
            )}
            disabled={isSending}
            autoSize={{ minRows: 3, maxRows: 3 }}
            maxLength={1024}
            showCount
            bordered={false}
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
            className={classes.textAreaField}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                !e.shiftKey &&
                !e.ctrlKey &&
                !e.metaKey
              ) {
                e.preventDefault();
                if (userMessage.trim() !== '' && !isSending) {
                  handleSendMessage();
                }
              } else if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
                setUserMessage(userMessage + '\n');
              }
            }}
          />
        </div>
        <div className={classes.buttonsContainer}>
          {isNewMail ? (
            <Toggle
              checked={isDraftIncluded}
              onChange={(value) => {
                setIsDraftIncluded(value);
              }}
            >
              <Flex.Row alignItems="center">
                {t('common:widgetArea.chat.includeDraft')}
                <Popover content={t('common:widgetArea.chat.popoverContent')}>
                  <Button
                    type="link"
                    iconProp={['fal', 'info-circle']}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                      color: theme.colors.application.typography.muted,
                    }}
                  ></Button>
                </Popover>
              </Flex.Row>
            </Toggle>
          ) : (
            <span></span>
          )}
          <Button
            type="primary"
            style={{}}
            iconProp={['fal', 'paper-plane-top']}
            onClick={() => handleSendMessage()}
            disabled={userMessage.trim() === '' || isSending}
            loading={isSending}
          >
            {t('common:widgetArea.chat.send')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrioBuddy;
