import { useContext, useMemo } from 'react';
import FilterPickerContext, {
  FilterPickerFilterFunction,
} from '../context/FilterPickerContext';
import { FilterPickerType } from '../FilterPicker';

const useFilterPickerContext: <T = unknown>(
  type: FilterPickerType
) => FilterPickerFilterFunction<T> | undefined = (type: FilterPickerType) => {
  const context = useContext(FilterPickerContext);

  return useMemo(() => {
    return context[type];
  }, [context, type]);
};

export default useFilterPickerContext;
